<template>
  <div class="convertGame" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('convertGame.title') }}</div>
      <div class="cont_desc">{{ $t('convertGame.text') }}</div>
      <!-- <div class="cont_btn">{{ $t('convertGame.text1') }}</div> -->
      <a target="_blank" class="cont_btn" href="https://www.deeplink.cloud/">{{ $t('convertGame.text1') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('convertGame.title') }}</p>

      <div class="stepList">
        <div class="stepLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="step_num">
            <p>01</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('convertGame.step.title1') }}</div>
            <div class="step_cont_text">{{ $t('convertGame.step.text1') }}</div>
          </div>
        </div>
        <div class="stepLi delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="step_num">
            <p>02</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('convertGame.step.title2') }}</div>
            <div class="step_cont_text">{{ $t('deployCloud.step2.text2') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'convertGame',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.convertGame {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .cont_btns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
      padding: 10px 20px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .stepList {
      width: 100%;
      margin-top: 40px;
      .stepLi {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        justify-content: space-between;
        .step_content {
          width: 275px;
          display: flex;
          line-height: 150%;
          flex-direction: column;
          .step_cont_title {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            margin-top: 12px;
            word-wrap: break-word;
          }
          .step_cont_text {
            font-size: 14px;
            color: #899AA9;
            font-weight: 500;
            margin-top: 12px;
            word-wrap: break-word;
            .step_icon {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-top: 2px;
              margin-right: 4px;
              background: url(../../assets/home/cont5_icon.png) 100%/100% no-repeat;
            }
          }
        }
        .step_num {
          width: 40px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-right: 8px;
          p {
            color: rgba(255, 255, 255, 0.24);
            font-size: 32px;
            font-weight: 900;
            line-height: 150%; 
          }
          .step_line {
            flex: 1;
            width: 2px;
            margin-top: 5px;
            // height: -webkit-fill-available;
            background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
      }
    }
  }
}

</style>
