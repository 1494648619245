<template>
  <div class="createwallet" v-show="lan == 'zh'">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('createwallet.title') }}</div>
      <div class="cont_desc">{{ $t('createwallet.text') }}</div>
      <!-- <div class="cont_btn">访问网站</div> -->
      <a target="_blank" class="cont_btn" href="https://www.dbcwallet.io">{{ $t('createwallet.text1') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('createwallet.cont2.title') }}</p>
      <p class="cont2_text2">{{ $t('createwallet.cont2.text1') }}</p>
      <div class="procedure_wiki">
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text1') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/1.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text2') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/2.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text3') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/3.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text4') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/4.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text5') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/5.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text6') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/6.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text7') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/7.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text8') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/8.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('createwallet.cont2.step1.text9') }}</p>
          <img class="procedure_img" src="../../assets/wiki/createWallet/9.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="cont3_media">
        <p class="cont3_media_text">{{ $t('createwallet.title1') }}</p>
        <div class="cont3_media_video">
          <video ref="videoRef" src="/video/createWallet/createWallet_zh.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
    </div>
  </div>
  <div class="createwallet" v-show="lan != 'zh'">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('createwallet.title') }}</div>
      <div class="cont_desc">{{ $t('createwallet.text') }}</div>
      <!-- <div class="cont_btn">访问网站</div> -->
      <a target="_blank" class="cont_btn" href="https://www.dbcwallet.io">{{ $t('createwallet.text1') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('createwallet.cont2.title') }}</p>
      <p class="cont2_text2">{{ $t('createwallet.cont2.text1') }}</p>
      <div class="stepList">
        <div class="stepLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="step_num">
            <p>01</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('createwallet.cont2.step.title1') }}</div>
          </div>
        </div>
        <div class="stepLi delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="step_num">
            <p>02</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('createwallet.cont2.step.title2') }}</div>
            <div class="step_cont_text">{{ $t('createwallet.cont2.step.text2') }}</div>
          </div>
        </div>
        <div class="stepLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="step_num">
            <p>03</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('createwallet.cont2.step.title3') }}</div>
            <div class="step_cont_text">{{ $t('createwallet.cont2.step.text3') }}</div>
          </div>
        </div>
        <div class="stepLi delay400 animateHide" v-animate="{delay: 400, class:'fadeInUp'}">
          <div class="step_num">
            <p>04</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('createwallet.cont2.step.title4') }}</div>
            <div class="step_cont_text">{{ $t('createwallet.cont2.step.text4') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="cont3_media">
        <p class="cont3_media_text">{{ $t('createwallet.title1') }}</p>
        <div class="cont3_media_video">
          <video ref="videoRef" src="/video/createWallet/createWallet_en.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'createwallet',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.createwallet {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 24px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1px;
    }
    .stepList {
      width: 100%;
      margin-top: 40px;
      .stepLi {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        justify-content: space-between;
        .step_content {
          width: 275px;
          display: flex;
          line-height: 150%;
          flex-direction: column;
          .step_cont_title {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            margin-top: 12px;
            word-wrap: break-word;
          }
          .step_cont_text {
            font-size: 14px;
            color: #899AA9;
            font-weight: 500;
            margin-top: 12px;
            word-wrap: break-word;
            .step_icon {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-top: 2px;
              margin-right: 4px;
              background: url(../../assets/home/cont5_icon.png) 100%/100% no-repeat;
            }
          }
        }
        .step_num {
          width: 40px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-right: 8px;
          p {
            color: rgba(255, 255, 255, 0.24);
            font-size: 32px;
            font-weight: 900;
            line-height: 150%; 
          }
          .step_line {
            flex: 1;
            width: 2px;
            min-height: 5px;
            margin-top: 5px;
            // height: -webkit-fill-available;
            background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
      }
    }
  }
  .content3 {
    position: relative;
    width: 100%;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont3_media {
      position: relative;
      width: 100%;
      margin: 0 auto;
      .cont3_media_text {
        color: #FFF;
        font-size: 28px;
        font-weight: 400;
        text-align: center;
        line-height: normal;
        letter-spacing: 1px;
        margin-bottom: 24px;
      }
      .cont3_media_video {
        width: 324px;
        height: 200px;
        margin: 0 auto;
        background: #000;
        video {
          width: 324px;
          height: 200px;
        }
      }
    }
  }
}

</style>
