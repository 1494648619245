export default {
  meau: {
    nav: 'Домой',
    nav1: 'Использовать DBC',
    nav1_1: 'Покупка облачных услуг для GPU',
    nav1_2: 'Развёртывание POS-узлов для получения наград',
    nav1_3: 'Проголосовать за POS-узлы',
    nav1_4: 'Стать графическим майнером для получения наград',
    nav1_5: 'Стать членом Совета',
    nav1_6: 'Проголосовать за кандидатов в Совет',
    nav1_7: 'Участвовать в референдумах',
    nav2: 'Учиться',
    nav2_1: 'Что такое DeepBrainChain?',
    nav2_2: 'Что такое графические процессоры?',
    nav2_3: 'История развития DeepBrainChain',
    nav2_4: 'Белая книга',
    nav2_5: 'Введение в автономию DBC DAO',
    nav2_6: 'О команде',
    nav3: 'Разработчики',
    nav3_1: 'Мощный API для аренды графических процессоров',
    nav3_2: 'Как стать брендом облачного кафе',
    nav3_3: 'Как стать дистрибьютором облачной игровой консоли',
    nav3_4: 'Как преобразовать AAA-игры в облачные игры',
    nav3_5: 'Как подать заявку на средства казначейства Совета',
    nav4: 'Кошелек',
    nav4_1: 'DBCWallet',
    nav4_2: 'PolkaWallet',
    nav4_3: 'ERC20 кошелёк',
    nav4_4: 'MathWallet',
    nav4_5: 'ImToken',
    nav4_6: 'TrustWallet',
    nav4_7: 'MetaMask',
    nav5: 'Вики',
    nav6: 'Купить DBC',
    nav7: 'Сообщество',
    nav8: 'DBC Scan',
    nav9: 'Разработчик ИИ',
    nav9_1: 'Что такое децентрализованный продукт ИИ?',
    nav9_2: 'Как разработать децентрализованный продукт ИИ?',
    nav9_3: 'Как развернуть смарт-контракт токена?',
    nav9_4: 'Как разработать смарт-контракт, связанный с платежами?',
    nav9_5: 'Как разработать смарт-контракт для майнинга на GPU?',
    nav9_6: 'Как разработать контейнер для моделей ИИ?',
    nav9_7: 'Как провести тестирование полного процесса?',
    nav9_8: 'Как провести базовое тестирование процесса?',
    nav10: 'Майнер',
    nav10_1: 'Как участвовать в майнинге токена DBC?',
    nav10_2: 'Как участвовать в двойном майнинге DBC и DeepLink?',
    nav10_3: 'Как участвовать в многомайнинге DBC и AI токена?',
    nav10_4: 'Как участвовать только в майнинге AI токена?',
    nav10_5: 'Как участвовать в многомайнинге DBC и других GPU токенов?'
  },
  home: {
    cont1: {
      text1: 'Добро пожаловать в',
      // text2: 'DeepBrain Chain',
      // text3: 'DeepBrain Chain - это децентрализованная высокопроизводительная сеть вычислений на GPU, способная масштабироваться бесконечно. Ее цель - стать самой широко используемой инфраструктурой вычислений на GPU в эпоху AI+Метавселенной по всему миру.',
      // text4: 'Основанная в 2017 году, DeepBrain Chain Foundation и DeepBrain Chain Council совместно продвигают развитие DBC.',
      // text5: 'Главная сеть блокчейна DBC была запущена 20 мая 2021 года, а основная сеть вычислений на GPU DBC была официально запущена 22 ноября 2021 года.',
      
      text2: 'DeepBrainChain 2.0',
      text2_1: 'DBC = AI + DePIN +GPU',
      text3: 'DeepBrain Chain 2.0 — это первая в мире блокчейн для искусственного интеллекта, создающая децентрализованную инфраструктуру ИИ, совместимую с стандартом смарт-контрактов EVM и поддерживающую децентрализованное выполнение моделей ИИ',
      text4: 'DeepBrainChain была основана в 2017 году. Развитие DBC совместно продвигается Фондом DeepBrainChain и Советом DeepBrainChain',
      text5: 'В 2021 году была запущена распределенная вычислительная сеть DBC 1.0 GPU, а в августе 2024 года был запущен тестнет DBC 2.0 AI публичной цепочки',
    },
    cont2: {
      title1: 'Huawei, DeepBrain Chain и Polygon совместно создают альянс Метавселенной и Web3',
      text1: 'Смотреть видео',
      title2: 'Главная сеть вычислений на GPU DBC запущена',
      text2: 'Информация о вычислениях на GPU в главной сети',
      title3: 'Сеть вычислений AI',
      text3: 'Презентация DBC',
      
      title4: 'Открытая продажа DeepLink',
      title4_1: ' ИДЕТ',
      text4: 'Присоединяйтесь сейчас'
    },
    cont3: {
      title: 'DBC 2.0 AI Экосистема',
      text: 'Любой AI-проект может разрабатывать децентрализованный AI на основе DBC 2.0, выпускать свои собственные токены и запускать AI-модели на постоянной основе, без возможности быть отключенными.',
      title1: 'DeepLink',
      text1: 'Протокол DeepLink – это децентрализованный протокол облачного AI-гейминга, сочетающий в себе AI и блокчейн-технологии для обеспечения ультранизкой задержки в рендеринге игр.',
      btn1_1: 'DeepLink',
      title2: 'DecentralGPT',
      text2: 'DecentralGPT – это децентрализованная сеть LLM AI. Она поддерживает различные открытые языковые модели и стремится создать безопасный, защищающий конфиденциальность, демократичный, прозрачный, открытый и общедоступный AGI.',
      btn2_1: 'DecentralGPT',
      title3: 'SuperImage',
      text3: 'SuperImage обладает несколькими латентными моделями диффузии "текст-в-изображение" для генерации фотореалистичных изображений на основе любого текстового ввода, предоставляя автономную свободу для создания невероятной графики и позволяя миллиардам людей создавать потрясающее искусство за считанные секунды.',
      btn3_1: 'SuperImage',
      title4: 'DRCpad',
      text4: 'Первая первичная торговая площадка для торговли AI-узлами. Фокусируется на торговой платформе узлов DBC AI и отбирает высококачественные AI-проекты.',
      btn4_1: 'DRCpad',
      title5: 'Hycons',
      text5: 'Бизнес-партнер HYCONS CLOUD: AI, автономное вождение, биомедицина, облачные игры, метавселенная, облачные XR, AR, VR визуальный рендеринг, моделирование климата. Любой, кому требуется GPU, может стать партнером HYCONS.',
      btn5_1: 'Hycons Cloud',
      title6: 'Браузер блокчейна DBC Mainnet',
      text6: 'Браузер блокчейна Mainnet может проверить количество владельцев кошельков, баланс Mainnet-кошельков и информацию о переводах DBC Mainnet-кошельков.',
      btn6_1: 'Браузер блокчейна DBC Mainnet',
      title7: 'DBCSCAN',
      text7: 'DBCSCAN – это EVM-браузер AI-публичной цепочки DBC. В настоящее время он работает в тестовой сети, позволяет запрашивать транзакции и развертывать смарт-контракты.',
      btn7_1: 'DBCSCAN',
      title8: 'DBCWallet',
      text8: 'DBCWallet – это полнофункциональный кошелек, поддерживаемый Фондом DBC. Он поддерживает вознаграждения за голосование POS, стейкинг POS, выборы и голосование совета директоров, голосование по казначейским предложениям и референдумы.',
      btn8_1: 'DBCWallet',
      title9: 'XAIAgent',
      text9: 'XAIAgent — это децентрализованная платформа AI-агентов на базе блокчейна DBC, предоставляющая возможности создания, эксплуатации и торговли AI-агентами. Она включает платформу XAIAgent для создания агентов без кода и фреймворк XAgentScope для продвинутой разработки',
      btn9_1: 'XAIAgent',
    },
    cont4: {
      title: 'DBC Сегодня',
      text: 'Последние статистические данные сети',
      text1: 'Количество GPU',
      text2: 'Уровень аренды GPU',
      text3: 'Количество DBC, заложенных на GPU',
      text4: 'Количество DBC, потребляемых в аренде GPU',
      text5: 'Общее количество выпущенных DBC',
      text6: 'Количество адресов владельцев DBC',
      text7: 'Количество DBC, заложенных для стейкинга',
      text8: 'Фонды казначейства Совета DAO'
    },
    cont5: {
      title: 'Почему AI-разработчики выбирают нас?',
      text: 'Любой может создать свою собственную платформу облачных услуг на GPU на основе DBC.',
      block1: {
        title: 'Бесплатное использование GPU',
        text: 'AI-разработчики могут бесплатно использовать GPU, просто выпустив свои собственные токены.',
      },
      block2: {
        title: 'Сокращение сроков разработки',
        text: 'Помогает AI-разработчикам сократить время разработки децентрализованного AI с 3 лет до 3 месяцев, значительно снижая затраты на разработку.',
      },
      block3: {
        title: 'Развертывание смарт-контрактов',
        text: 'Поддерживает разработчиков AI в развертывании собственных смарт-контрактов и выпуске собственных токенов.',
      },
      block4: {
        title: 'Высокий TPS, низкая комиссия за газ',
        text: 'Блок каждые 6 секунд, поддерживает более 1000 TPS, комиссия за газ всего 0.001 DBC.',
      },
      block5: {
        title: 'Открытый и без разрешений',
        text: 'Любая облачная платформа может создать свою собственную платформу облачных услуг на GPU на основе DBC.',
        text1: 'Обслуживайте клиентов в своей конкретной области без необходимости получения разрешений.',
      },
      block6: {
        title: 'Децентрализованное развертывание AI-моделей',
        text: 'Поддерживает децентрализованное развертывание AI-моделей, обеспечивая их непрерывную работу без отключений. Истинно децентрализованная работа модели обеспечивает доверие пользователей и полностью прозрачный механизм.',
      }
    },
    cont6: {
      title: 'Ценность токена DBC',
      block1: {
        text: 'Всего есть 10 миллиардов токенов DBC, с фиксированным предложением, которое никогда не увеличится. Весь объем будет выпущен примерно за 100 лет.',
      },
      block2: {
        text: 'Токен DBC - единственный токен в сети DBC.',
        text1: 'Каждый раз, когда пользователь арендует GPU, ему нужно приобрести токены DBC на бирже или из других источников, а затем заплатить определенное количество DBC в блокчейн-сеть DeepBrainChain, чтобы получить право использовать GPU.',
      },
      block3: {
        text: 'DBC следует модели дефляции. Когда общее количество GPU в сети DeepBrainChain составляет менее 5 000, 30% арендной платы пользователя уничтожается. Когда это число превышает 5 000, уничтожается 70%, а когда превышает 10 000, уничтожается 100%.',
        text1: 'DBC, оплачиваемые пользователями, нужно покупать на бирже или из других источников. Каждый раз, когда пользователь арендует GPU, общее количество DBC в обращении на рынке уменьшается.',
      },
      block4: {
        text: 'Суперузлы DBC POS должны ставить DBC для получения блоковой награды. Текущий общий объем ставки DBC во всей сети составляет {stakeNum}, что составляет {ratio}% от общего выпущенного объема DBC.',
      },
      block5: {
        text: 'Для майнеров необходимо ставить DBC, чтобы предоставить GPU. На каждую карту требуется ставка 100 000 DBC или эквивалентно до $800 в DBC. Это означает, что чем больше GPU в сети DeepBrainChain, тем больше DBC будет поставлено. Текущий общий объем DBC, поставленного майнерами GPU во всей сети, составляет {stakeNum}, что составляет {ratio}% от общего выпущенного объема DBC.',
      },
      block6: {
        text: 'Токен DBC - это токен управления DeepBrainChain.',
        text1: 'Совет DAO каждые 4 месяца выбирает 21 члена совета из всех кандидатов.',
        text2: 'Кандидаты с наибольшим количеством голосов токенов DBC среди топ-21 могут быть избраны.',
        text3: 'Каждый токен DBC равен одному голосу.',
        text4: 'Совет DAO коллективно управляет казначейскими средствами для поддержки развития экосистемы DeepBrainChain.',
      }
    },
    cont7: {
      title: 'Где торговать DBC',
      text1: 'Точность DBC: 15, Символ токена: DBC, Адрес контракта DBC ERC20 and BEP20:',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: 'Экономическая модель токена',
      text1: 'Текущее ежедневное выпуск DBC GPU вычислительной мощности наград: 1,095,900 монет, DBC Mainnet POS Узлы ежедневный вывод: 273,900 монет',
      table: {
        tr1: 'Использование',
        tr2: 'Категория',
        tr3: 'Итого',
        tr4: 'Количество (млрд)',
        tr5: 'Обращение (млрд)',
        tr6: 'Подлежит выпуску (млрд)',
        tr7: 'Примечание',
        tbody1: {
          text1: 'Ранняя продажа',
          text2: 'Продано профессиональным инвесторам или компаниям по искусственному интеллекту для получения прав на использование услуг экосистемы DBC',
        },
        tbody2: {
          text: 'Фонд и Экосистема',
          text1: 'Фонд',
          text2: '1 миллиардов разблокировано с 2018 по 2021 год (по 250 миллионов в год), оставшиеся 15 лет разблокировки, разблокировка 49 миллионов в год',
        },
        tbody3: {
          text1: 'Совет',
          text2: 'Весь совет избирается сообществом, а DBC полностью управляется советом, разблокировка происходит в течение 15 лет, разблокируется 51 миллионов монет ежегодно, начиная с января 2022 года',
        },
        tbody4: {
          text1: 'Команда',
          text2: 'После размещения на рынке, 10% блокируется на 1 месяц, а оставшиеся 10% разблокируются каждый год в течение 10 лет, начиная с 2018 года',
        },
        tbody5: {
          text1: 'Награды за мощность до основной сети',
          text2: 'Используется для стимулирования майнеров присоединяться к сети вычислительной мощности DBC до запуска основной сети',
        },
        tbody6: {
          text: 'Награды за узлы',
          text1: 'Супер-узлы',
          text2: 'Супер-узлы получают награды за учет блоков. В течение первых 3 лет они получают по 100 миллионов монет в год, затем по 50 миллионов монет в год в течение 5 лет, а затем награды уменьшаются вдвое каждые 5 лет. Награды начинаются с мая 2021 года',
        },
        tbody7: {
          text1: 'Узлы вычислительной мощности',
          text2: 'Узлы вычислительной мощности получают онлайн-награды за вклад в вычислительную мощность. В течение первых 3 лет они получают по 400 миллионов монет в год, затем по 200 миллионов монет в год в течение 5 лет, а затем награды уменьшаются вдвое каждые 5 лет. Награды начинаются с августа 2021 года',
        },
        tbody8: {
          text1: 'Всего',
        }
      }
    },
    cont9: {
      title: 'Инвестиционные институты и партнеры'
    },
    cont10: {
      title: 'История развития и дорожная карта'
    },
    cont12: {
      title: 'СМИ охват'
    },
    cont13: {
      title: 'Сообщественные мероприятия'
    }
  },
  buyDBC: {
    title: 'Покупка DBC',
    buy: 'Купить',
    see: 'Просмотр',
    block1: {
      title: 'Покупка DBC на централизованных биржах'
    },
    block2: {
      title: 'Покупка DBC на децентрализованных биржах'
    },
    block3: {
      title: 'Покупка DBC на платформах обмена',
      text: 'Можно обменивать напрямую через свой кошелек'
    },
    block4: {
      title: 'Покупка DBC на сторонних облачных платформах'
    }
  },
  buyGpuServer: {
    title: 'Использование токена DBC для покупки облачных GPU-сервисов',
    text1: 'DeepBrain Chain - это децентрализованная высокопроизводительная сеть вычислений с использованием GPU, которая может бесконечно масштабироваться. Поставщики со всего мира добавляют GPU-серверы в сеть DBC и ставят на ставку 100 000 или 800 долларов эквивалентных DBC, чтобы обеспечить стабильность и доступность GPU-серверов. Сторонние разработчики могут разрабатывать собственные облачные платформы на основе DBC для предоставления услуг пользователям. Разработчикам нужно только обслуживать пользователей, не покупая сами GPU-серверы. Все GPU-серверы могут автоматически получать доступ из сети DBC.',
    text2: 'Пользователи могут арендовать GPU с помощью DBC на облачной платформе или использовать фиатные деньги для аренды GPU. Если для аренды GPU используются фиатные деньги, облачная платформа автоматически конвертирует фиатные деньги в DBC для аренды GPU в сети DeepBrain Chain.',
    text3: 'Экосистемные службы приложений DBC'
  },
  getReward: {
    title: 'Развертывание узлов создания блоков POS для получения награды',
    text: 'DBC принимает механизм консенсуса POS. В настоящее время имеется в общей сложности 41 узел создания консенсуса. Каждые 24 часа 41 узел выбираются из всех узлов в качестве узлов создания блоков. Выбранные узлы могут генерировать награду в размере 272,500 DBC каждые 24 часа. Правила выборов: узлы, занимающие верхние 41 место по собственно ставке DBC и DBC, проголосованные за них.',
    seedetails: 'Просмотреть детали',
    cont: {
      title: 'Стать узлом проверки DBC'
    },
    procedure1: {
      title1: 'Настройка машины',
      text1: 'Память: 8 ГБ',
      text2: 'Процессор: 2 ядра',
      text3: 'Жесткий диск: 100 ГБ',
    },
    procedure2: {
      title1: 'Генерация счета финансирования (можно пропустить, если у вас уже есть счет финансирования)',
      text1: 'Справка:',
      text2: 'Как создать учетную запись'
    },
    procedure3: {
      title1: 'Получение двоичного файла dbc-chain',
      text1: 'Способ 1: Использование предварительно скомпилированной версии',
      text2: 'Если он не работает правильно, воспользуйтесь способом 2 и скомпилируйте его самостоятельно:',
      text3: 'Способ 2: Компиляция из исходного кода',
      text4: '# Установка зависимостей',
      text5: '# Компиляция dbc-chain'
    },
    procedure4: {
      title1: 'Запуск синхронизированного узла',
      text1: 'Если вы компилируете из исходного кода, путь к исполняемому файлу следующий:',
      text2: 'После завершения синхронизации закройте программу с помощью Control + C. Вы можете определить, завершена ли синхронизация, сравнив target и best. Когда разница между target и best невелика (например, менее 100), синхронизация считается завершенной.',
      text3: 'Объяснение параметров:',
      text4: '--base-path: Указывает каталог, в котором блокчейн хранит данные. Если не указано, будет использоваться каталог по умолчанию. Если каталог не существует, он будет создан автоматически. Если этот каталог уже содержит данные блокчейна, произойдет ошибка, в этом случае следует выбрать другой каталог или очистить содержимое этого каталога.',
      text5: '--pruning=archive: Запускает блокчейн в режиме архивации',
      text6: '--bootnodes: Указывает адрес узла загрузки.'
    },
    procedure5: {
      title1: 'Запуск узла в качестве проверяющего',
      text1: 'После завершения шага 3 по синхронизации данных узла закройте команду синхронизации. Затем запустите узел в качестве проверяющего:',
      text2: 'Если вы компилируете из исходного кода, путь к исполняемому файлу следующий:',
      text3: 'Обратите внимание, что --name YourNodeName здесь задает имя вашего узла. Вы можете дать вашему узлу уникальное и легко узнаваемое имя, которое другие смогут видеть в сети.',
      text4: 'Создание rotateKey',
      text5: 'На устройстве, где работает команда проверяющего узла, выполните следующую команду'
    },
    procedure6: {
      title1: 'Настройка суммы обязательства (bond)',
      text1: 'Открыть',
      text2: 'Вы увидите свой баланс:',
      text3: 'Перейдите в Сеть > Стейкинг > Действия с аккаунтом > Стэш-аккаунт.',
      text4: 'Установите сумму обязательства (убедитесь, что на вашем счете достаточно средств для отправки транзакций):',
      text5: 'Объяснение:',
      text6: 'Стэш-аккаунт: ваш финансовый аккаунт, здесь мы обязываем 45 DBC, убедитесь, что на вашем счете есть по крайней мере это количество средств.',
      text7: 'Контроллерный аккаунт: этот аккаунт также должен иметь небольшое количество DBC для отправки транзакций для запуска и остановки валидатора.',
      text8: 'Связанная сумма: сколько DBC вы хотите обязать/стейкать, обратите внимание, что вам не нужно обязывать всю доступную сумму на счете, и вы можете увеличить обязательство позже.',
      text9: 'Назначение платежа: вознаграждение, полученное валидатором, будет отправлено на этот счет. Этот счет можно установить на любой счет. Где,',
      text10: 'Стэш-аккаунт (увеличить сумму в стейке): Прибыль выплачивается на стэш-аккаунт и стейкуется.',
      text11: 'Стэш-аккаунт (не увеличивать сумму в стейке): Прибыль выплачивается на стэш-аккаунт без увеличения стейка.'
    }
  },
  voteReward: {
    title: 'Получайте вознаграждение за голосование за узлы POS',
    text: 'Каждый суперузел получает примерно 6700 DBC в день в качестве вознаграждения. Если узел голосования выбран в качестве суперузла, то и голосующие также могут получать вознаграждение. Сумма вознаграждения для голосующих рассчитывается как (1 - установленная голосующим комиссионная ставка) * моя сумма голосования / общая сумма голосов * 27250. Например, если общая сумма голосования для суперузла, за который я проголосовал, составляет 20 миллионов DBC, и я проголосовал с 1 миллионом DBC, а комиссионная ставка узла составляет 10 %, то моя ежедневная награда составит 90 % * 1 миллион / 20 миллионов * 6700 = 301 DBC. Приблизительно, можно получать около 110000 DBC в год в качестве вознаграждения.',
    seedetails: 'Посмотреть детали',
    cont: {
      title: 'Станьте номинантом DBC'
    },
    procedure1: {
      title1: 'Создайте финансовый счет (пропустите, если уже есть)',
      text1: 'Ссылка:',
      text2: 'Как создать учетную запись'
    },
    procedure2: {
      title1: 'Получите немного DBC',
      text1: 'Откройте',
      text2: 'Вы увидите свой баланс:'
    },
    procedure3: {
      title1: 'Установите сумму облигации',
      text1: 'Перейдите в Сеть> Стейкинг> Действия счета> Стэш-аккаунт,',
      text2: 'Во всплывающем окне выберите стэш-аккаунт, контролирующий аккаунт и введите сумму облигации, затем нажмите Далее.',
    }
  },
  gpuMiner:{
    title: 'стать GPU майнером и получить награды',
    text: 'В экосистеме DBC майнеры играют очень важную роль. Майнеры предоставляют GPU-серверы, которые являются основой сети DBC. В настоящее время каждый день 1,09 миллиона наград DBC выдается провайдерам GPU. Майнеры получают не только награды, но и доход от аренды. Для менее чем 5000 GPU арендный доход составляет 70%. Для GPU от 5000 до менее чем 10000 арендный доход для пользователей составляет 30%. Для более чем 10000 GPU дохода от аренды для пользователей нет, но доход от награды увеличивается на 30% при каждой аренде машины. Каждый GPU должен заложить 100000 DBC или эквивалент 800 USD в DBC, в зависимости от того, что ниже.',
    seedetails: 'просмотреть подробные правила',
    cont: {
      title: 'формула расчета ежемесячного дохода для GPU-машин',
      text: 'Формула расчета ежемесячного дохода для машин: Хэш-мощность машины / общая хэш-мощность сети * 1 095 890 * цена DBC * географический коэффициент * коэффициент крупной модели * 1,3 (увеличение хэш-мощности при аренде на 30%) * 30 + хэш-мощность машины * географический коэффициент * коэффициент крупной модели * 0,508 * 70%',
      text1: 'Модель машины:',
      text2: 'Количество графических карт:',
      text3: 'Количество памяти:',
      text4: 'Географическая область:',
      text5: 'Пожалуйста, выберите',
      text6: 'Текущий ежемесячный доход:',
      text7: 'Долларов США эквивалент DBC = ',
      text8: 'Доход от награды на цепи:',
      text9: 'DBC (эквивалент',
      text10: 'USD Текущая цена DBC:',
      text11: 'USD) + доход от аренды:',
      text12: 'USD эквивалент DBC'
    },
    country: {
      country1: 'Китай',
      country2: 'Северная Америка',
      country3: 'Южная Америка',
      country4: 'Европа',
      country5: 'Ближний Восток',
      country6: 'Южная Корея',
      country7: 'Япония',
      country8: 'Тайвань',
      country9: 'Гонконг и Макао',
      country10: 'Малайзия',
      country11: 'Индонезия',
      country12: 'Сингапур',
      country13: 'Вьетнам'
    }
  },
  applyCouncil: {
    title: 'Подача заявки на стать членом совета',
    text: 'Совет DBC - это организация DBC DAO, которая в настоящее время управляет более чем 800 миллионами токенов DBC в казне DBC, а также каждый месяц в казну поступает 4,25 миллиона токенов DBC, что продолжается до 2036 года. В совет входят 21 член, и выборы проводятся каждые 4 месяца. Выбираются 21 кандидат с наибольшим количеством голосов.',
    text1: 'Как член совета, вы можете голосовать за предложения по использованию средств из казны. Любое предложение требует одобрения как минимум 13 из 21 членов совета, чтобы быть принятым. Если голосов не хватает 13, главный член совета может голосовать от имени не проголосовавших членов.',
    text2: 'Быть членом совета увеличивает вероятность получения доверия со стороны сообщества и других членов совета. Если член совета предлагает предложение о распределении средств из казны, оно более вероятно будет принято.',
    text3: 'Любой член сообщества может инициировать предложение о распределении средств, но для того чтобы предложение было внесено в процесс голосования, оно должно быть представлено одним из 21 члена совета.',
    seedetails1: 'Группа обсуждения совета',
    seedetails2: 'Просмотр информации о совете',
    cont: {
      title: 'Процесс выборов в совет'
    },
    procedure1: {
      title1: 'Кошелек DBC основан на Polkadot',
      text1: 'Сначала вам нужен кошелек DBC, основанный на Polkadot. После создания кошелька перейдите на главную сеть DBC.',
      text2: 'Выберите раздел "Управление".',
      text3: 'В выпадающем меню выберите "Парламент".',
      text3_1: 'Внутри вы найдете обзор текущего состояния выборов в парламент.',
      text4: '“Места” - текущие члены совета.',
      text5: '“Победители” - временно снятые участники (вторые места).',
      text6: '“Кандидаты” - участники, которые подали заявку, но временно не вошли в топ-20.',
      text7: 'В правом верхнем углу страницы вы найдете кнопки "Голосовать" и "Представить кандидатуру".',
      text8: 'Если вы хотите стать членом совета, перейдите в "Представить кандидатуру"; если вы хотите только проголосовать, перейдите в "Голосовать".'
    },
    procedure2: {
      title1: 'Затем давайте рассмотрим "Голосование"',
      text1: 'После входа вы увидите баланс своего счета.',
      text2: 'Выберите желаемого кандидата, введите количество голосов в поле "Значение голоса".',
      text3: ' 1 DBC = 1 голос. Обратите внимание, что голоса меньше 1 DBC не будут учтены.',
      text4: 'После выбора подтвердите подпись, введите пароль для подтверждения.'
    }
  },
  councilVoting: {
    title: 'Голосование за кандидатов в Совет',
    text: 'Совет DBC - это DAO-организация, управляющая казной DBC. Члены совета играют ключевую роль в развитии экосистемы DBC. Каждый держатель DBC должен обращать внимание и участвовать в голосовании в совете, отдавая свой священный голос за поддерживаемого им кандидата в совет, только кандидаты, занявшие места в топ-21 по общему количеству голосов, могут быть избраны членами совета.',
    seedetails1: 'Группа обсуждения заседаний совета',
    seedetails2: 'Просмотр информации о совете',
    cont: {
      title: 'Как проголосовать?'
    },
    procedure1: {
      title1: 'Откройте страницу голосования в совете',
      text1: 'Откройте страницу голосования в совете,',
      text2: 'нажмите кнопку Голосовать (войдите в кошелек сначала)',
      text3: 'Установите значение голоса',
      text4: 'Установите значение голоса, щелкните на кандидата в совете, кандидат будет помещен в правую рамку. Если выбрано несколько кандидатов, голоса будут равномерно распределены между ними. Наконец, нажмите кнопку Голосовать. Примечание: после голосования монеты для голосования будут заложены и не могут быть переведены. Только после снятия залога можно выполнить операции с переводом. Рекомендуется голосовать за день до окончания новых выборов в совет, чтобы время залога монет было минимальным.',
      text5: 'Красная рамка на рисунке ниже показывает время, оставшееся до окончания новых выборов в совете',
      text6: 'Отменить голосование',
      text7: 'Снимите монеты для голосования. Вы можете отменить голосование в любое время. После нажатия кнопки "Отменить все" будет снят залог. Однако после отмены голосования голоса будут аннулированы. Рекомендуется отменить голосование после окончания новых выборов в совете.'
    }
  },
  referenda: {
    title: 'Участвовать в публичном голосовании',
    text: 'Любое техническое обновление и изменение параметров блокчейн сети DBC должны проходить через публичное голосование. Публичное голосование является ключевым элементом управления сообществом DBC. Каждый держатель DBC следит за техническим развитием и развитием продуктов DBC, может следить за публичным голосованием и принимать участие в нем. Также можно делиться своими предложениями по техническому развитию DBC и инициировать предложения для публичного голосования.',
    seedetails: 'Посмотреть общие предложения',
    cont: {
      title: 'Как участвовать в голосовании DBC Mainnet'
    },
    procedure1: {
      title1: '【Управление】-【Права демократии】Описание интерфейса',
      text1: 'Предложение — Предложение, инициированное обществом. По завершении каждого периода инициации (на данный момент каждые 28 дней) предложение, набравшее наибольшее количество поддержки (поддержка, выраженная в общей сумме dbc), будет передано на общественное голосование.',
      text2: 'Внешние — Предложения от Совета.',
      text3: 'Период инициации — Период, в течение которого открыто новое публичное голосование, на данный момент это 28 дней.'
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    text1: 'DeepBrain Chain 2.0 — это AI-блокчейн, на котором разработчики AI могут создавать децентрализованные AI-продукты на основе DBC. AI-модели могут работать децентрализованно, что сокращает время разработки на 90%.',
    text2: 'DeepBrain Chain была основана в 2017 году и совместно продвигается Фондом DeepBrain Chain и Советом DeepBrain Chain для развития DBC.',
    text3: 'Основная сеть вычислительных мощностей GPU DBC была официально запущена 22 ноября 2021 года, а тестовая сеть AI-блокчейна DBC будет запущена в августе 2024 года',
    text4: 'DBC собирает поставщиков GPU со всего мира с помощью механизма вознаграждений на основе блокчейна для предоставления серверов GPU для сети DBC. Затем с помощью распределенной сетевой технологии DBC разработчики AI могут использовать эти GPU. Разработчики AI выпускают свои собственные токены для вознаграждения поставщиков GPU, что привлекает множество поставщиков',
    text5: 'Чем больше экосистемных приложений, тем больше спрос на GPU со стороны самих экосистемных приложений. Чем больше ежедневных транзакций DBC используется, тем больше DBC уничтожается и тем выше стоимость DBC.',
    text6: 'Например, взяв в качестве примера приложение облачного интернет-кафе: пользователи облачного интернет-кафе должны покупать монеты на торговой площадке для использования GPU. За каждый дополнительный использованный GPU уничтожается 30% монет, купленных на торговой площадке. Если есть 1000 облачных интернет-кафе, и в каждом кафе по 100 машин, и каждая машина используется 10 часов в день, платя 0,1 доллара в час, из которых 30% уничтожается. Каждый месяц уничтожается 900 тысяч долларов. По цене монеты 0,002 USDT, в месяц должно быть уничтожено более 400 миллионов монет. В то же время, для поддержки 1000 облачных интернет-кафе необходимо 70000 машин, и дополнительно должно быть заложено 7 миллиардов монет.'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU (Graphics Processing Unit), или графический процессор, - это специализированный вычислительный блок, разработанный для обработки графики и видео. В отличие от ЦП (центрального процессора), GPU предназначен специально для параллельной обработки больших объемов данных.',
    block1: {
      title: 'Высокая параллельная производительность',
      text: 'GPU состоит из сотен или тысяч маленьких ядер, что позволяет ему обрабатывать большие объемы данных одновременно. Например, при рендеринге 3D графики каждое ядро может независимо обрабатывать пиксели или вершины, что значительно увеличивает скорость обработки.'
    },
    block2: {
      title: 'Оптимизация для графики',
      text: 'Изначально GPU был разработан для ускорения рендеринга графики и может эффективно обрабатывать задачи, связанные с изображениями и видео, такие как текстурный мэппинг и расчеты освещения.'
    },
    block3: {
      title: 'Широкий спектр применения',
      text: 'Хотя GPU изначально были разработаны для игровой индустрии и профессионального графического дизайна, они сейчас также являются крайне важными во многих других областях, особенно в области искусственного интеллекта и машинного обучения.'
    },
    title3: 'Игры и искусственный интеллект',
    title4: 'Зачем нам нужны GPU?',
    text2: 'Высокая параллельная обработка GPU позволяет им выделяться при обработке графически интенсивных задач и задач обработки больших объемов данных, что делает их неотъемлемыми в области игр и искусственного интеллекта.',
    text3: 'В настоящее время рыночная стоимость лидера по производству GPU, компании NVIDIA, превышает 1 триллион долларов, что в шесть раз больше, чем у лидера по производству CPU, компании Intel, что указывает на огромный спрос на GPU, который далеко превышает спрос на CPU.',
    block4: {
      title: 'Игры',
      text: 'Игры и современные игровые движки обычно включают сложную 3D графику и физические моделирования. Эти задачи требуют обширной параллельной обработки, поэтому мощные графические возможности GPU очень подходят. Использование GPU позволяет добиться более плавного игрового процесса и более высокого качества изображения.'
    },
    block5: {
      title: 'Искусственный интеллект и машинное обучение',
      text: 'В области искусственного интеллекта, особенно в глубоком обучении, требуется обработка больших объемов данных и выполнение сложных математических вычислений. Эти вычислительные задачи часто могут быть распараллелены, поэтому высокая параллельная производительность GPU очень подходит. Использование GPU может значительно ускорить скорость обучения моделей и вывода результатов.'
    }
  },
  historyDBC: {
    title: 'История развития DeepBrainChain',
    title1: '2017 год',
    text1_1: 'Запуск проекта DeepBrainChain, определение целей, видения и направления технологической архитектуры',
    text1_2: 'Завершение сбора средств',
    title2: '2018 год',
    text2_1: 'Введение токена DBC на бирже Huobi',
    text2_2: 'Запуск сети вычислительной мощности DBC и открытие исходного кода на GitHub',
    title3: '2020 год',
    text3_1: 'Число пользователей мировой AI-разработки DBChain превышает 10 000 человек, обслуживание более 500 университетов и лабораторий по всему миру, связанных с искусственным интеллектом',
    title4: '2021 год',
    text4_1: 'Официальный запуск главной сети узлов DeepBrainChain',
    text4_2: 'Запуск браузера главной сети DeepBrainChain',
    text4_3: 'Число платформ для облачных вычислений на базе DeepBrainChain превышает 50',
    text4_4: 'Начало публичного тестирования главной сети вычислительной мощности DBC GPU',
    text4_5: 'Общее количество вычислительной мощности DBC GPU в тестовой сети превышает 1200 карт, уровень аренды превышает 98%',
    text4_6: 'Официальный запуск главной сети вычислительной мощности DBC GPU',
    title5: '2022 год',
    text5_1: 'Число GPU в соревновании Galaxian достигает 1500',
    text5_2: 'Запуск облачной платформы haibaogpu на базе dbc, предоставляющей услуги GPU для пользователей искусственного интеллекта',
    text5_3: 'Три пула вычислительной мощности в Южной Корее - DBC KOREA HILLSTONE, DBC KOREA Ai-Factory и Gines Korea Center-1 присоединяются к сети DBC',
    text5_4: 'Запуск облачной платформы hycons на базе dbc в Южной Корее, предоставляющей услуги GPU для пользователей искусственного интеллекта и облачных игр',
    text5_5: 'Запуск облачной игровой платформы DeepLink на базе dbc, представляющей собой платформу облачных игр с удаленным управлением, разработанную как инструмент для облачных игр',
    title6: '2023 год',
    text6_1: 'Huawei, DeepBrainChain и Polygon объединяются в альянс метавселенной и Web3',
    text6_2: 'В Южной Корее стартует первый в мире облачный клуб ZestCloud на базе DBC и технологии DeepLink',
    text6_3: 'В Японии, в Токио, состоялась презентация мирового первого облачного игрового аппарата TIKEREN на базе DBC и технологии DeepLink, проведена совместная презентация',
    title7: '2024 год',
    text7_1: 'Q1',
    text7_2: '1. Разработка режима краткосрочной аренды GPU',
    text7_3: '2. Запуск новых функций на основной сети',
    text7_4: 'Q2',
    text7_5: '1. Разработка поддержки функций смарт-контрактов',
    text7_6: '2. Улучшение режима краткосрочной аренды GPU',
    text7_7: '3. Поддержка конвертации GameFi игр в облачный GameFi',
    text7_8: 'Q3',
    text7_9: '1. Поддержка проекта децентрализованного AIGC для разработки смарт-контрактов на основе DBC',
    text7_10: '2. Поддержка майнинга проекта децентрализованного AIGC на основе DBC GPU',
    text7_11: '3. Завершение тестирования функций смарт-контрактов',
    text7_12: 'Q4',
    text7_13: '1. Поддержка майнинга 3A GameFi на основе DBC GPU',
    text7_14: '2. Разработка функции DBCSwap, позволяющей торговать токенами из экосистемы DBC с DBC Token на цепочке',
  },
  introDBC: {
    title: 'Введение в управление DBC DAO',
    text1: 'С запуском основной сети DBC в 2021 году существует две организации, совместно продвигающие развитие DBC: Фонд DBC и Совет DBC.',
    text2: 'Фонд DBC - это основная организация DBC, главным образом отвечающая за техническое развитие DBC и глобальную деятельность. Фонд DeepBrainChain и Совет DeepBrainChain - это две полностью независимые организации без какой-либо принадлежности, совместно обслуживающие экосистему DeepBrainChain и способствующие развитию DeepBrainChain.',
    cont2: {
      block1: {
        title: 'Совет DBC',
        text1: 'DAO-организация, избираемая сообществом, главным образом отвечающая за управление средствами казны, поддержку приложений экосистемы DBC, развитие сообщества DBC и управление DBC.',
        text2: 'В настоящее время управляется более чем 800 миллионами токенов DBC в казне DBC, с ежемесячным поступлением в казну 4,25 миллиона DBC, что продолжится до 2036 года.',
        text3: 'Совет состоит из 21 члена, избираемых каждые 4 месяца. Топ-21 кандидатов выбираются.',
      },
      block2: {
        title: 'Стать членом Совета',
        text1: 'Становясь членом совета, вы можете голосовать по предложениям о фондах казны. Для прохождения любого предложения требуется одобрение 13 из 21 члена совета. Если число голосов меньше 13, главный директор может голосовать от имени неголосующих директоров.',
        text2: 'Становясь членом совета, вы более вероятно завоюете доверие сообщества и других директоров. Когда сами директоры подают заявки на предложения о фондах казны, они более вероятно будут одобрены.',
        text3: 'Любой член сообщества может инициировать предложение о фондах, но для того чтобы предложение вошло в процесс голосования, необходимо его представление одним из 21 директора.',
        btn: 'Подробнее'
      }
    },
    cont3: {
      title: 'Назначение средств из казны DBC',
      text1: 'Развертывание и постоянное обслуживание инфраструктуры',
      text1_2: 'система непрерывного обслуживания',
      text2: 'Обеспечение безопасности сети',
      text2_1: 'Службы мониторинга, непрерывная аудитория',
      text3: 'Поддержка экосистемы',
      text3_1: 'и сотрудничество с дружественными цепочками',
      text4: 'Маркетинговые мероприятия',
      text4_1: 'Реклама, платежи, сотрудничество',
      text5: 'Мероприятия в сообществе и мероприятия по привлечению',
      text5_1: 'встречи, вечеринки с пиццей, хакерские пространства',
      text6: 'Развертывание и постоянное обслуживание инфраструктуры',
      text6_1: 'Интеграция кошельков и кошельков, обновление клиентов и клиентов',
      text7: 'Листинг на биржах',
      text8: 'Другие...'
    },
    cont4: {
      title: 'Текущие три директора в этом сроке членства в DAO',
      job: 'Главный директор',
      reward: 'Голоса:'
    },
    cont5: {
      title: 'Обратный отсчет до выборов нового директора',
      text1: 'Предложения',
      text2: 'В процессе',
      text3: 'Утверждено',
      text3_1: 'Отклонено',
      text4: 'Запрашиваемая сумма',
      text5: 'Заявитель:',
      text6: 'Время окончания',
      text7: 'Часы',
      text8: 'Прошедшее время',
      text9: 'Согласен',
      text10: 'Против',
      text11: 'Дни'
    },
    seemore: 'узнать больше'
  },
  aboutTeam: {
    title: 'О команде',
    lead: 'HE YONG',
    job: 'Основатель',
    intro : {
      text1: 'Основатель DeepBrainChain и DeepLink, серийный предприниматель',
      text2: 'Опыт работы в области искусственного интеллекта - 14 лет, в области блокчейн и распределенных вычислительных сетей - 7 лет. В 2008 году окончил бакалавриат по компьютерным наукам в Китайском океанологическом университете, а в 2010 году изучал биоинформатику в Восточном китайском нормальном университете.',
      text3: 'В 2012 году основал компанию Jushang Intelligent, где был разработан первый в мире китайский голосовой помощник - Intelligent 360, который за три года был скачан более чем 17 миллионов раз.',
      text4: 'В 2014 году выпущен первый в мире искусственный интеллект-динамик - Xiao Zhi Super Dynamic Speaker.',
      text5: 'В мае 2017 года основал DeepBrain Chain, которая на данный момент обслуживает сотни клиентов в области искусственного интеллекта, облачных игр и облачных кафе.',
      text6: 'В 2020 году основал DeepLink, DeepLink Protocol является децентрализованным протоколом облачных игр с искусственным интеллектом, который вместе с DeepBrain Chain обслуживает глобальные облачные кафе и компании по производству игр AAA.'
    },
    application: {
      title: 'Подача заявки на должность',
      desc: 'Фонд DeepBrainChain на данный момент насчитывает более 50 сотрудников, работающих как на полную, так и на частичную ставку в области исследований и маркетинга, с персоналом в таких странах, как Сингапур, Китай, США, Россия, Южная Корея, Тайвань, Япония, Индия, Вьетнам, Бангладеш и Нигерия'
    },
    weekly: 'Последние 16 выпусков двухнедельного отчета DBC',
    see: 'Подробнее',
    seemore: 'узнать больше'
  },
  deployCloud: {
    title: 'Мощный API для аренды GPU',
    step1: {
      text1: 'Аренда GPU через On-chain API',
      text2: 'Адрес API-узла: xxxx, или разверните собственный узел синхронизации API блокчейна. Документация по развертыванию:',
      text3: 'Аренда машины на цепи',
      text4: 'Подтвердите доступность и арендуйте',
      text5: 'Продление',
    },
    step2: {
      text1: 'Аренда целой GPU-машины',
      text2: 'Создание виртуальной машины',
      text3: 'Перезагрузка виртуальной машины',
      text4: 'Подтвердите доступность и арендуйте',
      text5: 'Продление',
    },
    step3: {
      text1: 'Аренда GPU Bare Metal Machine',
      text2: 'Создать машину',
      text3: 'Перезагрузить машину',
      text4: 'Выключение',
      text5: 'Получить идентификатор устройства и проверочный код DeepLink',
      text6: 'Получить учетную запись и пароль Ubuntu',
    },
    server: 'Если у вас есть вопросы, пожалуйста, свяжитесь с нами:'
  },
  cloudInternet: {
    title: 'Как стать поставщиком облачного решения для интернет-кафе',
    text: 'В настоящее время основные страны распределения интернет-кафе по всему миру включают Южную Корею (6 000), Китай (40 000), Бразилию (10 000), Индонезию (20 000), Филиппины (20 000), Вьетнам (15 000), Турцию (5 000) и другие. Кроме того, есть такие страны, как Индия, Мексика, Ближний Восток, Южная Америка, Африка, Европа и другие. В мире более 150 000 интернет-кафе. Однако отрасль интернет-кафе имеет историю 20 лет и стала традиционной отраслью. Период окупаемости увеличился до 3-4 лет, и он сталкивается с различными рисками. Например, пандемия COVID-19 привела к закрытию большого числа интернет-кафе. Поэтому отрасль интернет-кафе нуждается в новом решении для сокращения периода окупаемости, снижения рисков и повышения эффективности управления. Таким образом, возникает облачное решение для интернет-кафе.',
    cont2: {
      text1: 'Что такое ',
      text2: 'решение облачного интернет-кафе?',
      text3: 'Решение облачного интернет-кафе - это новый тип модели обслуживания интернет-кафе с технологией рендеринга с ультранизкой задержкой. В облачном интернет-кафе игры и приложения не устанавливаются непосредственно на локальные компьютеры, а размещаются на облачных серверах с GPU. Пользователи получают доступ и играют в игры через облачные услуги, не требуя при этом высокопроизводительного локального оборудования.',
      block1: {
        text1: 'Снижение требований к локальному оборудованию',
        text2: 'Поскольку игры и приложения работают на облачных серверах с GPU, локальным компьютерам не требуется высокопроизводительных GPU или больших объемов хранения. Это снижает инвестиции в оборудование интернет-кафе и затраты на его обслуживание.'
      },
      block2: {
        text1: 'Мгновенный доступ к последним играм',
        text2: 'Пользователи могут мгновенно получать доступ к последним играм без ожидания загрузки и установки. Все обновления и обслуживание игр проводятся в облаке, обеспечивая пользователям удобство и эффективность.'
      },
      block3: {
        text1: 'Игровой опыт высокой производительности',
        text2: 'Локальные мониторы нужно только оборудовать мини-ПК (около 100 долларов), и облачные интернет-кафе могут обеспечить высокопроизводительный игровой опыт. Это потому, что вычислительная работа игры выполняется на удаленных высокопроизводительных серверах с GPU.'
      },
      block4: {
        text1: 'Экономия места и энергии',
        text2: 'Компьютеры с низкой конфигурацией занимают меньше места и потребляют меньше энергии, что помогает снизить операционные расходы интернет-кафе.'
      },
      block5: {
        text1: 'Простое управление и обслуживание',
        text2: 'Администраторы интернет-кафе могут легко управлять программным обеспечением и играми, потому что вся информация централизованно управляется в облаке. Это снижает локальную обслуживание и потенциальные технические проблемы.'
      }
    },
    cont3: {
      text1: 'Cloud Cybercafe Glowsteam в Южной Корее',
      text2: 'Фактическая рабочая среда облачного киберкафе'
    },
    cont4: {
      text1: 'Решение облачного киберкафе может помочь инвесторам киберкафе',
      text2: 'Увеличить чистую прибыль в 3 раза и сократить период окупаемости на 40%',
      text3: 'Нажмите, чтобы скачать сравнительные данные'
    },
    cont5: {
      text1: 'Какова прибыль для поставщиков решений облачного киберкафе?',
      text2: 'Если прибыль рассчитывается как 12% от доходов с одной киберкафе-машины, обслуживая в общей сложности 1000 киберкафе с 100 000 машинами, каждая машина используется в среднем 10 часов в день, и средняя стоимость одной машины в час составляет 0,8 доллара, то 12% примерно равно 0,1 доллара.',
      text3: 'Годовая прибыль поставщика решений облачного киберкафе составляет',
      text4: '100 000 * 10 * 0,1 * 365 = 36,5 миллиона долларов',
      text5: 'Как стать поставщиком решений облачного киберкафе',
      text6: 'На основе технологий DeepBrainChain и DeepLink южнокорейская компания HYCONS разработала полное решение для облачного киберкафе. DeepBrainChain предоставляет вычислительную мощность GPU, DeepLink предлагает технологию рендеринга с ультранизкой задержкой, а HYCONS предоставляет всё решение. Аналогично, если сравнить с автомобилем, DeepBrainChain предоставляет двигатель, DeepLink предоставляет автономную систему вождения, а HYCONS предоставляет полное решение для автомобиля. Местные поставщики решений в разных странах должны только интегрировать свои локальные платежные системы в HYCONS, чтобы предоставить услуги местным киберкафе, и поставщики решений могут устанавливать собственные бренды. HYCONS действует только как технологический сервис-провайдер без раскрытия своего бренда.',
      text7: 'Для получения дополнительной информации, пожалуйста, свяжитесь с:',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'Скачать связанные с облачным киберкафе материалы',
      text1: 'Введение в HYCONS',
      text2: 'Анализ прибыли от решения облачного киберкафе',
      text3: 'Диаграмма архитектуры центра обработки данных облачного киберкафе',
      text4: 'Руководство по тестированию и установке решения облачного киберкафе',
      text5: 'Введение в протокол DeepLink'
    }
  },
  gameAgents: {
    title: 'Как стать дистрибьютором облачной игровой консоли',
    text: 'Что такое облачная игровая консоль? По сравнению с традиционными игровыми консолями, облачные игровые консоли имеют высокую частоту обновления кадров в секунду (FPS), обеспечивая более плавный игровой опыт. Облачная игровая консоль TIKEREN от HKC Group разработана на основе технологии DeepBrainChain + DeepLink и позволяет в реальном времени подключаться к удаленному высокопроизводительному вычислительному GPU для рендеринга и вычислений игр.',
    title1: 'Презентация консоли TIKEREN',
    table: {
      th: {
        text1: 'Сортировка',
        text2: 'Пункт',
        text3: '27 дюймов',
        text4: '31.5 дюйма'
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27 дюймов',
        text3: '31.5 дюйма'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: 'Вверх, Вниз, Влево, Вправо, Поворот на 90°, Регулировка высоты',
        text3: 'Вверх, Вниз, Влево, Вправо, Поворот на 90°, Регулировка высоты'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: 'Пожалуйста, свяжитесь по электронной почте'
  },
  convertGame: {
    title: 'Как преобразовать игры AAA в облачные игры?',
    text: 'DeepLink Protocol - это децентрализованный протокол рендеринга облачных игр с ультранизкой задержкой, любое приложение может разрабатывать облачные игровые продукты на основе DeepLink Protocol, где облачная вычислительная мощность предоставляется DeepBrainChain.',
    text1: 'Понимание протокола DeepLink',
    step: {
      title1: 'Интеграция игр с протоколом DeepLink',
      text1: "Свяжитесь с service{'@'}deeplink.cloud",
      title2: 'DeepLink автоматически вызывает серверы графических процессоров DeepBrainChain',
      text2: 'Развертывание игры для запуска в облаке'
    }
  },
  applyForFunding: {
    title: 'Как подать заявку на финансирование из казны совета',
    text1: 'Прежде чем подать заявку на финансирование, вы можете присоединиться к группе обсуждения Telegram по заявкам на финансирование из казны совета.',
    text2: 'Группа обсуждения Telegram',
    cont: {
      title: 'Процесс подачи заявки на финансирование казны DBC',
      text: 'Когда заявитель запрашивает средства из казны, ему необходимо зарезервировать 5% от запрошенной суммы. Если предложение казны будет одобрено, зарезервированные средства будут возвращены; если предложение казны будет отклонено, зарезервированные средства будут наказаны. Обратите внимание, что пользователи не могут отозвать свое предложение после его подачи.',
    },
    procedure: {
      text: 'Процесс запроса финансирования из казначейства:',
      title1: 'Подготовка черновика',
      text1: 'Подготовьте черновик на основе шаблона предложения, см. Образец предложения',
      text2: 'Английский:',
      text3: 'Китайский:',
      title2: 'Обсуждение предложения вне цепи',
      text4: 'Перед подачей предложения в цепь заявитель может обсудить предложение с членами совета и сообществом в любом месте вне цепи, таком как WeChat, группы Telegram и т. д., и интегрировать разные мнения, постоянно совершенствуя предложение. В настоящее время DBC создало сообщество DBC_Council (https://www.reddit.com/r/DBC_Council/) на Reddit. Восточный материк Китая требует VPN. В этом сообществе также создан раздел для обсуждения предложений [proposal_draft discussion]. Заявитель также может опубликовать черновик в этом сообществе, добавив туда флэш [proposal_draft discussion] для обсуждения.',
      title3: 'Подача в цепь',
      text3_1: 'На странице правления-казначейства DBC главной сети (https://www.dbcwallet.io/#/treasury) подайте предложение в цепь, введите запрошенное количество DBC, и затем система автоматически заложит 5% DBC в качестве залога.',
      text3_2: 'Внимание! Если предложение будет принято, залог будет возвращен; если предложение будет отклонено, залог не будет возвращен!',
      text3_3: 'Поэтому отнеситесь серьезно к подготовке предложения, представляя относительно полное и выполнимое предложение.',
      text3_4: 'При подаче в цепь заявитель также должен опубликовать содержание предложения в сообществе Reddit DBC_Council (https://www.reddit.com/r/DBC_Council/) и добавить флэш [proposal_submitted onchain]. После подачи в цепь вы получите номер предложения и высоту блока для легкой корреляции между цепью и внецепными данными.',
      title4: 'Предложение совета и голосование',
      text4_1: 'После завершения заявки заявитель может просмотреть ее на https://www.dbcwallet.io/#/treasury.',
      text4_2: 'В этот момент совет может внести предложение по данному заявлению, приняв или отклонив его. Для принятия предложения требуется 3/5 голосов совета; для отклонения предложения требуется 1/2 голосов совета.',
      text4_3: 'Обратите внимание! Совет должен внести предложение в казначейство (https://www.dbcwallet.io/#/treasury), чтобы затем проголосовать в обсуждении в совете (https://www.dbcwallet.io/#/council/motions).',
      title5: 'Завершение голосования',
      text5_1: 'По истечении срока голосования совет должен вручную закрыть предложение (https://www.dbcwallet.io/#/council/motions).',
      text5_2: 'После голосования утвержденное предложение будет архивировано администраторами сообщества DBC subreddit с пометкой [proposal_approved], а отклоненное предложение будет помещено в [proposal_rejected].',
      title6: 'Распределение средств',
      text6_1: 'Средства будут распределяться частями, и успешный заявитель должен будет постоянно обновлять свой прогресс. Укажите необходимые средства для каждого этапа в вашем предложении. Прогресс выполнения предложения будет контролироваться членами сообщества (не членами совета), которые будут голосовать за наблюдение.',
      text6_2: 'Особое внимание: адрес для получения средств не может быть адресом биржи, он должен быть адресом, который вы контролируете лично. Лучше всего, если это будет адрес, созданный на главной сети DBC, иначе после утверждения вы не сможете получить средства.',
      text6_3: 'Примечание: Распределение утвержденных средств осуществляется по истечении срока траты. Срок траты можно узнать на странице казначейства (https://www.dbcwallet.io/#/treasury) (в правом верхнем углу отображается обратный отсчет до конца срока траты).'
    },
  },
  createwallet: {
    title: 'Кошелек DBCWallet',
    text: 'DBCWallet - это полнофункциональный кошелек, поддерживаемый Фондом DBC, который позволяет создавать адреса кошельков для DBC и DLC, участвовать в голосовании POS, выборах в совет и других голосованиях.',
    text1: 'Посетите веб-сайт',
    cont2: {
      title: 'Метод создания адреса кошелька',
      text1: 'Для защиты безопасности средств пользователей создание кошелька выполняется в форме плагина',
      step: {
        title1: 'Установите браузер Google Chrome',
        title2: 'Поиск плагина Polkadot',
        text2: 'Установите плагин',
        title3: 'Сохраните мнемоническую фразу',
        text3: 'Откройте плагин Polkadot, нажмите на кнопку создания кошелька, выберите опцию поддержки всех цепочек и сохраните мнемоническую фразу',
        title4: 'Обновите www.dbcwallet.io',
        text4: 'На моей странице аккаунта уже будет показан созданный кошелек'
      }
    },
    title1: 'Видео создания кошелька'
  },
  polkaWallet: {
    title: 'Кошелек PolkaWallet',
    text: 'Ищите PolkaWallet в Google Play и App Store или посетите официальный сайт: PolkaWallet.io для загрузки.',
    text1: 'Загрузить с PolkaWallet.io',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: 'Процесс создания',
      text1: '1. Откройте polkawallet.io в вашем браузере.',
      text2: '2. Перейдите на официальный сайт Polkadot, чтобы скачать приложение кошелька.',
      text3: '3. После загрузки выберите "Новый" или "Импорт" для кошелька главной сети DBC.',
      text4: '4. Выберите учетную запись Substrate.',
      text5: '5. Для нового кошелька запомните правильно резервную копию ключа.',
      text6: '6. Задайте имя и пароль, затем вы можете войти в главный интерфейс.',
      text7: '7. Перетащите влево на главном интерфейсе, чтобы выбрать DBC.',
      text8: '8. Нажмите на иконку, чтобы отправить и получить основной DBC.',
      text9: '9. Интерфейс получения.',
      text10: '10. Интерфейс отправки и перевода. Газ, используемый для кошелька основной сети, - DBC, поэтому, пожалуйста, зарезервируйте небольшое количество DBC при переводе.'
    }
  },
  mathWallet: {
    title: 'Кошелек MathWallet',
    text: 'Ищите MathWallet в Google Play и App Store или посетите официальный веб-сайт: https://mathwallet.org/, чтобы скачать.',
    text1: 'Скачать MathWallet',
    step: {
      title: 'Процесс создания',
      text1: '1. Введите mathwallet.org в вашем браузере.',
      text2: '2. После входа на официальный сайт выберите путь загрузки.',
      text3: '3. Для Android вы можете загрузить установочный пакет напрямую или перейти в магазин Google Play для загрузки.',
      text4: '4. После завершения загрузки откройте кошелек и вручную выберите основную сеть DBC.',
      text5: '5. Кошелек основной сети DBC находится на четвертой странице с конца.',
      text6: '6. Вы можете создать или импортировать.',
      text7: '7. Создание пароля требует как прописных, так и строчных букв.',
      text8: '8. Пожалуйста, сделайте резервную копию мнемонической фразы.',
      text9: '9. После появления иконки DBC нажмите, чтобы принимать и отправлять активы.',
      text10: '10. Интерфейс отправки.',
      text11: '11. Перемещайтесь влево, чтобы создать больше кошельков.',
      text12: '12. В правом нижнем углу настройте язык.',
      text13: '13. В центре нижней части главного интерфейса вы увидите основной браузер сети.'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: 'Поиск адреса контракта DBC:',
    text5: 'Точность:',
  },
  ImToken: {
    title: 'Кошелек ImToken',
    text: 'Посетите официальный сайт: token.im для скачивания',
    text1: 'Скачать token.im',
    step: {
      title: 'Процесс создания',
      text1: '1. Введите token.im в свой браузер',
      text2: '2. После ввода нажмите "Скачать сейчас"',
      text3: '3. Выберите, чтобы установить напрямую или из Google Play Store',
      text4: '4. После загрузки создайте кошелек в приложении',
      text5: '5. После создания рекомендуется немедленно создать резервную копию и надежно хранить ваши ключи',
      text6: '6. На главной странице кошелька нажмите знак "+" для добавления токена',
      text7: '7. В строке поиска введите адрес контракта DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. После успешного добавления вернитесь на главную страницу, чтобы увидеть значок DBC',
      text9: '9. Нажмите на значок, чтобы увидеть адреса приема и отправки DBC',
      text10: '10. При отправке другим убедитесь, что используете их адрес ERC20',
    }
  },
  trustWallet: {
    title: 'Кошелек TrustWallet',
    text: 'Поиск TrustWallet в Google Play и App Store или посетите официальный веб-сайт: https://trustwallet.com/ для загрузки',
    text1: 'Загрузить TrustWallet',
    step: {
      title: 'Процесс создания',
      text1: '1. Введите trustwallet.com в вашем браузере',
      text2: '2. После ввода появится три варианта загрузки',
      text3: '3. После загрузки создайте новый кошелек',
      text4: '4. Пожалуйста, сделайте резервную копию нового кошелька немедленно',
      text5: '5. После завершения резервного копирования перейдите на главный экран',
      text6: '6. Нажмите маленькую иконку "+" в правом верхнем углу главного экрана, а затем "+" на текущей странице',
      text7: '7. На экране импорта токенов вставьте адрес контракта DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. После успешного импорта появится иконка DBC',
      text9: '9. Нажмите "Получить", чтобы получить текущий адрес кошелька DBC',
      text10: '10. Нажмите "Отправить", чтобы войти в интерфейс отправки, и убедитесь, что на ERC20 цепочке достаточно ETH, чтобы завершить транзакцию',
    }
  },
  metaMask: {
    title: 'Кошелек MetaMask',
    text: 'Ищите MetaMask в Google Play или посетите официальный веб-сайт: https://metamask.io/ для загрузки',
    text1: 'Скачать MetaMask',
    step: {
      title: 'Процесс создания',
      text1: '1. Откройте metamask.io в вашем браузере',
      text2: '2. Установите из Google Play Store',
      text3: '3. После загрузки импортируйте или создайте кошелек и войдите в главный интерфейс',
      text4: '4. Нажмите "Импорт токенов" внизу интерфейса, чтобы перейти на страницу добавления токенов',
      text5: '5. Выберите «Пользовательский токен» и введите адрес контракта DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6. После успешного импорта на главном интерфейсе появится значок DBC',
      text7: '7. Щелкните, чтобы ввести, и вы увидите опции для получения и отправки DBC',
      text8: '8. Для отправки необходимо иметь ETH для газа в кошельке'
    }
  },

  whatisAi: {
    title1: 'Что такое децентрализованный продукт ИИ?',
    text1: 'Децентрализованный продукт ИИ — это продукт, в котором все этапы выполняются в децентрализованной среде. Продукт в основном состоит из двух частей: расчетов за платежи и работы модели. Когда обе эти части полностью децентрализованы, это считается децентрализованным продуктом ИИ.',
    title2: 'Что такое централизованный продукт ИИ?',
    text2: 'Централизованный продукт ИИ — это продукт, где все этапы контролируются централизованной организацией. Например, расчеты за платежи происходят на серверах централизованной организации, а модель ИИ работает на серверах с GPU, управляемых централизованной организацией. В общем, централизованная организация может налагать любые ограничения и вносить изменения.',
    title3: 'Что такое полу-децентрализованный продукт ИИ?',
    text3: 'Полу-децентрализованный продукт ИИ — это продукт, частично контролируемый централизованной организацией и частично децентрализованный. Например, расчеты производятся через смарт-контракты на основе токенов, а модель ИИ работает на серверах с GPU, управляемых централизованной организацией.',
    title4: 'В чем разница между децентрализованным и полу-децентрализованным проектом ИИ?',
    text4_1: 'Основные различия:',
    text4_2: 'Децентрализованный проект ИИ не зависит от централизованных организаций, его токен является утилитарным токеном, который не подпадает под регулирование ценных бумаг. В то время как полу-децентрализованные проекты выпускают токены, которые являются ценными бумагами и регулируются законами о ценных бумагах, что создает множество потенциальных рисков.',
    text4_3: 'Децентрализованные проекты ИИ работают дольше и могут быть переданы сообществу, если у команды возникнут проблемы, поскольку модели работают на мощностях GPU, предоставленных майнерами, а не на централизованных серверах команды.',
    title5: 'Какие преимущества у децентрализованных продуктов ИИ?',
    text5_1: '1. Бесплатное использование GPU для команды проекта.',
    text5_2: '2. Возможность привлечения средств через продажу узлов для майнинга GPU.',
    text5_3: '3. Токен является утилитарным и не подлежит регулированию ценных бумаг.',
    text5_4: '4. Возможность стимулирования пользователей с помощью токенов.',
    text5_5: '5. Модель работает в децентрализованной среде, является прозрачной и не регулируется, пользователи не беспокоятся о возможных ограничениях.',
    text5_6: '6. Пользователи могут участвовать в управлении проектом, и даже если команда распадется, сообщество может взять на себя управление, что обеспечит непрерывность работы модели.',
  },
  developAi: {
    title: 'Как разработать децентрализованный продукт ИИ?',
    title1: '1. Как разработать децентрализованный продукт ИИ?',
    text1: 'Децентрализованный продукт ИИ состоит из двух основных частей: расчетов за платежи и работы модели. Часть расчетов за платежи реализуется путем развертывания платежных смарт-контрактов. Работа модели делится на два элемента: контракт вознаграждения за майнинг и контейнер ИИ. Контракт вознаграждения за майнинг определяет, какую долю токенов выделить майнерам GPU в качестве награды, что стимулирует большее количество майнеров развертывать модель проекта и зарабатывать токены проекта. Контейнер ИИ требует, чтобы команда проекта упаковывала свою модель ИИ в контейнер, который майнеры могут развертывать, следуя документации.',
    title2: '2. Что включает в себя контракт на расчеты за платежи?',
    text2_1: '1). Стратегия ценообразования продукта, например, плата за количество запросов или ежемесячная подписка.',
    text2_2: '2). Цена продукта.',
    text2_3: '3). Права доступа к продукту для конкретного кошелька.',
    title3: '3. Что включает в себя контракт вознаграждения за майнинг?',
    text3_1: '1). Общий объем токенов для вознаграждений.',
    text3_2: '2). Механизм майнинга, например, сколько токенов можно добыть за год.',
    text3_3: '3). Факторы, влияющие на количество добытых токенов, такие как производительность GPU, количество заложенных токенов, количество узлов NFT, время майнинга и т.д.',
    text3_4: '4). Правила разблокировки добытых токенов.',
    title4: '4. Что включает в себя контейнер ИИ модели?',
    text4_1: '1). Среда выполнения контейнера.',
    text4_2: '2). Модель ИИ и сценарии выполнения.',
    text4_3: '3). Скрипт регистрации узлов вычислений GPU DBC.',
    text4_4: '4). Программа мониторинга состояния DBC.',
    text4_5: '5). Программы взаимодействия с контрактами на майнинг и расчеты за платежи.',
  },
  deployToken: {
    title: 'Как развернуть смарт-контракт токена?',
    text1: '1. DBC совместим с ETH EVM, стандарт токенов DRC20 полностью совместим с ERC20. Вы можете писать смарт-контракты на языке Solidity.',
    text2_1: '2. Браузер основной сети DBC:',
    text2_2: ' Через DBCScan вы можете просматривать развернутые контракты, адреса кошельков и транзакции.',
    text3: '3. Конкретный процесс развертывания контракта:',
    text3_1: '1). Заполните эти конфигурации основной сети dbc в hardhat.config.ts. Затем вы можете написать код и протестировать контракт.',
    text3_2: 'rpc_url: "https://rpc.dbcwallet.io"',
    text3_3: 'api_url: "https://www.dbcscan.io/api"',
    text3_4: 'browser_url: "https://www.dbcscan.io"',
    text3_5: 'network: "DBC Mainnet"',
    text3_6: 'chain_id: 19880818',
    text3_7: '2). Вы можете использовать Makefile для компиляции, развертывания, обновления, проверки или тестирования контракта.',
    text3_8: '3). Эти ссылки могут быть полезны для разработки контрактов ERC20:',
    text4: '4. Пример токен-контракта:',
  },
  paymentRelated: {
    title: 'Как разработать смарт-контракт для платежей?',
    text1: 'Определите ценообразование для разных моделей ИИ. Например, в текущем проекте есть три модели ИИ с двумя режимами: подписка и бесплатный. В бесплатном режиме каждая модель может быть вызвана 10 раз в день, а в режиме подписки – 10,000 раз в день. Цены на каждую модель определены в контракте.',
    text2: 'Когда пользователь проекта отправляет запрос через распределенную сеть DBC AI в AI-контейнер проекта, в контейнере должен быть программный "страж", который взаимодействует с платежным смарт-контрактом, чтобы определить, должна ли текущая модель ИИ предоставлять вычислительные услуги пользователю. Если такого "стража" нет, контейнер ИИ будет возвращать результаты вычислений для любых запросов пользователей, и пользователи не будут платить за это через смарт-контракт.',
    text3: 'Программа стража проекта',
    text4: 'Контейнер модели ИИ проекта',
    text5: 'Платежный смарт-контракт',
    text6: 'Программа стража взаимодействует со смарт-контрактом для определения того, должна ли текущая модель ИИ предоставлять вычислительные услуги пользователю.',
  },
  gpuMining: {
    title: 'Как разработать смарт-контракт для майнинга на GPU?',
    text1: 'Механизм вознаграждения за майнинг на GPU очень важен, поскольку определяет правила получения вознаграждений майнерами. Чем более привлекательны правила вознаграждения, тем больше майнеров будут участвовать, предоставляя мощности GPU. Рекомендуется выделять 20%-50% токенов в качестве вознаграждения за майнинг. Можно настроить механизм уменьшения вознаграждений, например, уменьшать вознаграждения каждые 2 или 4 года. Пример:',
    text2: 'Когда майнер разворачивает AI-контейнер на машине с GPU, в контейнере должна быть программа стейкинга. Эта программа стейкинга взаимодействует с узлом DBC AI Worker для регистрации или отмены регистрации. Регистрация означает, что машина начала майнить для текущего AI-проекта. Программа стейкинга также взаимодействует со смарт-контрактом, связанным с майнингом на GPU, и отправляет информацию о успешной регистрации в смарт-контракт, начиная отсчет вознаграждений за майнинг.',
    img_text1: 'Регистрация / Отмена регистрации',
    img_text2: 'Модель ИИ',
    img_text3: 'Программа стейкинга проектной команды',
    img_text4: 'Контейнер модели ИИ проектной команды',
    img_text5: 'Автоматическая отправка информации о регистрации в цепочку DBC',
    img_text6: 'Отправка успешной регистрации в смарт-контракт',
    img_text7: 'Контракт на майнинг на GPU должен подтвердить регистрацию в цепочке DBC',
    img_text8: 'Смарт-контракт для майнинга на GPU',
    text3_1: 'Существуют два режима подключения машин с GPU к сети DBC:',
    text3_2: '1. Режим долгосрочной аренды  2. Свободный режим',
    text3_3: 'Смарт-контракт для майнинга на GPU должен получать информацию из цепочки DBC, чтобы определить, в каком режиме подключена машина с GPU, и соответственно применять разные условия.',
    text4: 'Обработка режима долгосрочной аренды:',
    text4_1: '1) Смарт-контракт для майнинга на GPU должен сначала проверить, арендована ли машина с GPU.',
    text4_2: '2) Адрес кошелька стейкинга должен принадлежать тому же владельцу, что и адрес кошелька, арендующего GPU в сети DBC.',
    text4_3: '3) Только при выполнении этих условий майнер получает вознаграждение токенами.',
    text5: 'Обработка свободного режима:',
    text5_1: '1) Смарт-контракт для майнинга на GPU должен сначала проверить, успешно ли машина с GPU подключена к сети DBC.',
    text5_2: '2) Только при выполнении этих условий майнер получает вознаграждение токенами.',
  },
  developAIModel: {
    title: 'Как разработать контейнер модели ИИ?',
    title1: '1. Контейнер модели ИИ разрабатывается командой AI проекта и разворачивается майнерами GPU.',
    title2: '2. Что включает контейнер модели ИИ?',
    text2_1: '1) Среда выполнения контейнера: Сначала запустите чистый контейнер. См.',
    text2_1_1: '(Руководство по созданию контейнерных образов)',
    text2_2: '2) Модель ИИ и скрипты для запуска: Поскольку модели обычно большие, не рекомендуется загружать их в контейнер заранее. Вместо этого после запуска контейнера он может автоматически загружать модель с удаленного источника, например, Huggingface.',
    text2_3: '3) Скрипт регистрации узлов вычислений GPU DBC: Этот скрипт выполняет регистрацию и отмену регистрации. Вы также можете настроить автоматическую регистрацию при запуске контейнера и автоматическую отмену регистрации при остановке контейнера.',
    text2_3_1: '(Руководство по использованию узла Worker)',
    text2_4: '4) Программа мониторинга состояния DBC. ',
    text2_5: '5) Программа взаимодействия с контрактом майнинга и расчетным контрактом: В основном это стейкинговая программа и программа "страж", которые также могут быть объединены в одну программу.',
    block_text1: 'Модель ИИ',
    block_text2: 'Стейкинговая программа команды проекта',
    block_text3: 'Программа мониторинга состояния DBC',
    block_text4: 'Программа "страж" команды проекта',
    block_text5: 'Скрипт регистрации узлов вычислений GPU DBC',
    block_text6: 'Контейнер модели ИИ',
    title3: '3. Создание образа контейнера модели ИИ: Процесс создания образа',
  },
  fullProcessTesting: {
    title: 'Как выполнить тестирование полного процесса?',
    text1: '1. Завершите разработку смарт-контрактов для расчетов.',
    text2: '2. Завершите разработку смарт-контрактов для майнинга на GPU.',
    text3: '3. Завершите создание образа контейнера ИИ.',
    text4: '4. Подготовьте машину с GPU, способную запускать модель ИИ, добавьте её в сеть DBC в режиме свободной аренды, затем запустите DBC AI Worker Node на машине с GPU, запустите контейнер ИИ и зарегистрируйте его.',
    text5: '5. Настройте машину с CPU и IP-адресом, разверните на ней DBC AI Input Node. ',
    text6: '6. Проверьте, обнаружил ли DBC AI Input Node контейнер ИИ.',
    text7: '7. Отправьте запросы пользователей на DBC AI Input Node и проверьте, корректно ли возвращаются результаты.',
  },
  primaryProcessTesting: {
    title: 'Как выполнить начальное тестирование процесса?',
    text: 'Начальное тестирование процесса проверяет, может ли контейнер ИИ корректно обрабатывать запросы пользователей через распределённую сеть и возвращать результаты вычислений модели ИИ через эту сеть.',
    text1: '1. Завершите создание образа контейнера ИИ.',
    text2: '2. Подготовьте машину с GPU, способную запускать модель ИИ, затем запустите DBC AI Worker Node на машине с GPU (используя тестовую сеть), установите среду контейнера, запустите контейнер ИИ и зарегистрируйте его.',
    text3: '3. Настройте машину с CPU и IP-адресом, разверните на ней DBC AI Input Node. ',
    text4: '4. Проверьте, корректно ли DBC AI Input Node обнаруживает контейнер ИИ.',
    text5: '5. Отправьте запросы пользователей на DBC AI Input Node и проверьте, возвращаются ли корректные результаты.',
  },
  participateDBCMining: {
    title: 'Как участвовать в майнинге токенов DBC?',
    text: 'При участии только в майнинге DBC доход составит: награды за майнинг DBC + доход от аренды DBC.',
    text1: 'Подготовьте машину с GPU, соответствующую требованиям DBC. Подробности смотрите здесь:',
    text2: 'Если вы участвуете только в майнинге токенов DBC, вы получите награды за майнинг DBC и доход от аренды DBC (доход от аренды доступен только при количестве GPU в сети менее 10,000).',
    text3_1: 'Для получения информации о доходах от майнинга смотрите:',
    text3_2: 'Формула расчета ежемесячного дохода машины',
  },
  participateDBCDLCMining: {
    title: 'Как участвовать в двойном майнинге DBC и DeepLink?',
    text: 'При участии в двойном майнинге DBC и DeepLink доход составит: вознаграждение за майнинг DBC - сожженная арендная плата DBC + вознаграждение за майнинг DeepLink.',
    text1: 'Подготовьте машину с GPU, соответствующую требованиям DeepLink. Подробности смотрите здесь: ',
    text2: 'Добавьте машину с GPU в сеть DBC в режиме долгосрочной аренды, затем арендовать машину самостоятельно (машины в аренде на DBC получают на 30% больше вознаграждений). После этого присоединитесь к сети DeepLink. ',
    text3_1: 'Для получения информации о доходах от майнинга смотрите:',
    text3_2: 'Формула расчета ежемесячного дохода машины.',
  },
  participateDBCAiMining: {
    title: 'Как участвовать в многомайнинге DBC и AI токенов?',
    text: 'При участии в многомайнинге DBC и AI токенов доход составит: вознаграждение за майнинг DBC - сожженная арендная плата DBC + вознаграждение AI токенов.',
    text1: 'Подготовьте машину с GPU, соответствующую требованиям AI проекта. ',
    text2: 'Добавьте машину с GPU в сеть DBC в режиме долгосрочной аренды, затем арендовать машину самостоятельно (машины в аренде на DBC получают на 30% больше вознаграждений). После этого присоединитесь к смарт-контракту майнинга AI проекта. ',
    text3_1: 'Для получения информации о доходах от майнинга смотрите официальный сайт каждого AI проекта:',
    text3_2: 'Формула расчета ежемесячного дохода машины.',
  },
  participateAiMining: {
    title: 'Как участвовать в майнинге только AI токенов?',
    text: 'При участии только в майнинге AI токенов доход составит: вознаграждение AI токенов.',
    text1: 'Подготовьте машину с GPU, соответствующую требованиям AI проекта. Подробности смотрите здесь:',
    text2: 'Добавьте машину с GPU в сеть DBC в свободном режиме, затем присоединитесь к смарт-контракту майнинга AI проекта.',
    text3_1: 'Для получения информации о доходах от майнинга смотрите официальный сайт каждого AI проекта:',
    text3_2: 'Формула расчета ежемесячного дохода машины.',
  },
  participateGPUMining: {
    title: 'Как участвовать в многомайнинге DBC и других GPU токенов?',
    text1: 'Подготовьте машину с GPU, соответствующую требованиям DBC. Подробности смотрите здесь:',
    text2: 'Добавьте машину с GPU в сеть DBC в режиме долгосрочной аренды, затем запустите виртуальную машину. В виртуальной машине начните майнинг проектов GPU, таких как Aleo, IONET, Aethir и т.д. Однако обратите внимание, что в этом режиме виртуальная машина может быть отключена, если кто-то арендует машину в сети DBC, поэтому этот режим не подходит для проектов с штрафными механизмами майнинга.',
    text3: 'Добавьте машину с GPU в сеть DBC в режиме долгосрочной аренды, затем арендовать машину самостоятельно (машины в аренде на DBC получают на 30% больше вознаграждений). Затем запустите виртуальную машину и начните майнинг проектов GPU, таких как Aleo, IONET, Aethir и т.д. В этом режиме виртуальная машина не будет отключена, что делает его подходящим для любых проектов GPU майнинга. Однако учтите, что в этом режиме доход от аренды DBC уничтожается, поэтому этот режим будет выгоден, только если вознаграждения DBC превышают убытки от аренды.',
    text4_1: 'Для получения информации о доходах от майнинга DBC смотрите:',
    text4_2: '\n Формула расчета ежемесячного дохода машины.',
  },
  footer: {
    logo: 'Загрузить логотип',
    server: 'Сервисный электронный адрес'
  },
}