import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18n'
import { ElSelect, ElOption  } from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/fonts/fonts.css'

const app = createApp(App);
app.use(i18n)
app.use(router)
app.use(store)
app.component(ElSelect.name, ElSelect)
app.component(ElOption.name, ElOption)
app.mount('#app')

app.directive('animate', {
  mounted: function (el, binding) {
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.boby.clientHeight
      if (top < h) {
        if (el.className.indexOf(binding.value.class) == -1) {
          setTimeout(() => {
            el.classList.remove('animateHide')
          }, binding.value.delay)
          el.classList.add(binding.value.class)
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window.addEventListener('scroll', binding.addClass, true)
    binding.addClass()
  },
  beforeUnmount: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})