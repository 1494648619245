import { ApiPromise, WsProvider, Keyring } from '@polkadot/api'
import { formatBalance, BN_TEN, isHex, stringToU8a, u8aToHex, hexToU8a, stringToHex, hexToString } from '@polkadot/util';

const node = {
  dbc: 'wss://info1.dbcwallet.io'
}

let api = null

// 链上交互
export const GetApi = async () =>{
  if (!api) {
    const provider = new WsProvider(node.dbc)
    api = await ApiPromise.create({ 
      provider
    })
  }
  return { api }
}

// 创建账户
const keyring = new Keyring({type: 'sr25519'})

const getFloat = (number) => {
  number = number/Math.pow(10, 15)
  number = Math.floor(number*10000)/10000
  number = Number(number).toFixed(4)
  return number
}

// 获取发行总量
export const getTotalIssuance = async () => {
  await GetApi()
  let de = await api.query.balances.totalIssuance();
  const total = getFloat((de.toJSON()) / Math.pow(10, 9))
  const totalData = total + 'BDBC'
  return totalData
}

// 获取理事会前三数量
export const getMembers = async () => {
  await GetApi()
  let de = await api.query.elections.members();
  const memberData = de.toJSON()
  memberData.map(el => {
    if (getFloat(el.stake) > Math.pow(10, 9)) {
      const Stake = getFloat(el.stake / Math.pow(10, 9))
      el.stake_amount = Stake + 'BDBC'
    } else if (getFloat(el.stake) > Math.pow(10, 6)) {
      const Stake = getFloat(el.stake / Math.pow(10, 6))
      el.stake_amount = Stake + 'MDBC'
    } else {
      el.stake_amount = getFloat(el.stake)
    }
  })
  console.log(memberData, 'memberData');
  return memberData
}

// 获取理事会名称
export const getMemberName = async (wallet) => {
  await GetApi()
  let de = await api.query.identity.identityOf(wallet);
  const data =  de.toHuman()
  return data ? data.info.display.Raw : ''
}


// 获取质押数目
export const getTotalstak = async (wallet) => {
  await GetApi()
  const activeEra = await api.query.staking.activeEra();
  const era_num = activeEra.toJSON()
  const Stake = await api.query.staking.erasTotalStake(era_num.index);
  const Stake_data = Stake.toJSON()
  let StakeData = 0
  if (getFloat(Stake_data) > Math.pow(10, 9)) {
    const Stake = getFloat(Stake_data / Math.pow(10, 9))
    StakeData = Stake + 'BDBC'
  } else if (getFloat(Stake_data) > Math.pow(10, 6)) {
    const Stake = getFloat(Stake_data / Math.pow(10, 6))
    StakeData = Stake + 'MDBC'
  } else {
    StakeData = getFloat(Stake_data)
  }
  return Stake_data ? StakeData : 0
}

// 获取质押数目
export const getTreasury = async () => {
  await GetApi()
  const account = await api.query.system.account('5EYCAe5ijiYfyeZ2JJCGq56LmPyNRAKzpG4QkoQkkQNB5e6Z');
  const Treasury_data_free = account.toJSON()
  const Treasury_data = Treasury_data_free ? Treasury_data_free.data.free : 0
  let TreasuryData = 0
  if (Treasury_data) {
    if (getFloat(Treasury_data) > Math.pow(10, 9)) {
      const Treasury = getFloat(Treasury_data / Math.pow(10, 9))
      TreasuryData = Treasury + 'BDBC'
    } else if (getFloat(Treasury_data) > Math.pow(10, 6)) {
      const Treasury = getFloat(Treasury_data / Math.pow(10, 6))
      TreasuryData = Treasury + 'MDBC'
    } else {
      TreasuryData = getFloat(Treasury_data)
    }
  }
  return TreasuryData
}

// 获取发行，质押数字
export const getTotalNumber = async () => {
  await GetApi()
  const activeEra = await api.query.staking.activeEra();
  const era_num = activeEra.toJSON()
  const Stake = await api.query.staking.erasTotalStake(era_num.index);
  const Stake_data = Stake.toJSON()
  const Issuance = await api.query.balances.totalIssuance();
  const Issuance_data = Issuance.toJSON()
  return { Stake_data, Issuance_data }
}


// 获取块高
export const getBlockNumber = async () => {
  await GetApi()
  const number = await api.query.system.number();
  const block_num = number.toJSON()
  return block_num
}