<template>
  <div class="buyDBC" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('buyDBC.title') }}</div>
      <div class="buyType">
        <p class="buyType_text">{{ $t('buyDBC.block1.title') }}</p>
        <div class="buyType_list">
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/gate.png" alt="">
            <a target="_blank" href="https://www.gate.tv/trade/DBC_USDT" class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div>
          <!-- <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/huobi.png" alt="">
            <a target="_blank" href="https://www.htx.com/en-us/trade/dbc_usdt?type=spot" class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div> -->
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/bitmart.png" alt="">
            <a target="_blank" href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=DBC_USDT"  class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div>
        </div>
      </div>
      <div class="buyType">
        <p class="buyType_text">{{ $t('buyDBC.block2.title') }}</p>
        <div class="buyType_list">
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/SushiSwap.png" alt="">
            <a target="_blank" href="https://app.sushi.com/en/swap"  class="buyType_li_btn">{{ $t('buyDBC.see') }}</a>
          </div>
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/uniswap.png" alt="">
            <a target="_blank" href="https://app.uniswap.org/#/swap"  class="buyType_li_btn">{{ $t('buyDBC.see') }}</a>
          </div>
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/pancakeswap.png" alt="">
            <a target="_blank" href="https://pancakeswap.finance/swap"  class="buyType_li_btn">{{ $t('buyDBC.see') }}</a>
          </div>
        </div>
      </div>
      <div class="buyType">
        <p class="buyType_text">{{ $t('buyDBC.block3.title') }}</p>
        <p class="buyType_text1">{{ $t('buyDBC.block3.text') }}</p>
        <div class="buyType_list">
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/swft.png" alt="">
            <a target="_blank" href="https://swap.swft.pro/#/"  class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div>
        </div>
      </div>
      <div class="buyType">
        <p class="buyType_text">{{ $t('buyDBC.block4.title') }}</p>
        <div class="buyType_list">
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/haibaocloud.png" alt="">
            <a target="_blank" href="https://hibaocloud.com"  class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div>
          <div class="buyType_li">
            <img class="buyType_li_img" src="../assets/home/hycons-img.png" alt="">
            <a target="_blank" href="https://www.hycons.cloud "  class="buyType_li_btn">{{ $t('buyDBC.buy') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'buyDBC',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.buyDBC {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont_title  {
      margin-bottom: 96px;
    }
    .buyType {
      width: 100%;
      margin: auto;
      display: flex;
      margin-bottom: 40px;
      padding: 40px 24px;
      align-items: center;
      align-self: stretch;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      border-style: solid;
      border-bottom-width: 1px;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      background: #10132F;
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      
      .buyType_text {
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
        letter-spacing: 1px;
      }
      .buyType_text1 {
        font-size: 16px;
        color: #899AA9;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
        letter-spacing: 1px;
      }
      .buyType_list {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        .buyType_li {
          width: 370px;
          display: flex;
          margin-bottom: 24px;
          align-items: center;
          flex-direction: column;
          .buyType_li_img {
            height: 30px;
            margin-bottom: 24px;
          }
          .buyType_li_btn {
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 24px;
            line-height: normal;
            transition: all .5s;
            text-decoration: none;
            border: 1px solid #FFF;
            &:hover {
              color: #10132F;
              background: #FFF;
              border: 1px solid #FFF;
            }
          }
        }
      }
    }
  }
}
</style>
