<template>
  <div class="ERC20Wallet" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('ERC20Wallet.title') }}</div>
      <div class="walletList">
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text1') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text2') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text3') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'ERC20Wallet',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.ERC20Wallet {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    .walletList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 48px;
      align-items: center;
      justify-content: space-between;
      .walletLi {
        width: 100%;
        height: 154px;
        padding: 16px;
        position: relative;
        margin-bottom: 16px;
        box-sizing: border-box;
        border: 1px solid ;
        border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
        backdrop-filter: blur(12.5px);
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        .wallet_title {
          color: #FFF;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
        }
        .wallet_text {
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          margin-top: 8px;
          line-height: 20px;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
