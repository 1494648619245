export default {
  meau: {
    nav: 'Trang chủ',
    nav1: 'Sử dụng DBC',
    nav1_1: 'Mua dịch vụ Đám mây GPU',
    nav1_2: 'Triển khai nút POS để nhận phần thưởng',
    nav1_3: 'Bầu cử cho các nút POS',
    nav1_4: 'Trở thành đào mỏ GPU để nhận phần thưởng',
    nav1_5: 'Đăng ký trở thành thành viên Hội đồng',
    nav1_6: 'Bầu cử cho ứng cử viên Hội đồng',
    nav1_7: 'Tham gia vào các cuộc trưng cầu ý kiến',
    nav2: 'Học',
    nav2_1: 'DeepBrainChain là gì?',
    nav2_2: 'GPU là gì?',
    nav2_3: 'Lịch sử phát triển của DeepBrainChain',
    nav2_4: 'Sách trắng',
    nav2_5: 'Giới thiệu về Tự trị DBC DAO',
    nav2_6: 'Về đội ngũ',
    nav3: 'Nhà phát triển',
    nav3_1: 'API mạnh mẽ để thuê GPU',
    nav3_2: 'Làm thế nào để trở thành thương hiệu quán cafe đám mây',
    nav3_3: 'Làm thế nào để trở thành nhà phân phối máy chơi game đám mây',
    nav3_4: 'Làm thế nào để chuyển đổi game AAA thành game đám mây',
    nav3_5: 'Làm thế nào để đăng ký vốn kế toán của Hội đồng',
    nav4: 'Ví',
    nav4_1: 'DBCWallet',
    nav4_2: 'PolkaWallet',
    nav4_3: 'Ví ERC20',
    nav4_4: 'MathWallet',
    nav4_5: 'ImToken',
    nav4_6: 'TrustWallet',
    nav4_7: 'MetaMask',
    nav5: 'WIKI',
    nav6: 'Mua DBC',
    nav7: 'Cộng đồng',
    nav8: 'DBC Scan',
    nav9: 'Nhà phát triển AI',
    nav9_1: 'Sản phẩm AI phi tập trung là gì?',
    nav9_2: 'Làm thế nào để phát triển sản phẩm AI phi tập trung?',
    nav9_3: 'Làm thế nào để triển khai hợp đồng thông minh token?',
    nav9_4: 'Làm thế nào để phát triển hợp đồng thông minh liên quan đến thanh toán?',
    nav9_5: 'Làm thế nào để phát triển hợp đồng thông minh liên quan đến khai thác GPU?',
    nav9_6: 'Làm thế nào để phát triển container mô hình AI?',
    nav9_7: 'Làm thế nào để thực hiện kiểm tra toàn bộ quy trình?',
    nav9_8: 'Làm thế nào để thực hiện kiểm tra quy trình cơ bản?',
    nav10: 'Thợ mỏ',
    nav10_1: 'Làm thế nào để tham gia khai thác token DBC?',
    nav10_2: 'Làm thế nào để tham gia khai thác kép DBC và DeepLink?',
    nav10_3: 'Làm thế nào để tham gia khai thác nhiều token DBC và AI?',
    nav10_4: 'Làm thế nào để chỉ tham gia khai thác token AI?',
    nav10_5: 'Làm thế nào để tham gia khai thác nhiều token DBC và các token GPU khác?'
  },
  home: {
    cont1: {
      text1: 'Chào mừng bạn đến với',
      // text2: 'DeepBrain Chain',
      // text3: 'DeepBrain Chain là một mạng lưới tính toán GPU hiệu suất cao phi tập trung có thể mở rộng vô tận. Mục tiêu của nó là trở thành cơ sở hạ tầng tính toán GPU phổ biến nhất trên thế giới trong thời đại AI+Metaverse.',
      // text4: 'Thành lập vào năm 2017, DeepBrain Chain Foundation và DeepBrain Chain Council cùng thúc đẩy phát triển của DBC.',
      // text5: 'Mạng lưới chính của blockchain DBC đã được triển khai vào ngày 20 tháng 5 năm 2021, và mạng lưới tính toán GPU của DBC đã chính thức được triển khai vào ngày 22 tháng 11 năm 2021.',
      
      text2: 'DeepBrainChain 2.0',
      text2_1: 'DBC = AI + DePIN +GPU',
      text3: 'DeepBrain Chain 2.0 là chuỗi khối trí tuệ nhân tạo đầu tiên trên thế giới, xây dựng cơ sở hạ tầng AI phi tập trung, tương thích với tiêu chuẩn hợp đồng thông minh EVM và hỗ trợ chạy mô hình AI phi tập trung',
      text4: 'DeepBrainChain được thành lập vào năm 2017. Sự phát triển của DBC được thúc đẩy chung bởi Quỹ DeepBrainChain và Hội đồng DeepBrainChain',
      text5: 'Năm 2021, mạng lưới tính toán phân tán GPU DBC 1.0 được ra mắt và vào tháng 8 năm 2024, mạng thử nghiệm chuỗi công cộng AI DBC 2.0 đã được triển khai',
    },
    cont2: {
      title1: 'Huawei, DeepBrain Chain, và Polygon cùng thành lập Liên minh Metaverse và Web3',
      text1: 'Xem video',
      title2: 'DBC GPU Computing Mainnet đã được triển khai',
      text2: 'Thông tin tính toán GPU trên Mainnet',
      title3: 'Mạng tính toán AI',
      text3: 'Bài thuyết trình DBC',
      
      title4: 'Bán công khai DeepLink đang',
      title4_1: ' DIỄN RA',
      text4: 'Tham gia ngay'
    },
    cont3: {
      title: 'Hệ Sinh Thái AI DBC 2.0',
      text: 'Bất kỳ dự án AI nào cũng có thể phát triển AI phi tập trung dựa trên DBC 2.0, phát hành token riêng và chạy mô hình AI vĩnh viễn, không thể bị đóng lại.',
      title1: 'DeepLink',
      text1: 'Giao thức DeepLink là một giao thức chơi game đám mây AI phi tập trung, kết hợp công nghệ AI và blockchain để cung cấp công nghệ kết xuất game với độ trễ cực thấp.',
      btn1_1: 'DeepLink',
      title2: 'DecentralGPT',
      text2: 'DecentralGPT là một mạng suy luận AI LLM phi tập trung. Nó hỗ trợ nhiều mô hình ngôn ngữ mã nguồn mở và cam kết xây dựng một AGI an toàn, bảo vệ quyền riêng tư, dân chủ, minh bạch, mã nguồn mở và tiếp cận được cho tất cả mọi người.',
      btn2_1: 'DecentralGPT',
      title3: 'SuperImage',
      text3: 'SuperImage có nhiều mô hình khuếch tán văn bản thành hình ảnh tiềm ẩn để tạo ra hình ảnh chân thực từ bất kỳ văn bản nào, khuyến khích tự do sáng tạo hình ảnh đáng kinh ngạc và giúp hàng tỷ người tạo ra tác phẩm nghệ thuật tuyệt vời trong vài giây.',
      btn3_1: 'SuperImage',
      title4: 'DRCpad',
      text4: 'Thị trường sơ cấp đầu tiên cho giao dịch các nút AI. Tập trung vào nền tảng giao dịch nút của chuỗi công khai AI DBC và chọn lọc các dự án AI chất lượng cao.',
      btn4_1: 'DRCpad',
      title5: 'Hycons',
      text5: 'Đối tác kinh doanh của HYCONS CLOUD: AI, lái xe tự hành, y học sinh học, trò chơi đám mây, metaverse, đám mây XR, AR, VR, kết xuất hình ảnh, mô phỏng khí hậu. Bất kỳ ai cần GPU đều có thể trở thành đối tác của HYCONS.',
      btn5_1: 'Hycons Cloud',
      title6: 'Trình duyệt Blockchain DBC Mainnet',
      text6: 'Trình duyệt blockchain Mainnet có thể kiểm tra số lượng chủ sở hữu ví, số dư ví Mainnet và thông tin chuyển khoản ví DBC Mainnet.',
      btn6_1: 'Trình duyệt Blockchain DBC Mainnet',
      title7: 'DBCSCAN',
      text7: 'DBCSCAN là trình duyệt EVM của chuỗi công khai AI DBC. Hiện tại đã hoạt động trên mạng thử nghiệm, hỗ trợ truy vấn giao dịch và triển khai hợp đồng thông minh.',
      btn7_1: 'DBCSCAN',
      title8: 'DBCWallet',
      text8: 'DBCWallet là ví đầy đủ chức năng được bảo trì bởi Quỹ DBC. Nó hỗ trợ phần thưởng bỏ phiếu POS, đặt cược POS, bầu cử và bỏ phiếu hội đồng, bỏ phiếu đề xuất kho bạc và trưng cầu dân ý.',
      btn8_1: 'DBCWallet',
      title9: 'XAIAgent',
      text9: 'XAIAgent là một nền tảng AI Agent phi tập trung trên chuỗi DBC, cung cấp dịch vụ tạo, vận hành và giao dịch AI Agent. Nền tảng này bao gồm XAIAgent Platform để tạo Agent không cần mã và XAgentScope Framework để phát triển nâng cao',
      btn9_1: 'XAIAgent',
    },
    cont4: {
      title: 'DBC Hôm Nay',
      text: 'Thống Kê Mạng Mới Nhất',
      text1: 'Số Lượng GPU',
      text2: 'Tỷ Lệ Thuê GPU',
      text3: 'Số Lượng DBC Được Cọc trên GPU',
      text4: 'Số Lượng DBC Tiêu Thụ Trong Thuê GPU',
      text5: 'Tổng Số DBC Phát Hành',
      text6: 'Số Lượng Địa Chỉ Sở Hữu DBC',
      text7: 'Số Lượng DBC Được Cọc cho Staking',
      text8: 'Quỹ DAO Hội Đồng'
    },
    cont5: {
      title: 'Tại sao các nhà phát triển AI lại chọn chúng tôi?',
      text: 'Bất kỳ ai cũng có thể xây dựng nền tảng dịch vụ đám mây GPU của riêng họ dựa trên DBC.',
      block1: {
        title: 'Sử dụng GPU miễn phí',
        text: 'Các nhà phát triển AI chỉ cần phát hành token của riêng mình để sử dụng GPU miễn phí.',
      },
      block2: {
        title: 'Rút ngắn chu kỳ R&D',
        text: 'Có thể giúp các nhà phát triển AI rút ngắn thời gian nghiên cứu và phát triển AI phi tập trung từ 3 năm xuống còn 3 tháng, giảm đáng kể chi phí phát triển.',
      },
      block3: {
        title: 'Triển khai hợp đồng thông minh',
        text: 'Hỗ trợ các nhà phát triển AI triển khai hợp đồng thông minh của riêng họ và phát hành token của riêng mình.',
      },
      block4: {
        title: 'TPS cao, Gas thấp',
        text: '6 giây một khối, hỗ trợ trên 1000 TPS, phí gas thấp đến 0.001 DBC.',
      },
      block5: {
        title: 'Mã nguồn mở và không cần cấp phép',
        text: 'Bất kỳ nền tảng đám mây nào cũng có thể xây dựng nền tảng dịch vụ đám mây GPU của riêng họ dựa trên DBC.',
        text1: 'Phục vụ khách hàng trong lĩnh vực cụ thể của họ mà không cần cấp phép.',
      },
      block6: {
        title: 'Triển khai AI mô hình phi tập trung',
        text: 'Hỗ trợ triển khai AI mô hình phi tập trung, đảm bảo mô hình không bao giờ bị gián đoạn hoặc ngừng hoạt động. Hoạt động mô hình phi tập trung thực sự mang lại sự tin tưởng của người dùng và một cơ chế hoàn toàn minh bạch.',
      }
    },
    cont6: {
      title: 'Giá trị của Token DBC',
      block1: {
        text: 'Tổng số token DBC là 100 tỷ, với cung cấp cố định và không bao giờ tăng. Toàn bộ cung sẽ được phát hành trong khoảng 100 năm.',
      },
      block2: {
        text: 'Token DBC là duy nhất trong mạng lưới DBC.',
        text1: 'Mỗi khi người dùng thuê GPU, họ cần mua token DBC từ các sàn giao dịch hoặc từ các nguồn khác, sau đó trả một số lượng nhất định DBC cho mạng lưới blockchain DeepBrainChain để có quyền sử dụng GPU.',
      },
      block3: {
        text: 'DBC tuân theo mô hình thu hẹp. Khi tổng số GPU trong mạng lưới DeepBrainChain dưới 5.000, 30% phí thuê của người dùng bị phá hủy. Khi vượt quá 5.000, 70% bị phá hủy và khi vượt quá 10.000, 100% bị phá hủy.',
        text1: 'DBC được người dùng thanh toán cần được mua từ các sàn giao dịch hoặc từ các nguồn khác. Mỗi lần người dùng thuê GPU, tổng số DBC lưu thông trên thị trường sẽ giảm đi.',
      },
      block4: {
        text: 'Các siêu nút DBC POS cần đặt cược DBC để nhận phần thưởng khối. Tổng số tiền cược DBC hiện tại trong toàn bộ mạng là {stakeNum}, chiếm {ratio}% tổng số DBC được phát hành.',
      },
      block5: {
        text: 'Các nhà khai thác cần đặt cược DBC để cung cấp GPU. Mỗi thẻ yêu cầu đặt cược 100.000 DBC hoặc tương đương tối đa là 800 đô la trong DBC. Điều này có nghĩa là càng có nhiều GPU trong mạng lưới DeepBrainChain, càng nhiều DBC được đặt cược. Tổng số tiền cược DBC của các nhà khai thác GPU trong toàn bộ mạng hiện tại là {stakeNum}, chiếm {ratio}% tổng số DBC được phát hành.',
      },
      block6: {
        text: 'Token DBC là token quản trị của DeepBrainChain.',
        text1: 'Hội đồng DAO chọn 21 thành viên hội đồng mỗi 4 tháng từ tất cả các ứng cử viên.',
        text2: 'Các ứng cử viên có số phiếu bầu token DBC cao nhất trong top 21 có thể được bầu chọn.',
        text3: 'Mỗi token DBC tương đương với một phiếu bầu.',
        text4: 'Hội đồng DAO cùng quản lý quỹ kế toán để hỗ trợ phát triển hệ sinh thái của DeepBrainChain.',
      }
    },
    cont7: {
      title: 'Nơi Giao Dịch DBC',
      text1: 'Độ chính xác DBC: 15, Ký hiệu token: DBC, Địa chỉ hợp đồng DBC ERC20 and BEP20:',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: 'Mô hình Kinh tế Token',
      text1: 'Số lượng Phát hành hàng ngày của Phần Thưởng Sức Mạnh Tính Toán DBC: 1,095,900 đồng, DBC Mainnet POS Nodes Sản xuất Hàng ngày: 273,900 đồng',
      table: {
        tr1: 'Sử dụng',
        tr2: 'Thể loại',
        tr3: 'Tổng cộng',
        tr4: 'Số lượng (tỷ)',
        tr5: 'Lưu thông (tỷ)',
        tr6: 'Sắp phát hành (tỷ)',
        tr7: 'Ghi chú',
        tbody1: {
          text1: 'Bán sớm',
          text2: 'Bán cho các nhà đầu tư chuyên nghiệp hoặc các công ty AI để sử dụng quyền dịch vụ hệ sinh thái DBC',
        },
        tbody2: {
          text: 'Quỹ và Hệ sinh thái',
          text1: 'Quỹ',
          text2: '1 tỷ được mở khóa từ 2018 đến 2021 (mỗi năm 250 triệu) với 15 năm còn lại được mở khóa, mở khóa 49 triệu mỗi năm',
        },
        tbody3: {
          text1: 'Hội đồng',
          text2: 'Toàn bộ hội đồng được cộng đồng bầu cử, và DBC được quản lý hoàn toàn bởi hội đồng, mở khóa trong vòng 15 năm, mỗi năm mở khóa 51 triệu, bắt đầu từ tháng 1 năm 2022',
        },
        tbody4: {
          text1: 'Nhóm',
          text2: 'Sau khi niêm yết trên thị trường, 10% bị khóa trong 1 tháng, và phần còn lại được mở khóa mỗi năm trong vòng 10 năm, bắt đầu từ năm 2018',
        },
        tbody5: {
          text1: 'Khuyến nghị sức mạnh trước mạng chính',
          text2: 'Được sử dụng để khuyến khích các máy đào tham gia vào mạng lưới sức mạnh tính toán DBC trước khi mạng chính hoạt động',
        },
        tbody6: {
          text: 'Phần thưởng nút',
          text1: 'Nút siêu',
          text2: 'Nút siêu nhận được phần thưởng bằng cách kế toán cho các khối. Trong 3 năm đầu, họ nhận được 100 triệu đồng mỗi năm, sau đó là 50 triệu đồng mỗi năm trong 5 năm, và sau đó phần thưởng giảm một nửa mỗi 5 năm. Phần thưởng bắt đầu từ tháng 5 năm 2021',
        },
        tbody7: {
          text1: 'Nút sức mạnh tính toán',
          text2: 'Nút sức mạnh tính toán nhận được phần thưởng trực tuyến cho việc đóng góp vào sức mạnh tính toán. Trong 3 năm đầu, họ nhận được 400 triệu đồng mỗi năm, sau đó là 200 triệu đồng mỗi năm trong 5 năm, và sau đó phần thưởng giảm một nửa mỗi 5 năm. Phần thưởng bắt đầu từ tháng 8 năm 2021',
        },
        tbody8: {
          text1: 'Toàn bộ',
        }
      }
    },
    cont9: {
      title: 'Cơ quan đầu tư và Đối tác'
    },
    cont10: {
      title: 'Lịch sử Phát triển & Lộ trình'
    },
    cont12: {
      title: 'Báo chí'
    },
    cont13: {
      title: 'Hoạt động Cộng đồng'
    } 
  },
  buyDBC: {
    title: 'Mua DBC',
    buy: 'Mua',
    see: 'Xem',
    block1: {
      title: 'Mua DBC trên Sàn giao dịch Tập trung'
    },
    block2: {
      title: 'Mua DBC trên Sàn giao dịch Phi tập trung'
    },
    block3: {
      title: 'Mua DBC trên Các nền tảng Swap',
      text: 'Có thể trao đổi trực tiếp thông qua ví của bạn'
    },
    block4: {
      title: 'Mua DBC trên Các Nền tảng Đám mây của Bên thứ ba'
    }
  },
  buyGpuServer: {
    title: 'Sử dụng Token DBC để Mua Dịch vụ Đám mây GPU',
    text1: 'DeepBrain Chain là một mạng lưới tính toán GPU phân tán hiệu suất cao có thể mở rộng vô tận. Các nhà cung cấp từ khắp nơi trên thế giới thêm các máy chủ GPU vào mạng lưới DBC và cược 100.000 hoặc 800 đô la tương đương DBC để đảm bảo tính ổn định và sẵn có của máy chủ GPU. Các nhà phát triển bên thứ ba có thể phát triển các nền tảng đám mây của riêng họ dựa trên DBC để cung cấp dịch vụ cho người dùng. Các nhà phát triển chỉ cần phục vụ người dùng mà không cần mua máy chủ GPU. Tất cả các máy chủ GPU đều có thể truy cập tự động từ mạng lưới DBC.',
    text2: 'Người dùng có thể thuê GPU bằng DBC trên nền tảng đám mây hoặc sử dụng tiền tệ pháp lý để thuê GPU. Nếu sử dụng tiền tệ pháp lý để thuê GPU, nền tảng đám mây sẽ tự động chuyển đổi tiền tệ pháp lý thành DBC để thuê GPU trong DeepBrain Chain.',
    text3: 'Dịch vụ Ứng dụng Sinh thái DBC'
  },
  getReward: {
    title: 'Triển khai các nút sản xuất khối POS để nhận phần thưởng',
    text: 'DBC áp dụng cơ chế đồng thuận POS. Hiện tại, có tổng cộng 41 nút sản xuất khối đồng thuận. Mỗi 24 giờ, 41 nút được bầu từ tất cả các nút làm nút sản xuất khối. Các nút được chọn có thể tạo ra phần thưởng 272,500 DBC mỗi 24 giờ. Quy tắc bầu cử: Các nút xếp hạng trong 41 nút hàng đầu về DBC tự gắn kết và DBC bị bỏ phiếu cho họ.',
    seedetails: 'Xem chi tiết',
    cont: {
      title: 'Trở thành nút xác minh DBC'
    },
    procedure1: {
      title1: 'Cấu hình máy',
      text1: 'Bộ nhớ: 8GB',
      text2: 'CPU: 2 Lõi',
      text3: 'Ổ cứng: 100GB',
    },
    procedure2: {
      title1: 'Tạo tài khoản tài trợ (có thể bỏ qua nếu bạn đã có tài khoản tài trợ)',
      text1: 'Tham khảo:',
      text2: 'Cách tạo tài khoản'
    },
    procedure3: {
      title1: 'Lấy tệp nhị phân dbc-chain',
      text1: 'Phương pháp 1: Sử dụng phiên bản đã biên soạn trước',
      text2: 'Nếu không chạy đúng, vui lòng sử dụng Phương pháp 2 để tự biên soạn:',
      text3: 'Phương pháp 2: Biên soạn từ mã nguồn',
      text4: '# Cài đặt các phụ thuộc',
      text5: '# Biên soạn dbc-chain'
    },
    procedure4: {
      title1: 'Chạy nút đồng bộ',
      text1: 'Nếu bạn biên soạn từ mã nguồn, đường dẫn tới tập tin thực thi như sau:',
      text2: 'Sau khi đồng bộ hoàn tất, đóng chương trình bằng Control + C. Bạn có thể xác định xem đồng bộ đã hoàn tất bằng cách so sánh mục tiêu và tốt nhất. Khi sự khác biệt giữa mục tiêu và tốt nhất không lớn (ví dụ, trong phạm vi 100), có thể coi đồng bộ đã hoàn tất.',
      text3: 'Giải thích tham số:',
      text4: '--base-path: Chỉ định thư mục mà blockchain lưu trữ dữ liệu. Nếu không chỉ định, đường dẫn mặc định sẽ được sử dụng. Nếu thư mục không tồn tại, nó sẽ được tạo tự động. Nếu thư mục này đã chứa dữ liệu blockchain, sẽ xảy ra lỗi, trong trường hợp này bạn nên chọn một thư mục khác hoặc xóa nội dung của thư mục này.',
      text5: '--pruning=archive: Khởi động blockchain ở chế độ lưu trữ lưu trữ',
      text6: '--bootnodes: Chỉ định địa chỉ nút khởi động.'
    },
    procedure5: {
      title1: 'Chạy nút dưới dạng người xác minh',
      text1: 'Sau khi hoàn thành bước 3 về đồng bộ dữ liệu nút, đóng lệnh đồng bộ. Sau đó, chạy nút dưới dạng người xác minh:',
      text2: 'Nếu bạn biên soạn từ mã nguồn, đường dẫn tới tập tin thực thi như sau:',
      text3: 'Lưu ý, --name YourNodeName ở đây là đặt tên cho nút của bạn. Bạn có thể đặt cho nút của mình một cái tên duy nhất và dễ nhận biết, mà người khác có thể thấy trên mạng.',
      text4: 'Tạo rotateKey',
      text5: 'Trên thiết bị đang chạy lệnh nút xác minh, hãy chạy lệnh sau'
    },
    procedure6: {
      title1: 'Thiết lập số tiền kết nối (bond)',
      text1: 'Mở',
      text2: 'Bạn sẽ thấy số dư của mình:',
      text3: 'Chuyển đến Mạng > Đầu cơ > Hoạt động tài khoản > Tài khoản stash.',
      text4: 'Thiết lập số tiền kết nối (đảm bảo rằng có đủ số dư trên tài khoản của bạn để gửi giao dịch):',
      text5: 'Giải thích:',
      text6: 'Tài khoản stash: tài khoản tiền của bạn, ở đây chúng tôi kết nối 45 DBC, đảm bảo có ít nhất số dư này trên tài khoản của bạn.',
      text7: 'Tài khoản điều khiển: tài khoản này cũng cần có một lượng nhỏ DBC để gửi giao dịch để bắt đầu và dừng vận động viên.',
      text8: 'Số tiền đã kết nối: số DBC bạn muốn kết nối/stake, lưu ý rằng bạn không cần phải kết nối toàn bộ số dư có sẵn trên tài khoản và bạn có thể tăng số tiền kết nối sau này.',
      text9: 'Đích đến thanh toán: phần thưởng mà vận động viên nhận được sẽ được gửi đến tài khoản này. Tài khoản này có thể được thiết lập thành bất kỳ tài khoản nào. Dưới đây,',
      text10: 'Tài khoản stash (tăng số lượng trong staking): Lợi nhuận được thanh toán vào tài khoản stash và stake.',
      text11: 'Tài khoản stash (không tăng số lượng trong staking): Lợi nhuận được thanh toán vào tài khoản stash mà không cần tăng staking.'
    }
  },
  voteReward: {
    title: 'Nhận phần thưởng bằng cách bỏ phiếu cho các nút POS',
    text: 'Mỗi siêu nút nhận khoảng 6700 DBC tiền thưởng mỗi ngày. Nếu một nút bầu cử được chọn làm siêu nút, những người bầu cử cũng có thể nhận được tiền thưởng. Số tiền thưởng cho người bầu cử được tính là (1 - tỷ lệ hoa hồng được thiết lập bởi người bầu cử) * số phiếu bầu của tôi / tổng số phiếu bầu * 27.25 nghìn. Ví dụ, nếu tổng số phiếu bầu cho siêu nút mà tôi bầu là 20 triệu DBC, và tôi bầu với 1 triệu DBC, và tỷ lệ hoa hồng của nút là 10%, thì số tiền thưởng hàng ngày của tôi sẽ là 90% * 1 triệu / 20 triệu * 6700 = 301 DBC. Xấp xỉ, một người có thể nhận được khoảng 110.000 DBC tiền thưởng mỗi năm.',
    seedetails: 'Xem chi tiết',
    cont: {
      title: 'Trở thành ứng cử viên DBC'
    },
    procedure1: {
      title1: 'Tạo Tài khoản Tài trợ (Bỏ qua nếu bạn đã có)',
      text1: 'Tham khảo:',
      text2: 'Cách Tạo Tài khoản'
    },
    procedure2: {
      title1: 'Thu thập một số DBC',
      text1: 'Mở',
      text2: 'Bạn sẽ thấy số dư của mình:'
    },
    procedure3: {
      title1: 'Thiết lập Số tiền gói cước',
      text1: 'Chuyển đến Mạng> Stake> Hành động Tài khoản> Tài khoản Stash,',
      text2: 'Trong cửa sổ bật lên, chọn Tài khoản Stash, Tài khoản Điều khiển và nhập Số tiền gói cước, sau đó nhấn Tiếp theo.',
    }
  },
  gpuMiner:{
    title: 'trở thành một máy đào GPU để nhận phần thưởng',
    text: 'Trong hệ sinh thái DBC, các thợ mỏ đóng vai trò rất quan trọng. Các thợ mỏ cung cấp máy chủ GPU, là nền tảng của mạng lưới DBC. Hiện nay, mỗi ngày có 1,09 triệu phần thưởng DBC được trao cho các nhà cung cấp GPU. Các thợ mỏ không chỉ nhận được phần thưởng mà còn thu nhập từ việc cho thuê. Đối với số lượng GPU dưới 5.000, thu nhập từ việc cho thuê là 70%. Đối với GPU từ 5.000 đến dưới 10.000, thu nhập từ việc cho thuê cho người dùng là 30%. Đối với hơn 10.000 GPU, không có thu nhập từ việc cho thuê của người dùng, nhưng thu nhập từ phần thưởng tăng thêm 30% mỗi khi máy được cho thuê. Mỗi GPU cần đặt cược 100.000 DBC hoặc tương đương 800 USD trong DBC, tùy thuộc vào cái nào thấp hơn.',
    seedetails: 'xem các quy định chi tiết',
    cont: {
      title: 'công thức tính thu nhập hàng tháng cho máy GPU',
      text: 'Công thức tính thu nhập hàng tháng cho máy: Công suất băm máy / tổng lượng băm mạng * 1.095.890 * giá DBC * hệ số địa lý * hệ số mô hình lớn * 1.3 (tăng 30% công suất băm khi cho thuê) * 30 + công suất băm máy * hệ số địa lý * hệ số mô hình lớn * 0.508 * 70%',
      text1: 'Mô hình máy:',
      text2: 'Số lượng card đồ họa:',
      text3: 'Số lượng bộ nhớ:',
      text4: 'Khu vực địa lý:',
      text5: 'Vui lòng chọn',
      text6: 'Thu nhập hàng tháng hiện tại là:',
      text7: 'DBC tương đương USD = ',
      text8: 'Thu nhập từ phần thưởng trên chuỗi:',
      text9: 'DBC (tương đương',
      text10: 'USD Giá DBC hiện tại:',
      text11: 'USD) + thu nhập từ việc cho thuê:',
      text12: 'DBC tương đương USD'
    },
    country: {
      country1: 'Trung Quốc',
      country2: 'Bắc Mỹ',
      country3: 'Nam Mỹ',
      country4: 'Châu Âu',
      country5: 'Trung Đông',
      country6: 'Hàn Quốc',
      country7: 'Nhật Bản',
      country8: 'Đài Loan',
      country9: 'Hồng Kông và Ma Cao',
      country10: 'Malaysia',
      country11: 'Indonesia',
      country12: 'Singapore',
      country13: 'Việt Nam'
    }
  },
  applyCouncil: {
    title: 'Đệ trình đơn xin trở thành thành viên hội đồng',
    text: 'Hội đồng DBC là tổ chức DBC DAO, hiện đang quản lý hơn 800 triệu token DBC trong Kho DBC, và mỗi tháng có 4,25 triệu DBC được chuyển vào Kho, tiếp tục cho đến năm 2036. Hội đồng bao gồm 21 thành viên, và cuộc bầu cử được tổ chức mỗi 4 tháng. 21 ứng cử viên có số phiếu bầu cao nhất được chọn.',
    text1: 'Là một thành viên hội đồng, bạn có thể bỏ phiếu cho các đề xuất về việc sử dụng quỹ từ Kho. Mọi đề xuất đều cần sự chấp thuận của ít nhất 13 trong số 21 thành viên hội đồng để được thông qua. Nếu số phiếu không vượt quá 13, Thành viên Hội đồng Chính có thể bỏ phiếu thay mặt cho những thành viên không tham gia bỏ phiếu.',
    text2: 'Trở thành một thành viên hội đồng có thể tăng cơ hội được cộng đồng và các thành viên khác trong hội đồng tin tưởng. Khi một thành viên hội đồng đề xuất một đề xuất về quỹ từ Kho, nó có khả năng được thông qua nhiều hơn.',
    text3: 'Bất kỳ thành viên cộng đồng nào cũng có thể khởi xướng một đề xuất về việc sử dụng quỹ, nhưng để đề xuất đó được đưa vào quá trình bỏ phiếu, nó phải được một trong 21 thành viên hội đồng trình bày.',
    seedetails1: 'Nhóm thảo luận của Hội đồng',
    seedetails2: 'Xem thông tin về Hội đồng',
    cont: {
      title: 'Quy trình bầu cử Hội đồng'
    },
    procedure1: {
      title1: 'Ví mạng chính của DBC dựa trên Polkadot',
      text1: 'Trước tiên, bạn cần có một ví mạng chính của DBC dựa trên Polkadot. Sau khi tạo ví, mở địa chỉ mạng chính DBC.',
      text2: 'Chọn mục "Quản trị".',
      text3: 'Trong menu xổ xuống, chọn "Quốc hội".',
      text3_1: 'Bên trong, bạn sẽ tìm thấy tổng quan về trạng thái bầu cử quốc hội hiện tại.',
      text4: '“Ghế” - Các thành viên hội đồng hiện tại.',
      text5: '“Người thắng cuộc” - Những người dự thi tạm thời bị loại (người đứng thứ hai).',
      text6: '“Ứng viên” - Những người đã nộp đơn nhưng tạm thời không vào top 20.',
      text7: 'Ở góc trên bên phải của trang là các nút "Bỏ phiếu" và "Nộp ứng cử".',
      text8: 'Nếu bạn muốn trở thành thành viên hội đồng, chọn "Nộp ứng cử"; nếu bạn chỉ muốn bỏ phiếu, chọn "Bỏ phiếu".'
    },
    procedure2: {
      title1: 'Tiếp theo, hãy xem "Bỏ phiếu"',
      text1: 'Sau khi đăng nhập, bạn sẽ thấy số dư tài khoản của mình.',
      text2: 'Chọn ứng cử viên mà bạn muốn, nhập số lượng phiếu vào "Giá trị bỏ phiếu".',
      text3: ' 1 DBC = 1 phiếu. Lưu ý rằng số phiếu dưới 1 DBC sẽ không được tính.',
      text4: 'Sau khi đã chọn, xác nhận chữ ký, nhập mật khẩu để xác nhận.'
    }
  },
  councilVoting: {
    title: 'Bỏ phiếu cho ứng cử viên Hội đồng',
    text: 'Hội đồng DBC là tổ chức DAO quản lý Quỹ DBC. Các thành viên Hội đồng đóng vai trò quan trọng trong việc phát triển hệ sinh thái DBC. Mỗi chủ sở hữu DBC đều nên chú ý và tham gia bỏ phiếu trong hội đồng, đưa ra phiếu bầu linh thiêng cho ứng cử viên Hội đồng mà họ ủng hộ, chỉ có các ứng cử viên xếp hạng trong top 21 về tổng số phiếu mới có thể được bầu vào Hội đồng.',
    seedetails1: 'Nhóm Thảo luận Họp Hội đồng',
    seedetails2: 'Xem thông tin Hội đồng',
    cont: {
      title: 'Làm thế nào để bỏ phiếu?'
    },
    procedure1: {
      title1: 'Mở trang bỏ phiếu trong Hội đồng',
      text1: 'Mở trang bỏ phiếu trong Hội đồng,',
      text2: 'nhấn vào nút Bỏ phiếu (đăng nhập vào ví trước)',
      text3: 'Thiết lập Giá trị Bỏ phiếu',
      text4: 'Thiết lập giá trị bỏ phiếu, chọn ứng cử viên Hội đồng, ứng cử viên sẽ được đặt trong khung bên phải. Nếu chọn nhiều ứng cử viên, phiếu bầu sẽ được phân phối đều giữa họ. Cuối cùng, nhấn vào nút Bỏ phiếu. Lưu ý: Sau khi bỏ phiếu, các đồng tiền bỏ phiếu sẽ được đặt cọc và không thể chuyển tiếp. Chỉ sau khi gỡ cọc mới có thể thực hiện các hoạt động chuyển tiền. Đề xuất bỏ phiếu vào ngày trước khi kết thúc cuộc bầu cử mới trong hội đồng, để thời gian cọc tiền là ngắn nhất.',
      text5: 'Khung đỏ trong hình dưới đây cho thấy thời gian còn lại cho đến khi cuộc bầu cử mới kết thúc trong hội đồng',
      text6: 'Hủy bỏ phiếu',
      text7: 'Gỡ cọc đồng bỏ phiếu. Bạn có thể hủy bỏ phiếu bất kỳ lúc nào. Sau khi nhấn vào nút "Hủy bỏ tất cả", việc cọc tiền sẽ bị hủy. Tuy nhiên, sau khi hủy bỏ phiếu, phiếu bầu sẽ bị hủy. Đề xuất hủy bỏ phiếu sau khi kết thúc cuộc bầu cử mới trong hội đồng.'
    }
  },
  referenda: {
    title: 'Tham gia bình chọn công khai',
    text: 'Bất kỳ cập nhật công nghệ và thay đổi tham số nào trên mạng lưới blockchain của DBC cũng cần thông qua bình chọn công khai. Bình chọn công khai là yếu tố cốt lõi của việc quản lý cộng đồng DBC. Mỗi chủ sở hữu DBC đều quan tâm đến sự phát triển công nghệ và sản phẩm của DBC, có thể theo dõi bình chọn công khai và tham gia vào đó. Bạn cũng có thể chia sẻ đề xuất của mình về phát triển công nghệ DBC và khởi xướng đề xuất cho bình chọn công khai.',
    seedetails: 'Xem các đề xuất chung',
    cont: {
      title: 'Làm thế nào để tham gia bình chọn trên DBC Mainnet'
    },
    procedure1: {
      title1: '【Quản lý】-【Quyền dân chủ】Giới thiệu chức năng giao diện',
      text1: 'Đề xuất — Đề xuất từ công chúng. Sau mỗi kỳ khởi động (hiện tại là mỗi 28 ngày), đề xuất nhận được nhiều sự ủng hộ nhất (tính bằng tổng số dbc ủng hộ nhiều nhất) sẽ được chuyển sang bình chọn công khai.',
      text2: 'Bên ngoài — Các đề xuất từ Hội đồng.',
      text3: 'Kỳ khởi động — Giai đoạn mở bình chọn công khai mới, hiện tại là 28 ngày.'
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    text1: 'DeepBrain Chain 2.0 là một blockchain AI, nơi các nhà phát triển AI có thể phát triển các sản phẩm AI phi tập trung dựa trên DBC. Các mô hình AI có thể hoạt động phi tập trung, giúp rút ngắn 90% thời gian nghiên cứu và phát triển',
    text2: 'DeepBrain Chain được thành lập vào năm 2017, được đẩy mạnh bởi DeepBrain Chain Foundation và DeepBrain Chain Council để thúc đẩy sự phát triển của DBC.',
    text3: 'Mạng chính tính toán GPU của DBC chính thức ra mắt vào ngày 22 tháng 11 năm 2021, và mạng thử nghiệm của chuỗi khối AI DBC sẽ ra mắt vào tháng 8 năm 2024',
    text4: 'DBC tập hợp các nhà cung cấp GPU từ khắp nơi trên thế giới thông qua cơ chế thưởng của blockchain để cung cấp các máy chủ GPU cho mạng DBC. Sau đó, thông qua công nghệ mạng phi tập trung của DBC, các nhà phát triển AI có thể sử dụng các GPU này. Các nhà phát triển AI phát hành token của riêng họ để thưởng cho các nhà cung cấp GPU, từ đó thu hút một số lượng lớn các nhà cung cấp GPU',
    text5: 'Càng nhiều ứng dụng hệ sinh thái, nhu cầu GPU của chính các ứng dụng hệ sinh thái càng lớn. Càng nhiều giao dịch DBC được sử dụng hàng ngày, số lượng DBC bị phá hủy càng nhiều, và giá trị của DBC càng lớn.',
    text6: 'Ví dụ, lấy ứng dụng quán net đám mây làm ví dụ: người dùng quán net đám mây cần mua đồng tiền trên thị trường giao dịch để sử dụng GPU. Đối với mỗi GPU sử dụng bổ sung, 30% số đồng được mua từ thị trường giao dịch sẽ bị phá hủy. Nếu có 1000 quán net đám mây, mỗi quán có 100 máy, và mỗi máy được sử dụng trong 10 giờ mỗi ngày, trả 0,1 USD mỗi giờ, trong đó có 30% bị phá hủy. Mỗi tháng, có 900.000 USD đồng bị phá hủy. Theo giá đồng là 0,002 USDT, hơn 400 triệu đồng cần bị phá hủy mỗi tháng. Đồng thời, để hỗ trợ 1000 quán net đám mây, cần 70.000 máy, và phải giao dịch thêm 7 tỷ đồng.'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU, viết tắt của Đơn vị Xử lý Đồ họa (Graphics Processing Unit), là một đơn vị tính toán chuyên biệt được thiết kế để xử lý các nhiệm vụ liên quan đến đồ họa và video. Khác với CPU (Đơn vị Xử lý Trung tâm), GPU được thiết kế đặc biệt để xử lý song song các lượng dữ liệu lớn.',
    block1: {
      title: 'Hiệu suất Song song Cao',
      text: 'GPU được hình thành từ hàng trăm hoặc hàng nghìn lõi nhỏ, cho phép nó xử lý một lượng lớn dữ liệu cùng một lúc. Ví dụ, khi kết xuất đồ họa 3D, mỗi lõi có thể xử lý độc lập một điểm ảnh hoặc một điểm đỉnh, làm tăng đáng kể tốc độ xử lý.'
    },
    block2: {
      title: 'Tối Ưu hóa cho Đồ họa',
      text: 'Ban đầu được thiết kế để tăng tốc quá trình kết xuất đồ họa, GPU hiệu quả trong việc xử lý các nhiệm vụ liên quan đến hình ảnh và video, như ánh sáng và phép chiếu.'
    },
    block3: {
      title: 'Phạm Vi Ứng Dụng Rộng',
      text: 'Mặc dù GPU ban đầu được thiết kế cho ngành công nghiệp trò chơi và thiết kế đồ họa chuyên nghiệp, nhưng hiện nay chúng cũng rất quan trọng trong nhiều lĩnh vực khác, đặc biệt là trong trí tuệ nhân tạo và học máy.'
    },
    title3: 'Trò chơi và Trí tuệ Nhân Tạo',
    title4: 'Tại Sao Chúng Ta Cần GPU?',
    text2: 'Khả năng xử lý song song cao của GPU giúp chúng xuất sắc trong việc xử lý các nhiệm vụ tập trung vào đồ họa và xử lý dữ liệu quy mô lớn, khiến chúng trở nên không thể thiếu trong lĩnh vực trò chơi và trí tuệ nhân tạo.',
    text3: 'Hiện nay, giá trị thị trường của nhà lãnh đạo sản xuất chip GPU, NVIDIA, vượt quá 1 nghìn tỷ USD, gấp sáu lần so với nhà lãnh đạo sản xuất chip CPU, Intel, cho thấy nhu cầu lớn về GPU, vượt xa nhu cầu về CPU.',
    block4: {
      title: 'Trò Chơi',
      text: 'Trò chơi và các đồ họa hiện đại thường bao gồm đồ họa 3D phức tạp và mô phỏng vật lý. Các nhiệm vụ này đòi hỏi xử lý song song một cách mạnh mẽ, do đó sức mạnh xử lý đồ họa mạnh mẽ của GPU rất phù hợp. Sử dụng GPU có thể đạt được trải nghiệm chơi game mượt mà hơn và chất lượng hình ảnh cao hơn.'
    },
    block5: {
      title: 'Trí Tuệ Nhân Tạo và Học Máy',
      text: 'Trong lĩnh vực trí tuệ nhân tạo, đặc biệt là trong học sâu, cần xử lý các lượng dữ liệu lớn và thực hiện các phép tính toán toán học phức tạp. Các nhiệm vụ tính toán này thường có thể được phân cấp song song, do đó rất phù hợp với hiệu suất song song cao của GPU. Sử dụng GPU có thể cải thiện đáng kể tốc độ huấn luyện mô hình và suy luận.'
    }
  },
  historyDBC: {
    title: 'Lịch sử phát triển của DeepBrainChain',
    title1: 'Năm 2017',
    text1_1: 'Dự án DeepBrainChain được khởi đầu, đặt mục tiêu, tầm nhìn và hướng kiến trúc công nghệ',
    text1_2: 'Hoàn thành gây quỹ',
    title2: 'Năm 2018',
    text2_1: 'Token DBC được niêm yết trên sàn giao dịch Huobi',
    text2_2: 'Mạng lưới sức mạnh tính toán DBC được triển khai, với mã nguồn mở trên GitHub',
    title3: 'Năm 2020',
    text3_1: 'Số lượng người dùng phát triển toàn cầu của DBChain vượt qua con số 10.000, phục vụ hơn 500 trường đại học và phòng thí nghiệm liên quan đến trí tuệ nhân tạo trên toàn thế giới',
    title4: 'Năm 2021',
    text4_1: 'Mạng lưới nút DeepBrainChain chính thức được triển khai',
    text4_2: 'Trình duyệt chính mạng lưới DeepBrainChain được triển khai',
    text4_3: 'Hơn 50 nền tảng đám mây dựa trên DeepBrainChain',
    text4_4: 'Mạng lưới sức mạnh tính toán GPU chính của DeepBrainChain bắt đầu kiểm tra công cộng',
    text4_5: 'Tổng lượng sức mạnh tính toán GPU trong mạng kiểm tra công cộng vượt qua con số 1200 thẻ, tỷ lệ thuê vượt qua 98%',
    text4_6: 'Mạng lưới sức mạnh tính toán GPU chính của DeepBrainChain chính thức được triển khai',
    title5: 'Năm 2022',
    text5_1: 'Số lượng GPU trong cuộc thi Galaxian đạt 1500',
    text5_2: 'Nền tảng đám mây haibaogpu dựa trên dbc được triển khai, cung cấp dịch vụ GPU cho người dùng AI',
    text5_3: 'Ba hồ bơi Hàn Quốc - DBC KOREA HILLSTONE, DBC KOREA Ai-Factory và Gines Korea Center-1 tham gia vào mạng lưới DBC',
    text5_4: 'Nền tảng đám mây hycons dựa trên dbc được triển khai tại Hàn Quốc, cung cấp dịch vụ GPU cho người dùng AI và trò chơi đám mây',
    text5_5: 'Nền tảng trò chơi đám mây DeepLink dựa trên dbc được triển khai, phục vụ như một nền tảng trò chơi đám mây với các công cụ điều khiển từ xa',
    title6: 'Năm 2023',
    text6_1: 'Huawei, DeepBrainChain và Polygon cùng thành lập Liên minh Vũ trụ và Web3',
    text6_2: 'Quán cyber cloud đầu tiên trên thế giới dựa trên công nghệ DBC và DeepLink bắt đầu hoạt động thử nghiệm tại Seoul, Hàn Quốc',
    text6_3: 'Máy chơi game đám mây đầu tiên trên thế giới dựa trên công nghệ DBC và DeepLink được giới thiệu tại Tokyo, Nhật Bản, với một buổi họp chung',
    title7: 'Năm 2024',
    text7_1: 'Q1',
    text7_2: '1. Phát triển chế độ cho thuê GPU ngắn hạn',
    text7_3: '2. Hỗ trợ các tính năng mới trên mạng chính',
    text7_4: 'Q2',
    text7_5: '1. Phát triển hỗ trợ chức năng hợp đồng thông minh',
    text7_6: '2. Hoàn thiện chế độ cho thuê GPU ngắn hạn',
    text7_7: '3. Hỗ trợ chuyển đổi trò chơi GameFi thành GameFi đám mây',
    text7_8: 'Q3',
    text7_9: '1. Hỗ trợ dự án AIGC phi tập trung phát triển hợp đồng thông minh dựa trên DBC',
    text7_10: '2. Hỗ trợ khai thác dự án AIGC phi tập trung dựa trên GPU DBC',
    text7_11: '3. Hoàn tất thử nghiệm chức năng hợp đồng thông minh',
    text7_12: 'Q4',
    text7_13: '1. Hỗ trợ khai thác 3A GameFi dựa trên GPU DBC',
    text7_14: '2. Phát triển chức năng DBCSwap, hỗ trợ giao dịch token trong hệ sinh thái DBC với DBC Token trên chuỗi',
  },
  introDBC: {
    title: 'Giới thiệu về quản lý DBC DAO',
    text1: 'Với việc triển khai mạng chính DBC vào năm 2021, hiện tại có hai tổ chức cùng đóng góp vào sự phát triển của DBC, đó là Quỹ DBC và Hội đồng DBC.',
    text2: 'Quỹ DBC là tổ chức chính của DBC, chịu trách nhiệm chính về phát triển kỹ thuật của DBC và quảng bá toàn cầu. Quỹ DeepBrainChain và Hội đồng DeepBrainChain là hai tổ chức hoàn toàn độc lập không có mối quan hệ phụ thuộc nào, cùng phục vụ hệ sinh thái DeepBrainChain và đóng góp vào sự phát triển của DeepBrainChain.',
    cont2: {
      block1: {
        title: 'Hội đồng DBC',
        text1: 'Tổ chức DAO được cộng đồng bầu chọn, chịu trách nhiệm chính về quản lý quỹ, hỗ trợ ứng dụng hệ sinh thái DBC, phát triển cộng đồng DBC và quản lý DBC.',
        text2: 'Hiện tại quản lý hơn 800 triệu token DBC trong quỹ DBC, với việc tiếp nhận hàng tháng 4,25 triệu DBC, kéo dài đến năm 2036.',
        text3: 'Hội đồng bao gồm 21 thành viên, được bầu cử mỗi 4 tháng. Mỗi tháng, 21 ứng cử viên hàng đầu sẽ được bầu.',
      },
      block2: {
        title: 'Trở thành thành viên Hội đồng',
        text1: 'Trở thành thành viên hội đồng cho phép bạn tham gia bỏ phiếu cho các đề xuất về quỹ. Để thông qua bất kỳ đề xuất nào, cần có sự chấp thuận của 13 trong số 21 thành viên hội đồng.',
        text2: 'Trở thành thành viên hội đồng cũng tăng khả năng được cộng đồng và các thành viên khác trong hội đồng tin tưởng hơn. Ngoài ra, khi tự mình đề xuất một đề xuất về quỹ, khả năng được thông qua cao hơn.',
        text3: 'Bất kỳ thành viên cộng đồng nào cũng có thể đề xuất về việc cấp quỹ, tuy nhiên để đưa đề xuất vào quá trình bỏ phiếu, cần có một trong 21 thành viên hội đồng đóng góp.',
        btn: 'Xem chi tiết'
      }
    },
    cont3: {
      title: 'Mục đích sử dụng quỹ của DBC',
      text1: 'Triển khai và duy trì cơ sở hạ tầng',
      text1_2: 'và hoạt động liên tục',
      text2: 'Vận hành an toàn mạng',
      text2_1: 'Dịch vụ giám sát, kiểm toán liên tục',
      text3: 'Hỗ trợ sinh thái',
      text3_1: 'và hợp tác với chuỗi thân thiện',
      text4: 'Hoạt động tiếp thị',
      text4_1: 'Quảng cáo, thanh toán, hợp tác',
      text5: 'Hoạt động cộng đồng và hoạt động mở rộng',
      text5_1: 'Cuộc họp, buổi tiệc pizza, không gian hacker',
      text6: 'Triển khai và duy trì cơ sở hạ tầng',
      text6_1: 'Tích hợp ví và ví, nâng cấp khách hàng và khách hàng',
      text7: 'Niêm yết trên sàn giao dịch',
      text8: 'Nhiều hơn...'
    },
    cont4: {
      title: 'Bản điều hành Top Ba Chốt trong Đảng viên Cao cấp này',
      job: 'Chủ Tịch',
      reward: 'Phiếu bầu:'
    },
    cont5: {
      title: 'Đếm ngược đến cuộc bầu cử Ban giám đốc mới',
      text1: 'Đề xuất',
      text2: 'Đang tiến hành',
      text3: 'Đã được duyệt',
      text3_1: 'Bị từ chối',
      text4: 'Số tiền đề xuất',
      text5: 'Người nộp đơn:',
      text6: 'Thời gian kết thúc',
      text7: 'Giờ',
      text8: 'Thời gian đã qua',
      text9: 'Đồng ý',
      text10: 'Phản đối',
      text11: 'Ngày'
    },
    seemore: 'Xem thêm'
  },
  aboutTeam: {
    title: 'Về đội nhóm',
    lead: 'HE YONG',
    job: 'Người sáng lập',
    intro : {
      text1: 'Người sáng lập DeepBrainChain và DeepLink - Hà Vĩnh, doanh nhân liên tục',
      text2: 'Kinh nghiệm phát triển trí tuệ nhân tạo trong 14 năm, blockchain và mạng lưới tính toán phân tán trong 7 năm. Tốt nghiệp đại học ngành công nghệ thông tin tại Đại học Hải Dương Trung Quốc vào năm 2008, năm 2010 tiến sĩ ngành Thông tin Sinh học tại Đại học Đông Phương Thường Châu, nhưng không tốt nghiệp.',
      text3: 'Năm 2012, thành lập công ty Jushang Intelligence, phát triển trợ lý giọng nói AI tiếng Trung đầu tiên trên thế giới - Trí tuệ 360. Trong vòng ba năm, số lượng người dùng tải xuống phần mềm vượt quá 17 triệu.',
      text4: 'Năm 2014, ra mắt loa thông minh AI đầu tiên trên thế giới - Loa siêu nhỏ XiaoZhi.',
      text5: 'Tháng 5 năm 2017, thành lập DeepBrain Chain, hiện phục vụ hàng trăm khách hàng về trí tuệ nhân tạo, trò chơi đám mây và quán trò chơi mạng.',
      text6: 'Năm 2020, thành lập DeepLink, DeepLink Protocol là giao thức trò chơi đám mây trí tuệ nhân tạo phi tập trung, hợp tác với DeepBrain Chain, phục vụ quán trò chơi mạng và các công ty sản xuất trò chơi 3A trên toàn thế giới.'
    },
    application: {
      title: 'Nộp đơn ứng tuyển',
      desc: 'Quỹ DeepBrainChain hiện có hơn 50 nhân viên toàn thời gian và bán thời gian trong lĩnh vực nghiên cứu và thị trường, với nhân sự phân bố tại các quốc gia như Singapore, Trung Quốc, Hoa Kỳ, Nga, Hàn Quốc, Đài Loan, Nhật Bản, Ấn Độ, Việt Nam, Bangladesh và Nigeria'
    },
    weekly: '16 số gần đây của Báo cáo hàng tuần DBC',
    see: 'Xem chi tiết',
    seemore: 'Xem thêm'
  },
  deployCloud: {
    title: 'API mạnh mẽ để thuê GPU',
    step1: {
    text1: 'Thuê GPU thông qua API trên chuỗi',
    text2: 'Địa chỉ Node API: xxxx, hoặc triển khai nút đồng bộ hóa API blockchain của riêng bạn. Tài liệu triển khai:',
    text3: 'Thuê máy trên chuỗi',
    text4: 'Xác nhận sẵn có và thuê',
    text5: 'Gia hạn',
    },
    step2: {
    text1: 'Thuê toàn bộ máy GPU',
    text2: 'Tạo máy ảo',
    text3: 'Khởi động lại máy ảo',
    text4: 'Xác nhận sẵn có và thuê',
    text5: 'Gia hạn',
    },
    step3: {
    text1: 'Thuê máy GPU Bare Metal',
    text2: 'Tạo máy',
    text3: 'Khởi động lại máy',
    text4: 'Tắt máy',
    text5: 'Lấy ID Thiết bị và Mã xác minh DeepLink',
    text6: 'Lấy tài khoản và mật khẩu Ubuntu',
    },
    server: 'Nếu có bất kỳ câu hỏi nào, vui lòng liên hệ:'
  },
  cloudInternet: {
    title: 'Làm thế nào để trở thành đối tác giải pháp quán trò chơi mạng trên đám mây',
    text: 'Hiện nay, các quốc gia có số lượng quán trò chơi mạng chính trên toàn thế giới bao gồm Hàn Quốc (6000 quán), Trung Quốc (40,000 quán), Brazil (10,000 quán), Indonesia (20,000 quán), Philippines (20,000 quán), Việt Nam (15,000 quán), Thổ Nhĩ Kỳ (5000 quán), cùng với Ấn Độ, Mexico, Trung Đông, Nam Mỹ, Châu Phi, châu Âu và Mỹ và nhiều quốc gia khác. Tổng số lượng quán trò chơi mạng trên toàn thế giới lên đến hơn 150,000 quán, nhưng ngành công nghiệp này đã tồn tại trong 20 năm và đã trở thành một ngành công nghiệp truyền thống. Chu kỳ thu hồi vốn đã tăng lên đến 3-4 năm, và đối mặt với nhiều rủi ro, như đại dịch COVID-19 đã khiến nhiều quán trò chơi mạng phải đóng cửa. Do đó, ngành công nghiệp quán trò chơi mạng cần một giải pháp mới toanh, có thể giảm thiểu thời gian thu hồi vốn, giảm thiểu rủi ro và nâng cao hiệu suất vận hành quán trò chơi mạng. Và từ đó, giải pháp quán trò chơi mạng trên đám mây đã ra đời.',
    cont2: {
      text1: 'là gì',
      text2: 'Giải pháp quán trò chơi mạng trên đám mây?',
      text3: 'Giải pháp quán trò chơi mạng trên đám mây là một loại hình dịch vụ quán trò chơi mới với công nghệ kết xuất siêu thấp độ trễ. Trong quán trò chơi mạng trên đám mây, trò chơi và ứng dụng không được cài đặt trực tiếp trên máy tính cục bộ mà được lưu trữ trên các máy chủ GPU trên đám mây. Người dùng truy cập và chơi trò chơi thông qua dịch vụ đám mây mà không cần phải sử dụng phần cứng cục bộ có hiệu suất cao.',
      block1: {
        text1: 'Giảm Yêu Cầu Phần Cứng Cục Bộ',
        text2: 'Do trò chơi và ứng dụng chạy trên các máy chủ GPU trên đám mây, máy tính cục bộ không cần phải có GPU hiệu suất cao hoặc dung lượng lưu trữ lớn. Điều này giảm thiểu chi phí đầu tư và bảo trì phần cứng của quán trò chơi.'
      },
      block2: {
        text1: 'Truy Cập Ngay Lập Tức Đến Các Trò Chơi Mới Nhất',
        text2: 'Người dùng có thể truy cập các trò chơi mới nhất ngay lập tức mà không cần phải chờ đợi để tải xuống và cài đặt. Tất cả các cập nhật và bảo trì trò chơi đều được thực hiện trên đám mây, mang lại trải nghiệm thuận tiện và hiệu quả cho người dùng.'
      },
      block3: {
        text1: 'Trải Nghiệm Chơi Game Hiệu Suất Cao',
        text2: 'Màn hình cục bộ chỉ cần được trang bị mini PC (khoảng 100 đô la), và quán trò chơi mạng trên đám mây vẫn có thể cung cấp trải nghiệm chơi game hiệu suất cao. Điều này bởi vì tính toán trò chơi được thực hiện trên các máy chủ GPU hiệu suất cao từ xa.'
      },
      block4: {
        text1: 'Tiết Kiệm Không Gian và Năng Lượng',
        text2: 'Máy tính cục bộ có cấu hình thấp chiếm ít không gian hơn và tiêu thụ ít năng lượng hơn, giúp giảm chi phí vận hành của quán trò chơi.'
      },
      block5: {
        text1: 'Quản Lý và Bảo Trì Dễ Dàng',
        text2: 'Các quản lý quán trò chơi có thể dễ dàng quản lý phần mềm và trò chơi vì tất cả nội dung được quản lý tập trung trên đám mây. Điều này giảm thiểu công việc bảo trì cục bộ và các vấn đề kỹ thuật tiềm ẩn.'
      }
    },
    cont3: {
      text1: 'Glowsteam Cloud Cybercafe ở Hàn Quốc',
      text2: 'Môi Trường Hoạt Động Thực Tế của Quán Trò Chơi Mạng Trên Đám Mây'
    },
    cont4: {
      text1: 'Giải Pháp Quán Trò Chơi Mạng Trên Đám Mây Có Thể Giúp Các Nhà Đầu Tư Quán Trò Chơi Mạng',
      text2: 'Tăng Lợi Nhuận Ròng lên 3 Lần và Rút Ngắn Thời Gian Hồi Vốn lên 40%',
      text3: 'Nhấn để Tải Dữ Liệu So Sánh'
    },
    cont5: {
      text1: 'Lợi nhuận của nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là như thế nào?',
      text2: 'Nếu lợi nhuận được tính là 12% của thu nhập mỗi máy trò chơi mạng trên đám mây, sau đó dịch vụ một tổng số 1000 quán trò chơi mạng với 100.000 máy, mỗi máy được sử dụng trung bình 10 giờ mỗi ngày, và với một chi phí trung bình là 0.8 đô la mỹ cho mỗi máy mỗi giờ, 12% tương đương khoảng 0.1 đô la.',
      text3: 'Lợi nhuận hàng năm của một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là',
      text4: '100.000 * 10 * 0.1 * 365 = 36.5 triệu đô la',
      text5: 'Làm thế nào để trở thành một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây',
      text6: 'Dựa trên công nghệ DeepBrainChain và DeepLink, HYCONS của Hàn Quốc đã phát triển một giải pháp quán trò chơi mạng trên đám mây hoàn chỉnh. DeepBrainChain cung cấp sức mạnh tính toán GPU, DeepLink cung cấp công nghệ kết xuất với độ trễ siêu thấp, và HYCONS cung cấp toàn bộ giải pháp. Tương tự, nếu chúng ta so sánh nó với một chiếc ô tô, DeepBrainChain cung cấp động cơ, DeepLink cung cấp hệ thống lái tự động, và HYCONS cung cấp giải pháp ô tô hoàn chỉnh. Các nhà cung cấp giải pháp địa phương tại các quốc gia khác nhau chỉ cần tích hợp hệ thống thanh toán địa phương của họ vào HYCONS để cung cấp dịch vụ cho các quán trò chơi mạng địa phương, và các nhà cung cấp giải pháp có thể thiết lập thương hiệu của riêng họ. HYCONS chỉ hoạt động duy nhất như một nhà cung cấp dịch vụ công nghệ mà không tiết lộ thương hiệu của mình.',
      text7: 'Để biết thêm thông tin, vui lòng liên hệ:',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'Tải về tài liệu liên quan đến quán trò chơi mạng trên đám mây',
      text1: 'Giới thiệu về HYCONS',
      text2: 'Phân tích lợi nhuận từ giải pháp quán trò chơi mạng trên đám mây',
      text3: 'Sơ đồ kiến trúc Trung tâm Dữ liệu Quán trò chơi mạng trên đám mây',
      text4: 'Hướng dẫn Kiểm tra và Cài đặt Giải pháp quán trò chơi mạng trên đám mây',
      text5: 'Giới thiệu giao thức DeepLink'
    }
  },
  gameAgents: {
    title: 'Làm thế nào để trở thành đại lý máy chơi game đám mây',
    text: 'Máy chơi game đám mây là gì? So với các máy chơi game truyền thống, máy chơi game đám mây có tốc độ làm mới khung hình cao (FPS), giúp mang lại trải nghiệm chơi game mượt mà hơn. Máy chơi game đám mây TIKEREN của Nhóm HKC được phát triển dựa trên công nghệ DeepBrainChain + DeepLink, cho phép kết nối thời gian thực với GPU tính toán hiệu suất cao từ xa để thực hiện việc hiển thị và tính toán trò chơi.',
    title1: 'Trình bày Máy TIKEREN',
    table: {
      th: {
        text1: 'Sắp xếp',
        text2: 'Mục',
        text3: '27 inches',
        text4: '31.5 inches'
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27 inches',
        text3: '31.5 inches'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: 'Lên, Xuống, Trái, Phải, Xoay 90°, Điều chỉnh độ cao',
        text3: 'Lên, Xuống, Trái, Phải, Xoay 90°, Điều chỉnh độ cao'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: 'Vui lòng liên hệ qua email'
  },
  convertGame: {
    title: 'Làm thế nào để chuyển đổi Trò chơi AAA thành Trò chơi Đám mây?',
    text: 'DeepLink Protocol là một giao thức vẽ trò chơi đám mây siêu thấp trễ phi tuyến tính, bất kỳ ứng dụng nào cũng có thể phát triển các sản phẩm liên quan đến trò chơi đám mây dựa trên DeepLink Protocol, nơi sức mạnh tính toán đám mây được cung cấp bởi DeepBrainChain.',
    text1: 'Hiểu về DeepLink Protocol',
    step: {
      title1: 'Tích hợp Trò chơi với DeepLink Protocol',
      text1: "Liên hệ với service{'@'}deeplink.cloud",
      title2: 'DeepLink Tự động Gọi máy chủ GPU DeepBrainChain',
      text2: 'Triển khai trò chơi để chạy trong đám mây'
    }
  },
  applyForFunding: {
    title: 'Làm thế nào để đề xuất Quỹ từ Hội đồng quản trị',
    text1: 'Trước khi đề xuất vốn, bạn có thể tham gia nhóm thảo luận Telegram về Đề xuất Quỹ của Hội đồng.',
    text2: 'Nhóm Thảo luận Telegram',
    cont: {
      title: 'Quy trình đề xuất Quỹ DBC',
      text: 'Khi một ứng viên yêu cầu vốn từ quỹ, họ cần dành 5% số tiền đề xuất. Nếu đề xuất của quỹ được chấp nhận, số tiền dành dự trữ sẽ được hoàn trả; nếu đề xuất của quỹ bị từ chối, số tiền dành dự trữ sẽ bị phạt. Lưu ý rằng người dùng không thể rút lại đề xuất của mình sau khi gửi đề xuất tài chính.',
    },
    procedure: {
      text: 'Quy trình đề xuất tài chính từ Quỹ Khoản DBC:',
      title1: 'Chuẩn bị bản nháp',
      text1: 'Chuẩn bị bản nháp dựa trên mẫu đề xuất, xem Mẫu đề xuất',
      text2: 'Tiếng Anh:',
      text3: 'Tiếng Trung:',
      title2: 'Thảo luận đề xuất ngoại mạng',
      text4: 'Trước khi nộp đề xuất vào chuỗi, người đề xuất có thể thảo luận về đề xuất với Hội đồng và cộng đồng ở bất kỳ nơi nào ngoài chuỗi, như WeChat, nhóm Telegram, vv và tích hợp các ý kiến khác nhau, liên tục hoàn thiện đề xuất. Hiện tại, DBC đã tạo cộng đồng DBC_Council (https://www.reddit.com/r/DBC_Council/) trên Reddit. Mainland China yêu cầu VPN. Cộng đồng cũng thiết lập một phần để thảo luận về đề xuất [proposal_draft discussion]. Người đề xuất cũng có thể đăng bản nháp trong cộng đồng này và thêm flair [proposal_draft discussion] để thảo luận.',
      title3: 'Nộp vào chuỗi',
      text3_1: 'Trên trang Quản lý-quỹ chính của DBC trên mạng lưới chính (https://www.dbcwallet.io/#/treasury), nộp đề xuất vào chuỗi, nhập số lượng DBC yêu cầu, sau đó hệ thống sẽ tự động cầm cố 5% DBC làm tiền đặt cược.',
      text3_2: 'Chú ý! Nếu đề xuất được chấp nhận, tiền đặt cọc sẽ được hoàn lại; nếu đề xuất bị từ chối, tiền đặt cọc sẽ không được hoàn lại!',
      text3_3: 'Do đó, hãy nghiêm túc trong việc chuẩn bị đề xuất, đề xuất tương đối đầy đủ và thực hiện được.',
      text3_4: 'Khi nộp vào chuỗi, người đề xuất cũng phải đăng nội dung đề xuất vào cộng đồng Reddit DBC_Council (https://www.reddit.com/r/DBC_Council/) và thêm flair [proposal_submitted onchain]. Sau khi nộp vào chuỗi, bạn sẽ nhận được số đề xuất và chiều cao khối để dễ dàng tương ứng giữa dữ liệu ngoại chuỗi và trong chuỗi.',
      title4: 'Đề xuất của Hội đồng và Bỏ phiếu',
      text4_1: 'Khi hoàn tất đề xuất, người đề xuất có thể xem nó trên https://www.dbcwallet.io/#/treasury.',
      text4_2: 'Lúc này, Hội đồng có thể đề xuất về đề xuất, chấp nhận hoặc từ chối nó. Để chấp nhận đề xuất, cần có sự đồng thuận của 3/5 Hội đồng; để từ chối đề xuất, cần có sự đồng thuận của 1/2 Hội đồng.',
      text4_3: 'Lưu ý! Hội đồng phải đề xuất đề xuất vào quỹ (https://www.dbcwallet.io/#/treasury) để sau đó bỏ phiếu trong cuộc họp (https://www.dbcwallet.io/#/council/motions).',
      title5: 'Hoàn thành Bỏ phiếu',
      text5_1: 'Sau khi kỳ bỏ phiếu kết thúc, Hội đồng phải đóng cửa đề xuất thủ công (https://www.dbcwallet.io/#/council/motions).',
      text5_2: 'Sau khi bỏ phiếu, đề xuất được chấp nhận sẽ được các quản trị viên cộng đồng DBC subreddit đưa vào kho lưu trữ với cờ [proposal_approved], và đề xuất không được chấp nhận sẽ được đưa vào cờ [proposal_rejected].',
      title6: 'Phân phối tiền',
      text6_1: 'Tiền sẽ được phân phối theo từng phần và người đề xuất thành công sẽ phải tiếp tục cập nhật tiến độ của mình. Vui lòng chỉ ra số tiền cần cho mỗi giai đoạn trong đề xuất của bạn. Tiến độ thực hiện đề xuất sẽ được giám sát bởi các thành viên cộng đồng (không phải là thành viên của Hội đồng) bằng cách bỏ phiếu giám sát.',
      text6_2: 'Lưu ý đặc biệt: Địa chỉ nhận tiền không thể là địa chỉ sàn giao dịch, nó phải là địa chỉ bạn kiểm soát cá nhân. Tốt nhất là sử dụng địa chỉ được tạo ra trên mạng lưới chính DBC, nếu không sau khi được chấp nhận bạn sẽ không thể nhận được tiền.',
      text6_3: 'Lưu ý: Việc phân phối tiền đã được chấp nhận sẽ được thực hiện sau khi kỳ chi tiêu kết thúc. Bạn có thể kiểm tra kỳ chi tiêu trên trang quản lý (https://www.dbcwallet.io/#/treasury) (đếm ngược thời gian kỳ chi tiêu được hiển thị ở góc trên bên phải).'
    }  
  },
  createwallet: {
    title: 'Ví DBCWallet',
    text: 'Ví DBCWallet là một ví đa chức năng được DBC Foundation duy trì, có thể sử dụng ví này để tạo địa chỉ ví cho DBC và DLC, tham gia bỏ phiếu POS, tham gia bỏ phiếu bầu cử Hội đồng, tham gia bỏ phiếu dân sự, v.v.',
    text1: 'Truy cập trang web',
    cont2: {
      title: 'Cách tạo địa chỉ ví',
      text1: 'Để bảo vệ an toàn cho người dùng, việc tạo ví được thực hiện dưới dạng tiện ích mở rộng',
      step: {
        title1: 'Cài đặt trình duyệt Google Chrome',
        title2: 'Tìm kiếm tiện ích Polkadot',
        text2: 'Cài đặt tiện ích',
        title3: 'Lưu giữ hạt giống',
        text3: 'Mở tiện ích Polkadot, nhấp vào tạo ví, chọn hỗ trợ tất cả các chuỗi, lưu giữ hạt giống',
        title4: 'Làm mới trang www.dbcwallet.io',
        text4: 'Trang tài khoản của tôi sẽ hiển thị ví đã tạo'
      }
    },
    title1: 'Video hướng dẫn tạo ví'
  },
  polkaWallet: {
    title: 'Ví PolkaWallet',
    text: 'Tìm kiếm PolkaWallet trên Google Play và App Store, hoặc truy cập trang web chính thức: PolkaWallet.io để tải xuống.',
    text1: 'Tải từ PolkaWallet.io',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: 'Quy trình tạo',
      text1: '1. Mở polkawallet.io trên trình duyệt của bạn.',
      text2: '2. Truy cập vào trang web chính thức của Polkadot để tải ứng dụng ví.',
      text3: '3. Sau khi tải xuống, chọn "Tạo mới" hoặc "Nhập" cho ví mạng chính DBC.',
      text4: '4. Chọn tài khoản Substrate.',
      text5: '5. Đối với ví mới, hãy nhớ sao lưu khóa mật khẩu một cách đúng đắn.',
      text6: '6. Đặt tên và mật khẩu, sau đó bạn có thể nhập vào giao diện chính.',
      text7: '7. Vuốt sang trái trên giao diện chính để chọn DBC.',
      text8: '8. Nhấp vào biểu tượng để gửi và nhận DBC mạng chính.',
      text9: '9. Giao diện nhận.',
      text10: '10. Giao diện gửi và chuyển khoản. Gas được sử dụng cho ví mạng chính là DBC, vì vậy, vui lòng dành một lượng nhỏ DBC khi chuyển khoản.'
    }
  },
  mathWallet: {
    title: 'Ví MathWallet',
    text: 'Tìm kiếm MathWallet trên Google Play và App Store, hoặc truy cập trang web chính thức: https://mathwallet.org/ để tải xuống.',
    text1: 'Tải MathWallet',
    step: {
      title: 'Quy trình tạo',
      text1: '1. Truy cập mathwallet.org trên trình duyệt.',
      text2: '2. Sau khi vào trang chính, chọn đường dẫn tải xuống.',
      text3: '3. Trên Android, bạn có thể tải trực tiếp tệp cài đặt hoặc chuyển đến Cửa hàng Google để tải xuống.',
      text4: '4. Sau khi tải xuống xong, mở ứng dụng ví và chọn mạng chính DBC thủ công.',
      text5: '5. Ví mạng chính DBC sẽ nằm ở trang thứ tư từ cuối cùng.',
      text6: '6. Bạn có thể tạo mới hoặc nhập từ khóa.',
      text7: '7. Mật khẩu tạo cần chứa cả chữ hoa và chữ thường.',
      text8: '8. Hãy sao lưu từ khóa trợ giúp.',
      text9: '9. Sau khi thấy biểu tượng DBC xuất hiện, nhấp vào để nhận và gửi tài sản.',
      text10: '10. Giao diện gửi.',
      text11: '11. Vuốt sang trái để tạo thêm ví.',
      text12: '12. Phần cài đặt ở góc dưới bên phải cho phép bạn chọn ngôn ngữ.',
      text13: '13. Ở phía dưới trung tâm của giao diện chính, bạn có thể thấy trình duyệt mạng chính.'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: 'Tìm kiếm địa chỉ hợp đồng DBC:',
    text5: 'Độ chính xác:',
  },
  ImToken: {
    title: 'Ví ImToken',
    text: 'Truy cập trang web chính thức: token.im để tải xuống',
    text1: 'Tải token.im',
    step: {
      title: 'Quy trình tạo',
      text1: '1. Nhập token.im vào trình duyệt của bạn',
      text2: '2. Sau khi nhập, nhấp vào "Tải ngay"',
      text3: '3. Chọn cài đặt trực tiếp hoặc từ Cửa hàng Google Play',
      text4: '4. Sau khi tải xuống, tạo ví trong ứng dụng',
      text5: '5. Sau khi tạo, khuyến nghị sao lưu ngay lập tức và bảo quản an toàn khóa của bạn',
      text6: '6. Trên trang chính của ví, nhấp vào biểu tượng "+" để thêm mã thông báo',
      text7: '7. Trong thanh tìm kiếm, nhập địa chỉ hợp đồng DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. Sau khi thêm thành công, quay lại trang chính để xem biểu tượng DBC',
      text9: '9. Nhấp vào biểu tượng để xem địa chỉ nhận và gửi DBC',
      text10: '10. Khi gửi cho người khác, hãy đảm bảo sử dụng địa chỉ ERC20 của họ',
    }
  },
  trustWallet: {
    title: 'Ví TrustWallet',
    text: 'Tìm kiếm TrustWallet trên Google Play và App Store, hoặc truy cập trang web chính thức: https://trustwallet.com/ để tải xuống',
    text1: 'Tải TrustWallet',
    step: {
      title: 'Quy trình tạo',
      text1: '1. Nhập trustwallet.com vào trình duyệt của bạn',
      text2: '2. Sau khi nhập, có ba tùy chọn tải xuống',
      text3: '3. Sau khi tải xuống, tạo một ví mới',
      text4: '4. Vui lòng sao lưu ngay lập tức ví mới',
      text5: '5. Sau khi sao lưu hoàn tất, nhập vào giao diện chính',
      text6: '6. Nhấp vào biểu tượng "+" nhỏ được chồng lên nhau ở góc trên bên phải của giao diện chính, sau đó nhấp vào biểu tượng "+" ở góc trên bên phải của trang hiện tại',
      text7: '7. Trên màn hình nhập mã thông báo, dán địa chỉ hợp đồng DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. Sau khi nhập thành công, biểu tượng DBC sẽ xuất hiện',
      text9: '9. Nhấp vào "Nhận" để lấy địa chỉ ví DBC hiện tại',
      text10: '10. Nhấp vào "Gửi" để nhập vào giao diện gửi, và đảm bảo rằng có đủ ETH dành cho gas trên chuỗi ERC20 để hoàn thành giao dịch',
    }
  },
  metaMask: {
    title: 'Ví MetaMask',
    text: 'Tìm kiếm MetaMask trên Google Play hoặc truy cập trang web chính thức: https://metamask.io/ để tải xuống',
    text1: 'Tải về MetaMask',
    step: {
      title: 'Quy trình tạo',
      text1: '1. Mở metamask.io trong trình duyệt của bạn',
      text2: '2. Cài đặt từ Google Play Store',
      text3: '3. Sau khi tải xuống, nhập hoặc tạo một ví để truy cập vào giao diện chính',
      text4: '4. Nhấp vào "Nhập mã thông báo" ở dưới cùng của giao diện để chuyển đến trang thêm mã thông báo',
      text5: '5. Chọn "Mã thông báo Tùy chỉnh" và nhập địa chỉ hợp đồng DBC: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6. Sau khi nhập thành công, bạn có thể thấy biểu tượng DBC trên giao diện chính',
      text7: '7. Nhấp để nhập và bạn sẽ thấy các tùy chọn để nhận và gửi DBC',
      text8: '8. Để gửi, cần có ETH trong ví để thanh toán phí gas'
    }
  },

  whatisAi: {
    title1: 'Sản phẩm AI phi tập trung là gì?',
    text1: 'Sản phẩm AI phi tập trung là sản phẩm mà tất cả các khâu đều được thực hiện theo cách phi tập trung. Sản phẩm bao gồm hai phần chính: thanh toán và vận hành mô hình. Khi cả hai phần này đều đạt được sự phi tập trung hoàn toàn, đó chính là sản phẩm AI phi tập trung.',
    title2: 'Sản phẩm AI tập trung là gì?',
    text2: 'Sản phẩm AI tập trung là sản phẩm mà tất cả các khâu đều do một tổ chức tập trung quản lý. Ví dụ, thanh toán được thực hiện trên các máy chủ của tổ chức tập trung và mô hình AI được chạy trên các máy chủ GPU do tổ chức tập trung quản lý. Nói tóm lại, tổ chức tập trung có thể áp đặt mọi hạn chế và sửa đổi.',
    title3: 'Sản phẩm AI bán phi tập trung là gì?',
    text3: 'Sản phẩm AI bán phi tập trung là sản phẩm mà một phần được quản lý bởi tổ chức tập trung, phần còn lại là phi tập trung. Ví dụ, thanh toán được thực hiện thông qua hợp đồng thông minh sử dụng token, nhưng mô hình AI được chạy trên các máy chủ GPU do tổ chức tập trung quản lý.',
    title4: 'Sự khác biệt giữa dự án AI phi tập trung và dự án AI bán phi tập trung là gì?',
    text4_1: 'Sự khác biệt như sau:',
    text4_2: 'Dự án AI phi tập trung không phụ thuộc vào bất kỳ thực thể tập trung nào, và token của nó là token tiện ích không chịu sự điều chỉnh của luật chứng khoán. Trong khi đó, các dự án AI bán phi tập trung phát hành token chứng khoán, chịu sự quản lý của luật chứng khoán ở các quốc gia khác nhau, tạo ra nhiều rủi ro tiềm ẩn.',
    text4_3: 'Dự án AI phi tập trung có chu kỳ hoạt động dài hơn và có thể được cộng đồng tiếp quản nếu đội ngũ gặp vấn đề. Điều này là do mô hình chạy trên tài nguyên GPU do các thợ đào cung cấp, thay vì trên các máy chủ tập trung của đội ngũ.',
    title5: 'Những lợi thế của sản phẩm AI phi tập trung là gì?',
    text5_1: '1. Đối với đội ngũ dự án, GPU được sử dụng miễn phí.',
    text5_2: '2. Có thể huy động vốn thông qua việc bán các nút khai thác GPU.',
    text5_3: '3. Token là token tiện ích, không chịu sự điều chỉnh của luật chứng khoán.',
    text5_4: '4. Có thể khuyến khích người dùng thông qua token.',
    text5_5: '5. Mô hình hoạt động phi tập trung, minh bạch và không chịu sự kiểm soát, người dùng không lo lắng về việc bị hạn chế sử dụng.',
    text5_6: '6. Người dùng có thể tham gia vào quản trị dự án, ngay cả khi đội ngũ giải tán, cộng đồng vẫn có thể tiếp quản, đảm bảo mô hình tiếp tục hoạt động mà không bị gián đoạn.',
  },
  developAi: {
    title: 'Làm thế nào để phát triển sản phẩm AI phi tập trung?',
    title1: '1. Làm thế nào để phát triển sản phẩm AI phi tập trung?',
    text1: 'Sản phẩm AI phi tập trung bao gồm hai phần chính: thanh toán và vận hành mô hình. Phần thanh toán được thực hiện bằng cách triển khai các hợp đồng thanh toán. Vận hành mô hình được chia thành hai phần: hợp đồng thưởng khai thác và container AI. Hợp đồng thưởng khai thác xác định tỷ lệ token được phân bổ cho thợ mỏ GPU để khuyến khích thêm nhiều thợ mỏ triển khai mô hình của dự án và kiếm được token. Container AI yêu cầu đội ngũ dự án đóng gói mô hình AI vào một container, và thợ mỏ sẽ triển khai container AI dựa trên tài liệu hướng dẫn.',
    title2: '2. Hợp đồng thanh toán bao gồm những nội dung gì?',
    text2_1: '1). Chiến lược giá của sản phẩm, ví dụ như tính phí theo số lần yêu cầu hoặc theo gói hàng tháng.',
    text2_2: '2). Giá của sản phẩm.',
    text2_3: '3). Quyền sử dụng sản phẩm của một địa chỉ ví cụ thể.',
    title3: '3. Hợp đồng thưởng khai thác bao gồm những nội dung gì?',
    text3_1: '1). Tổng số lượng token thưởng.',
    text3_2: '2). Cơ chế khai thác, ví dụ như số lượng token có thể khai thác mỗi năm.',
    text3_3: '3). Các yếu tố ảnh hưởng đến số lượng token khai thác, như hiệu suất GPU, số lượng token đã đặt cọc, số lượng node NFT, thời gian khai thác, v.v.',
    text3_4: '4). Quy tắc mở khóa token khai thác được.',
    title4: '4. Container mô hình AI bao gồm những nội dung gì?',
    text4_1: '1). Môi trường chạy của container.',
    text4_2: '2). Mô hình AI và các script chạy.',
    text4_3: '3). Script đăng ký node tính toán GPU DBC.',
    text4_4: '4). Chương trình giám sát sức khỏe DBC.',
    text4_5: '5). Các chương trình tương tác với hợp đồng khai thác và hợp đồng thanh toán.',
  },
  deployToken: {
    title: 'Làm thế nào để triển khai hợp đồng thông minh Token?',
    text1: '1. DBC tương thích với ETH EVM, tiêu chuẩn Token DRC20 hoàn toàn tương thích với ERC20. Bạn có thể viết hợp đồng thông minh bằng ngôn ngữ Solidity.',
    text2_1: '2. Trình duyệt mạng chính DBC:',
    text2_2: ' Thông qua DBCScan, bạn có thể tra cứu hợp đồng đã triển khai, địa chỉ ví và giao dịch.',
    text3: '3. Quy trình triển khai hợp đồng cụ thể:',
    text3_1: '1). Điền các cấu hình mạng chính dbc này vào hardhat.config.ts. Sau đó, bạn có thể viết mã và kiểm tra hợp đồng.',
    text3_2: 'rpc_url: "https://rpc.dbcwallet.io"',
    text3_3: 'api_url: "https://www.dbcscan.io/api"',
    text3_4: 'browser_url: "https://www.dbcscan.io"',
    text3_5: 'network: "DBC Mainnet"',
    text3_6: 'chain_id: 19880818',
    text3_7: '2). Bạn có thể sử dụng Makefile để biên dịch, triển khai, nâng cấp, xác minh hoặc kiểm tra hợp đồng.',
    text3_8: '3). Các liên kết này có thể hữu ích cho việc phát triển hợp đồng ERC20:',
    text4: '4. Ví dụ tham khảo về hợp đồng Token:',
  },
  paymentRelated: {
    title: 'Làm thế nào để phát triển hợp đồng thông minh liên quan đến thanh toán?',
    text1: 'Xác định giá cho các mô hình AI khác nhau. Ví dụ, dự án hiện tại có ba mô hình AI với hai chế độ: đăng ký và miễn phí. Ở chế độ miễn phí, mỗi mô hình có thể được gọi 10 lần mỗi ngày, trong khi ở chế độ đăng ký, mỗi mô hình có thể được gọi 10.000 lần mỗi ngày. Giá của từng mô hình được quy định trong hợp đồng.',
    text2: 'Khi người dùng của dự án gửi yêu cầu thông qua mạng phân tán DBC AI đến container AI của dự án, container phải có một chương trình gatekeeper để tương tác với hợp đồng thông minh thanh toán, xác định xem mô hình AI hiện tại có nên cung cấp dịch vụ tính toán cho người dùng hay không. Nếu không có chương trình gatekeeper này, container AI sẽ trả về kết quả tính toán cho bất kỳ yêu cầu nào của người dùng, và người dùng sẽ không phải trả bất kỳ khoản phí nào cho hợp đồng thông minh.',
    text3: 'Chương trình gatekeeper của nhóm dự án',
    text4: 'Container mô hình AI của nhóm dự án',
    text5: 'Hợp đồng thông minh thanh toán',
    text6: 'Chương trình gatekeeper tương tác với hợp đồng thông minh để xác định xem mô hình AI hiện tại có nên cung cấp dịch vụ tính toán cho người dùng hay không.',
  },
  gpuMining: {
    title: 'Làm thế nào để phát triển hợp đồng thông minh liên quan đến khai thác GPU?',
    text1: 'Cơ chế thưởng cho khai thác GPU rất quan trọng, vì nó quyết định các quy tắc mà người khai thác GPU có thể nhận được phần thưởng. Các quy tắc thưởng càng hấp dẫn, càng có nhiều thợ mỏ tham gia cung cấp GPU. Nên phân bổ 20%-50% số token làm phần thưởng khai thác. Có thể thiết lập cơ chế giảm một nửa, ví dụ giảm một nửa sau 2 hoặc 4 năm. Bạn có thể tham khảo:',
    text2: 'Khi thợ mỏ triển khai container AI trên máy GPU, container cần có chương trình staking. Chương trình staking này sẽ giao tiếp với Node Worker AI của DBC để đăng ký hoặc hủy đăng ký. Đăng ký có nghĩa là máy đã bắt đầu khai thác cho dự án AI hiện tại. Chương trình staking cũng giao tiếp với hợp đồng thông minh liên quan đến khai thác GPU, gửi thông tin đăng ký thành công cho hợp đồng thông minh, và từ đó bắt đầu tính toán phần thưởng khai thác.',
    img_text1: 'Đăng ký / Hủy đăng ký',
    img_text2: 'Mô hình AI',
    img_text3: 'Chương trình Staking của nhóm dự án',
    img_text4: 'Container mô hình AI của nhóm dự án',
    img_text5: 'Tự động gửi thông tin đăng ký lên chuỗi DBC',
    img_text6: 'Gửi thông tin đăng ký thành công đến hợp đồng thông minh',
    img_text7: 'Hợp đồng khai thác GPU cần xác minh việc đăng ký trên chuỗi DBC',
    img_text8: 'Hợp đồng thông minh khai thác GPU',
    text3_1: 'Có hai chế độ để máy GPU tham gia vào mạng DBC:',
    text3_2: '1. Chế độ thuê dài hạn  2. Chế độ tự do',
    text3_3: 'Hợp đồng khai thác GPU cần lấy thông tin từ chuỗi DBC để xác định chế độ tham gia của máy GPU, và các chế độ khác nhau sẽ được xử lý khác nhau.',
    text4: 'Xử lý chế độ thuê dài hạn:',
    text4_1: '1) Hợp đồng khai thác GPU cần xác định xem máy GPU có đang được thuê hay không.',
    text4_2: '2) Địa chỉ ví staking cần được chứng minh là thuộc cùng một chủ sở hữu với địa chỉ ví thuê GPU trên mạng DBC.',
    text4_3: '3) Chỉ khi các điều kiện trên được đáp ứng thì thợ mỏ mới nhận được phần thưởng token.',
    text5: 'Xử lý chế độ tự do:',
    text5_1: '1) Hợp đồng khai thác GPU cần xác định xem máy GPU đã tham gia thành công vào mạng DBC hay chưa.',
    text5_2: '2) Chỉ khi các điều kiện trên được đáp ứng thì thợ mỏ mới nhận được phần thưởng token.',
  },
  developAIModel: {
    title: 'Làm thế nào để phát triển container mô hình AI?',
    title1: '1. Container mô hình AI được phát triển bởi nhóm dự án AI và được triển khai bởi các thợ mỏ GPU.',
    title2: '2. Container mô hình AI bao gồm những gì?',
    text2_1: '1) Môi trường runtime của container: Đầu tiên, khởi động một container sạch. ',
    text2_1_1: '(Hướng dẫn tham khảo để tạo hình ảnh container)',
    text2_2: '2) Mô hình AI và các script chạy: Vì các mô hình thường khá lớn, không nên tải trước mô hình vào container. Thay vào đó, sau khi khởi động container, nó có thể tự động tải xuống từ một nguồn từ xa, ví dụ: Huggingface.',
    text2_3: '3) Script đăng ký node tính toán GPU của DBC: Script này xử lý việc đăng ký và hủy đăng ký. Bạn cũng có thể thiết lập để tự động đăng ký khi container khởi động và tự động hủy đăng ký khi container dừng.',
    text2_3_1: '(Hướng dẫn sử dụng nút Worker)',
    text2_4: '4) Chương trình giám sát sức khỏe của DBC. ',
    text2_5: '5) Chương trình tương tác với hợp đồng khai thác và hợp đồng thanh toán: Chủ yếu là chương trình staking và chương trình gatekeeper, cũng có thể kết hợp cả hai chương trình thành một.',
    block_text1: 'Mô hình AI',
    block_text2: 'Chương trình staking của nhóm dự án',
    block_text3: 'Chương trình giám sát sức khỏe DBC',
    block_text4: 'Chương trình gatekeeper của nhóm dự án',
    block_text5: 'Script đăng ký node tính toán GPU của DBC',
    block_text6: 'Container mô hình AI',
    title3: '3. Tạo image container mô hình AI: Quy trình tạo image',
  },
  fullProcessTesting: {
    title: 'Làm thế nào để thực hiện kiểm tra toàn bộ quy trình?',
    text1: '1. Hoàn thành việc phát triển hợp đồng thông minh liên quan đến thanh toán.',
    text2: '2. Hoàn thành việc phát triển hợp đồng thông minh liên quan đến khai thác GPU.',
    text3: '3. Hoàn thành việc tạo image container AI.',
    text4: '4. Chuẩn bị một máy GPU có khả năng chạy mô hình AI, sau đó thêm máy vào mạng DBC bằng chế độ thuê tự do, chạy DBC AI Worker Node trên máy GPU, sau đó khởi động và đăng ký container AI.',
    text5: '5. Thiết lập một máy CPU có địa chỉ IP và triển khai DBC AI Input Node trên máy CPU. ',
    text6: '6. Kiểm tra xem DBC AI Input Node có phát hiện đúng container AI hay không.',
    text7: '7. Gửi yêu cầu của người dùng đến DBC AI Input Node và kiểm tra xem kết quả phản hồi có được trả về chính xác hay không.',
  },
  primaryProcessTesting: {
    title: 'Làm thế nào để thực hiện kiểm tra quy trình cơ bản?',
    text: 'Kiểm tra quy trình cơ bản chỉ nhằm kiểm tra xem container AI có thể xử lý yêu cầu người dùng thông qua mạng phân tán và trả về kết quả tính toán của mô hình AI thông qua mạng phân tán hay không.',
    text1: '1. Hoàn thành việc tạo image container AI.',
    text2: '2. Chuẩn bị một máy GPU có khả năng chạy mô hình AI, sau đó chạy DBC AI Worker Node trên máy GPU (sử dụng mạng thử nghiệm). Sau đó, cài đặt môi trường container, khởi động container AI và đăng ký container AI.',
    text3: '3. Thiết lập một máy CPU có địa chỉ IP và triển khai DBC AI Input Node trên máy CPU. ',
    text4: '4. Kiểm tra xem DBC AI Input Node có phát hiện đúng container AI hay không.',
    text5: '5. Gửi yêu cầu của người dùng đến DBC AI Input Node và kiểm tra xem kết quả phản hồi có được trả về chính xác hay không.',
  },
  participateDBCMining: {
    title: 'Làm thế nào để tham gia khai thác token DBC?',
    text: 'Chỉ tham gia khai thác DBC, tổng thu nhập nhận được bao gồm: Phần thưởng khai thác DBC + Thu nhập từ thuê DBC.',
    text1: 'Chuẩn bị một máy GPU đáp ứng yêu cầu của DBC. Chi tiết cụ thể có thể tham khảo tại:',
    text2: 'Nếu chỉ tham gia khai thác token DBC, bạn sẽ nhận được phần thưởng khai thác DBC và thu nhập từ thuê DBC (chỉ có thu nhập từ thuê khi tổng số GPU toàn mạng dưới 10.000).',
    text3_1: 'Để kiểm tra thu nhập từ khai thác, tham khảo:',
    text3_2: 'Công thức tính thu nhập hàng tháng của máy',
  },
  participateDBCDLCMining: {
    title: 'Làm thế nào để tham gia khai thác kép DBC và DeepLink?',
    text: 'Tham gia khai thác kép DBC và DeepLink, tổng thu nhập nhận được bao gồm: Phần thưởng khai thác DBC - Phí thuê DBC bị hủy + Phần thưởng khai thác DeepLink.',
    text1: 'Chuẩn bị một máy GPU đáp ứng yêu cầu của DeepLink. Chi tiết cụ thể có thể tham khảo tại: ',
    text2: 'Thêm máy GPU vào mạng DBC bằng chế độ thuê dài hạn, sau đó tự thuê lại máy (máy đang thuê trên DBC sẽ nhận thêm 30% phần thưởng). Sau đó, tham gia vào mạng DeepLink.',
    text3_1: 'Để kiểm tra thu nhập từ khai thác, tham khảo:',
    text3_2: 'Công thức tính thu nhập hàng tháng của máy.',
  },
  participateDBCAiMining: {
    title: 'Làm thế nào để tham gia khai thác nhiều token DBC và AI?',
    text: 'Tham gia khai thác nhiều token DBC và AI, tổng thu nhập nhận được bao gồm: Phần thưởng khai thác DBC - Phí thuê DBC bị hủy + Phần thưởng token AI.',
    text1: 'Chuẩn bị một máy GPU đáp ứng yêu cầu của dự án AI. ',
    text2: 'Thêm máy GPU vào mạng DBC bằng chế độ thuê dài hạn, sau đó tự thuê lại máy (máy đang thuê trên DBC sẽ nhận thêm 30% phần thưởng). Sau đó, tham gia vào hợp đồng khai thác của dự án AI.',
    text3_1: 'Để kiểm tra thu nhập từ khai thác, tham khảo trang web chính thức của mỗi dự án AI:',
    text3_2: 'Công thức tính thu nhập hàng tháng của máy.',
  },
  participateAiMining: {
    title: 'Làm thế nào để chỉ tham gia khai thác token AI?',
    text: 'Chỉ tham gia khai thác token AI, tổng thu nhập nhận được bao gồm: Phần thưởng token AI.',
    text1: 'Chuẩn bị một máy GPU đáp ứng yêu cầu của dự án AI. Chi tiết cụ thể có thể tham khảo tại:',
    text2: 'Thêm máy GPU vào mạng DBC bằng chế độ tự do, sau đó tham gia vào hợp đồng khai thác của dự án AI. ',
    text3_1: 'Để kiểm tra thu nhập từ khai thác, tham khảo trang web chính thức của mỗi dự án AI:',
    text3_2: 'Công thức tính thu nhập hàng tháng của máy.',
  },
  participateGPUMining: {
    title: 'Làm thế nào để tham gia khai thác nhiều token DBC và GPU khác?',
    text1: 'Chuẩn bị một máy GPU đáp ứng yêu cầu của DBC. Chi tiết cụ thể có thể tham khảo tại:',
    text2: 'Thêm máy GPU vào mạng DBC bằng chế độ thuê dài hạn, sau đó khởi động một máy ảo. Trong máy ảo, bắt đầu khai thác các dự án GPU như Aleo, IONET, Aethir, v.v. Tuy nhiên, lưu ý rằng trong chế độ này, máy ảo có thể bị tắt bất cứ lúc nào nếu có người dùng thuê máy DBC, vì vậy chế độ này không phù hợp với các dự án khai thác có cơ chế phạt.',
    text3: 'Thêm máy GPU vào mạng DBC bằng chế độ thuê dài hạn, sau đó tự thuê lại máy (máy đang thuê trên DBC sẽ nhận thêm 30% phần thưởng). Sau đó, khởi động một máy ảo và bắt đầu khai thác các dự án GPU như Aleo, IONET, Aethir, v.v. Trong chế độ này, máy ảo sẽ không bị tắt, phù hợp với bất kỳ dự án khai thác GPU nào. Tuy nhiên, lưu ý rằng chế độ này sẽ hủy thu nhập từ thuê DBC, vì vậy chỉ có lợi nếu phần thưởng DBC vượt quá tổn thất từ việc hủy thuê.',
    text4_1: 'Để kiểm tra thu nhập từ khai thác DBC, tham khảo:',
    text4_2: '\n Công thức tính thu nhập hàng tháng của máy.',
  },
  footer: {
    logo: 'Tải logo',
    server: 'Email dịch vụ'
  },
}