<template>
  <div class="councilVoting" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('councilVoting.title') }}</div>
      <div class="cont_desc margin0">{{ $t('councilVoting.text') }}</div>
      <div class="cont_btns">
        <a target="_blank" href="https://t.me/dbccouncil" class="cont_btn">{{ $t('councilVoting.seedetails1') }}</a>
        <a target="_blank" href="https://www.dbcwallet.io/#/council" class="cont_btn">{{ $t('councilVoting.seedetails2') }}</a>
      </div>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('councilVoting.cont.title') }}</p>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('councilVoting.procedure1.title1') }}</p>
        <p class="procedure_text2">{{ $t('councilVoting.procedure1.text1') }}<a class="procedure_text3" target="_blank" href="https://www.dbcwallet.io/#/council">https://www.dbcwallet.io/#/council</a>{{ $t('councilVoting.procedure1.text2') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'"  src="../../assets/wiki/wiki_img12.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/wiki_img12_en.png" alt="">
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('councilVoting.procedure1.text3') }}</p>
        <p class="procedure_text2">{{ $t('councilVoting.procedure1.text4') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'"  src="../../assets/wiki/wiki_img13.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/wiki_img13_en.png" alt="">
        <p class="procedure_text2">{{ $t('councilVoting.procedure1.text5') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'"  src="../../assets/wiki/wiki_img14.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/wiki_img14_en.png" alt="">
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('councilVoting.procedure1.text6') }}</p>
        <p class="procedure_text2">{{ $t('councilVoting.procedure1.text7') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'"  src="../../assets/wiki/wiki_img15.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/wiki_img15_en.png" alt="">
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'councilVoting',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.councilVoting {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .cont_btns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
      padding: 10px 20px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 40px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
    
  }
}

</style>
