<template>
  <div class="whatisGPU" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content">
      <div class="cont_left">
        <p class="left_text1">{{ $t('whatisGPU.title1') }} <span class="gradient_text">{{ $t('whatisGPU.title2') }}</span> </p>
        <!-- <p class="left_text1 gradient_text">{{ $t('whatisGPU.title2') }}</p> -->
        <p class="left_text2">{{ $t('whatisGPU.text1') }}</p>
      </div>
      <div class="cont_right">
        <div class="list">
          <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="text1"><span class="line color3"></span>{{ $t('whatisGPU.block1.title') }}</div>
            <div class="text2">{{ $t('whatisGPU.block1.text') }}</div>
          </div>
          <div class="listInfo delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="text1"><span class="line color2"></span>{{ $t('whatisGPU.block2.title') }}</div>
            <div class="text2">{{ $t('whatisGPU.block2.text') }}</div>
          </div>
          <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="text1"><span class="line color1"></span>{{ $t('whatisGPU.block3.title') }}</div>
            <div class="text2">{{ $t('whatisGPU.block3.text') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content1">
      <div class="cont_left">
        <p class="left_text1">{{ $t('whatisGPU.title3') }}</p>
        <p class="left_text1 gradient_text margin48">{{ $t('whatisGPU.title4') }}</p>
        <p class="left_text2">{{ $t('whatisGPU.text2') }}</p>
        <p class="left_text2">{{ $t('whatisGPU.text3') }}</p>
      </div>
      <div class="cont_right">
        <div class="list">
          <div class="listInfo delay100 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="text1"><span class="line color3"></span>{{ $t('whatisGPU.block4.title') }}</div>
            <div class="text2">{{ $t('whatisGPU.block4.text') }}</div>
          </div>
          <div class="listInfo delay200 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="text1"><span class="line color2"></span>{{ $t('whatisGPU.block5.title') }}</div>
            <div class="text2">{{ $t('whatisGPU.block5.text') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'whatisGPU',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.whatisGPU {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 48px 24px;
    box-sizing: border-box;    
    justify-content: space-between;
    .cont_left {
      width: 100%;
      display: flex;
      text-align: center;
      // margin-right: 100px;
      flex-direction: column;
      .left_text1 {
        color: #fff;
        font-size: 32px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .left_text2 {
        color: #899AA9;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
        margin: 48px 0;
      }
    }
    .cont_right {
      display: flex;
      margin-top: 48px;
      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .listInfo {
          width: 100%;
          padding: 16px;
          margin-top: 15px;
          box-sizing: border-box;
          backdrop-filter: blur(2px);
          background: rgba(0, 0, 0, 0.24);
          .text1 {
            display: flex;
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            align-items: center;
            margin-bottom: 10px;
            .line {
              width: 2px;
              height: 28px;
              margin-right: 15px;
              &.color3 {
                background: #9945FF;
              }
              &.color2 {
                background: #2059FE;
              }
              &.color1 {
                background: #16C8FF;
              }
              &.color6 {
                background: #FF5D39;
              }
              &.color5 {
                background: #FFD512;
              }
              &.color4 {
                background: #19FB9B;
              }
            }
          }
          .text2 {
            font-size: 16px;
            color: #899AA9;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  .content1 {
    .cont_left  {
      .margin48 {
        margin-bottom: 48px;
       }
      .left_text2 {
        color: #899AA9;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
        margin: 0 0 16px;
      }
    }
  }
}
</style>
