<template>
  <div class="buyGpuServer" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('buyGpuServer.title') }}</div>
      <div class="cont_desc">{{ $t('buyGpuServer.text1') }}{{ $t('buyGpuServer.text2') }}</div>
      <!-- <div class="cont_desc">{{ $t('buyGpuServer.text2') }}</div> -->
    </div>
    <div class="content2">
      <div class="cont_title">{{ $t('home.cont3.title') }}</div>
      <div class="cont_desc">{{ $t('home.cont3.text') }}</div>
      <swiper
        :space-between="50"
        :modules="modules"
        navigation
      >
        <swiper-slide>
          <div class="ecologyLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/cont3_img1.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title1') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text1') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.deeplink.cloud/"><span><img src="../../assets/home/deeplink.png" alt=""></span> {{ $t('home.cont3.btn1_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/decentral_gpt.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title2') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text2') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.decentralgpt.org/"><span><img src="../../assets/home/decentral_gpt.png" alt=""></span> {{ $t('home.cont3.btn2_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/superimage.webp" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title3') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text3') }}</div>
            <div class="ecology_btns">
              <!-- <div class="btn1">{{ $t('home.cont3.btn3_1') }}</div> -->
              <a class="btn1" target="_blank" href="https://www.superimage.ai/"><span><img src="../../assets/home/superimage.webp" alt=""></span> {{ $t('home.cont3.btn3_1') }}</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="ecologyLi delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/drcpad.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title4') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text4') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.drcpad.io/"><span><img src="../../assets/home/drcpad.png" alt=""></span> {{ $t('home.cont3.btn4_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/cont3_img5.jpg" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title5') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text5') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.hycons.cloud"><span><img src="../../assets/home/hycons.png" alt=""></span> {{ $t('home.cont3.btn5_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/subscan.webp" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title6') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text6') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://dbc.subscan.io/"><span><img src="../../assets/home/subscan.webp" alt=""></span> {{ $t('home.cont3.btn6_1') }}</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/dbcwallet.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title7') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text7') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://test.dbcscan.io/"><span><img src="../../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn7_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/cont3_img9.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title9') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text9') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://xaiagent.io/"><span><img src="../../assets/home/cont3_img9.png" alt=""></span> {{ $t('home.cont3.btn9_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../../assets/home/dbcwallet.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title8') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text8') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.dbcwallet.io/"><span><img src="../../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn8_1') }}</a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  export default defineComponent({
    name: 'buyGpuServer',
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
        modules: [Navigation],
      };
    }
  })
</script>

<style lang="scss" scoped>
.buyGpuServer {
  width: 100%;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    margin: auto;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
  }
  .content2 {
    position: relative;
    width: 100%;
    // height: 1010px;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont_title {
      color: #fff;
      margin-bottom: 12px;
    }
    .ecologyList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 48px auto 0;
      justify-content: center;
      .ecologyLi {
        width: 342px;
        // height: 270px;
        position: relative;
        margin-bottom: 40px;
        padding: 32px 16px 16px;
        box-sizing: border-box;
        border: 1px solid #FFF;
        transition: all 1s;
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        // &:hover {
        //   border: 1px solid;
        //   border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
        // }
        .ecologyIcon {
          position: absolute;
          width: 48px;
          height: 48px;
          top: -25px;
          left: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ecology_title {
          color: #FFF;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 12px;
          line-height: normal;
        }
        .ecology_text {
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 12px;
          word-wrap: break-word;
        }
        .ecology_btns {
          // position: absolute;
          // bottom: 30px;
          width: 100%;
          height: 43px;
          display: flex;
          color: #FFF;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          align-items: center;
          justify-content: space-between;
          .btn {
            width: 145px;
            height: 43px;
            color: #FFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .btn1 {
            width: 100%;
            height: 43px;
            display: flex;
            color: #FFF;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
  }
  .swiper-slide {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 48px;
    margin: 0 auto;
    overflow: hidden;
    justify-content: space-between;
    .ecologyLi {
      width: 342px;
      // height: 270px;
      position: relative;
      margin-bottom: 40px;
      padding: 32px 16px 16px;
      box-sizing: border-box;
      border: 1px solid #FFF;
      transition: all 1s;
      box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
      background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
      // &:hover {
      //   border: 1px solid;
      //   border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      // }
      &:last-child {
        margin-bottom: 80px;
      }
      .ecologyIcon {
        position: absolute;
        width: 48px;
        height: 48px;
        top: -25px;
        left: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ecology_title {
        color: #FFF;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 12px;
        line-height: normal;
      }
      .ecology_text {
        font-size: 14px;
        color: #899AA9;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 12px;
        word-wrap: break-word;
      }
      .ecology_btns {
        // position: absolute;
        // bottom: 30px;
        width: 100%;
        height: 43px;
        display: flex;
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        align-items: center;
        justify-content: space-between;
        .btn {
          width: 145px;
          height: 43px;
          color: #FFF;
          display: flex;
          cursor: pointer;
          align-items: center;
          text-decoration: none;
          justify-content: center;
          border: 1px solid #FFF;
          span {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .btn1 {
          width: 100%;
          height: 43px;
          display: flex;
          color: #FFF;
          cursor: pointer;
          align-items: center;
          text-decoration: none;
          justify-content: center;
          border: 1px solid #FFF;
          span {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

</style>
