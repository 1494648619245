<template>
  <div class="participateGPUMining" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="pagecont_bg">
        <div class="bg">
          <img src="../../assets/miner/miner_bg.png" alt="">
        </div>
        <img class="cont_img" src="../../assets/miner/miner_img1.png" alt="">
      </div>
      <div class="title"><p>{{ $t('participateGPUMining.title') }}</p></div>
      <div class="pagecont">
        <div class="cont_left">
          <div class="list">
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateGPUMining.text1') }} <a class="nav1" href="https://galaxyrace.deepbrainchain.org/rule" target="_blank">https://galaxyrace.deepbrainchain.org/rule</a></p>
            </div>
            <div class="listInfo delay500 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateGPUMining.text2') }}</p>
            </div>
            <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateGPUMining.text3') }}</p>
            </div>
            <div class="listInfo delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateGPUMining.text4_1') }}<a class="nav1" href="https://galaxyrace.deepbrainchain.org/rule" target="_blank">https://galaxyrace.deepbrainchain.org/rule</a> {{ $t('participateGPUMining.text4_2') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'participateGPUMining',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.participateGPUMining {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .6);
  }
  .pagecont1 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 0 0 100px;
    box-sizing: border-box;   
    .pagecont_bg {
      position: relative;
      width: 100%;
      text-align: right;
      .bg {
        position: absolute;
        right: -500px;
        top: -300px;
        z-index: 1;
        img {
          width: 1000px;
        }
      }
      .cont_img {
        width: 100%;
      }
    }  
    .title {
      position: relative;
      z-index: 2;
      width: 90%;
      margin: auto;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 20px;
      p {
        display: inline-block;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
      }
    }
    .pagecont {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .cont_left {
        position: relative;
        width: 90%;
        margin: auto;
        display: flex;
        word-wrap: break-word;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          border-radius: 10px;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
            border-radius: 20px;
            box-sizing: border-box;
            background-image: linear-gradient(0deg, #03FFF0 30%, #6409F7 70%);
            &:last-child {
              margin-bottom: 0;
            }
            p {
              margin-left: 1px;
              padding: 10px 30px;
              border-radius: 19px;
              background: #03061D;
              white-space: break-spaces;
            }
          }
          .mb20 {
            margin-bottom: 20px;
          }
          .ml20 {
            margin-left: 20px;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
          .text1 {
            color: #9DFFD4;
            .nav1 {
              color: #9DFFD4;
            }
          }
        }
      }
    }
    
  }
}
</style>
