<template>
  <div class="developAi" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="title">{{ $t('developAi.title') }}</div>
      <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div>
      <img class="bg_img delay100 animateHide" v-animate="{delay: 100, class:'amplify'}" src="../../assets/aipro/ai_img3.png" alt="">
      <div class="pagecont">
        <div class="cont_left">
          <div class="list">
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('developAi.title1') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text1') }}</div>
            </div>
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('developAi.title2') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text2_1') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text2_2') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text2_3') }}</div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('developAi.title3') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text3_1') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text3_2') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text3_3') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text3_4') }}</div>
            </div>
            <div class="listInfo delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('developAi.title4') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text4_1') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text4_2') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text4_3') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text4_4') }}</div>
              <div class="text2 cont_desc">{{ $t('developAi.text4_5') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'developAi',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.developAi {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    word-wrap: break-word;
    color: rgba(255, 255, 255, .8)
  }
  .pagecont1 {
    position: relative;
    width: 90%;
    margin: auto;
    padding: 80px 0 100px;
    box-sizing: border-box;    
    .title {
      width: 60%;
      font-size: 30px;
      font-weight: 600;
      line-height: auto;
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
    }
    .bg1 {
      position: absolute;
      top: -50px;
    }
    .bg_img {
      position: absolute;
      top: 50px;
      right: -190px;
      width: 350px;
    }
    .pagecont {
      position: relative;
      z-index: 1;
      display: flex;
      text-align: left;
      justify-content: space-between;
      .cont_left {
        width: 100%;
        display: flex;
        margin-top: 150px;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            width: 100%;
            padding-left: 40px;
            margin-bottom: 40px;
            box-sizing: border-box;
            border-left: 1px solid #0EF0FF;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    
  }
}
</style>
