
<template>
  <div class="gpuMiner" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('gpuMiner.title') }}</div>
      <div class="cont_desc margin0">{{ $t('gpuMiner.text') }}</div>
      <a target="_blank" href="https://galaxyrace.deepbrainchain.org/" class="cont_btn">{{ $t('gpuMiner.seedetails') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('gpuMiner.cont.title') }}</p>
      <p class="cont2_text2 gradient_text">{{ $t('gpuMiner.cont.text') }}</p>
      <div class="select">
        <div class="topcon">
          <div class="topitem">{{ $t('gpuMiner.cont.text1') }}
            <el-select v-model="gpu_type" :placeholder="$t('gpuMiner.cont.text5')" @change='SelectGPU'>
              <el-option v-for="(item, index) in options " :key="item.value" :label="item.name + '-Mem:' + item.men" :value="item.cuda+'_'+item.men+'_'+item.large+'_'+index"></el-option>
            </el-select> 
          </div>
          <div class="topitem">{{ $t('gpuMiner.cont.text2') }}
            <el-select v-model="gpu_num" :placeholder="$t('gpuMiner.cont.text5')" @change='SelectGpuNum'>
              <el-option v-for="(item, index) in options1 " :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select> 
          </div>
          <div class="topitem">{{ $t('gpuMiner.cont.text3') }}
            <el-select v-model="mem_num" :placeholder="$t('gpuMiner.cont.text5')" @change='SelectMem'>
              <el-option v-for="(item, index) in options2 " :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select> 
          </div>
          <div class="topitem">{{ $t('gpuMiner.cont.text4') }}
            <el-select v-model="local" :placeholder="$t('gpuMiner.cont.text5')" @change='SelectLocal'>
              <el-option v-for="(item, index) in options3 " :key="item.value" :label="item.name" :value="item.value +'_'+index"></el-option>
            </el-select> 
          </div>
        </div>
      </div>
      <p class="cont2_text3">{{ $t('gpuMiner.cont.text6') }} {{Income}} {{ $t('gpuMiner.cont.text7') }} <br/> {{ $t('gpuMiner.cont.text8') }} {{price3}} {{ $t('gpuMiner.cont.text9') }} {{ price1 }} {{ $t('gpuMiner.cont.text10') }} {{dbcPrice}} {{ $t('gpuMiner.cont.text11') }} {{ price2 }} {{ $t('gpuMiner.cont.text12') }}</p>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { dbcPriceOcw } from '../../api/http'
  import { getRewardInfo } from '../../api/wss'
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'gpuMiner',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      const { t, locale } = useI18n()
      const options = ref([
        {name: '1080', cuda: '2560', men: '8', large: 1},
        {name: '1080ti', cuda: '3584', men: '11', large: 1},
        {name: '1660', cuda: '1408', men: '6', large: 1},
        {name: '1660ti', cuda: '1536', men: '6', large: 1},
        {name: '1660s', cuda: '1408', men: '6', large: 1},
        {name: '2070s', cuda: '2560', men: '8', large: 1},
        {name: '2080', cuda: '2944', men: '8', large: 1},
        {name: '2080s', cuda: '3072', men: '8', large: 1},
        {name: '2080ti', cuda: '4352', men: '11', large: 1},
        {name: '3060', cuda: '3584', men: '12', large: 1},
        {name: '3060ti', cuda: '4864', men: '8', large: 1},
        {name: '3070', cuda: '5888', men: '8', large: 1},
        {name: '3070ti', cuda: '6144', men: '8', large: 1},
        {name: '3080', cuda: '8704', men: '10', large: 1},
        {name: '3080ti', cuda: '10240', men: '12', large: 1},
        {name: '3090', cuda: '10496', men: '24', large: 1},
        {name: 'A4000', cuda: '6144', men: '16', large: 1},
        {name: 'A5000', cuda: '8192', men: '24', large: 1},
        {name: 'A40', cuda: '10752', men: '48', large: 1},
        {name: 'A100 PCIe', cuda: '6912', men: '40', large: 2},
        {name: 'A100 PCIe', cuda: '6912', men: '80', large: 2},
        {name: 'A100 SXM4', cuda: '6912', men: '40', large: 2},
        {name: 'A100 SXM4', cuda: '6912', men: '80', large: 2},
        {name: 'A800 PCIe', cuda: '6912', men: '40', large: 2},
        {name: 'A800 PCIe', cuda: '6912', men: '80', large: 2},
        {name: 'A800 SXM5', cuda: '8192', men: '80', large: 2},
        {name: 'V100', cuda: '5120', men: '16', large: 2},
        {name: 'V100', cuda: '5120', men: '32', large: 2},
        {name: 'H100 SXM5', cuda: '16896', men: '80', large: 2},
        {name: 'H100 PCIe', cuda: '14592', men: '80', large: 2},
        {name: 'H100 NVL1', cuda: '16896', men: '94', large: 2},
        {name: 'H800 SXM5', cuda: '16896', men: '80', large: 2},
        {name: '4060', cuda: '3072', men: '8', large: 1},
        {name: '4060ti', cuda: '4352', men: '8', large: 1},
        {name: '4060ti', cuda: '4352', men: '16', large: 1},
        {name: '4070', cuda: '5888', men: '12', large: 1},
        {name: '4070ti', cuda: '7680', men: '12', large: 1},
        {name: '4080', cuda: '9728', men: '16', large: 1},
        {name: '4080ti', cuda: '14080', men: '20', large: 1},
        {name: '4090', cuda: '16384', men: '24', large: 1},
        {name: '4090ti', cuda: '18176', men: '24', large: 1}
      ])
      const options1 = ref([
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
        {label: 6, value: 6},
        {label: 7, value: 7},
        {label: 8, value: 8}
      ])
      const options2 = ref([])
      const options2_1 = ref([])
      const options3 = ref([
        { name: t('gpuMiner.country.country1'), key: 1, value: 1 },
        { name: t('gpuMiner.country.country2'), key: 2, value: 1.2 },
        { name: t('gpuMiner.country.country3'), key: 3, value: 1.3 },
        { name: t('gpuMiner.country.country4'), key: 4, value: 1.5 },
        { name: t('gpuMiner.country.country5'), key: 5, value: 1.6 },
        { name: t('gpuMiner.country.country6'), key: 6, value: 1.6 },
        { name: t('gpuMiner.country.country7'), key: 7, value: 1.7 },
        { name: t('gpuMiner.country.country8'), key: 8, value: 1.3 },
        { name: t('gpuMiner.country.country9'), key: 9, value: 1.3 },
        { name: t('gpuMiner.country.country10'), key: 10, value: 1.5 },
        { name: t('gpuMiner.country.country11'), key: 11, value: 1.5 },
        { name: t('gpuMiner.country.country12'), key: 12, value: 1.5 },
        { name: t('gpuMiner.country.country13'), key: 13, value: 1.3 }
      ])
      const gpu_type = ref('')
      const gpu_num = ref('')
      const mem_num = ref('')
      const local = ref('')
      const numList = ref([])
      const Income = ref(0) // 月收益
      const dbcPrice = ref(0.0019) // dbc价格
      const price1 = ref(0)
      const price2 = ref(0)
      const price3 = ref(0)
      const countVideo_num = ref(0) // 显存
      const countGpu_num = ref(0) // 显卡数量
      const countMem_num = ref(0) // 内存
      const countLarge_num = ref(1) // 大模型系数
      const countCuda_core = ref(0) // cuda数量
      const countLocal_num = ref(0) // 地域系数
      const totalCalcPoints = ref(0) // 总算力值
      const machineCalcPoints = ref(0) // 整机算力值
      const machinePoints = ref(0) // 整机算力值
      const SelectGPU = (val) => {
        const arr = val.split('_')
        countCuda_core.value = arr[0]
        countVideo_num.value = arr[1]
        countLarge_num.value = arr[2]
        MonthlyIncome()
      }
      const SelectGpuNum = (val) => {
        countGpu_num.value = val
        options2.value = []
        mem_num.value = ''
        for (let i = 0; i < numList.value.length; i ++) {
          let mem_value = val * numList.value[i]
          options2.value.push({ name: mem_value, value: mem_value })
        }
        mem_num.value = val * numList.value[0]
        countMem_num.value = val * numList.value[0]
        MonthlyIncome()
      }
      const SelectMem = (val) => {
        countMem_num.value = val
        MonthlyIncome()
      }
      const SelectLocal = (val) => {
        const arr = val.split('_')
        countLocal_num.value = arr[0]
        MonthlyIncome()
      }
      const countPoint = (Gpu_Num, Mem, Cuda, M_value, coe) => {
        var num = Math.round(((Gpu_Num*25+ Mem/3.5 + Math.sqrt(Cuda) * Math.sqrt(M_value/10)*Gpu_Num)*coe) * 100) / 100
        return num
      }
      const MonthlyIncome = () => {
        console.log(countCuda_core.value, countVideo_num.value, dbcPrice.value, countGpu_num.value, countMem_num.value, countLarge_num.value);
        console.log(countLocal_num.value, totalCalcPoints.value);
        if (countCuda_core.value&&countVideo_num.value
        &&dbcPrice.value&&countGpu_num.value
        &&countMem_num.value&&countLarge_num.value
        &&countLocal_num.value&&totalCalcPoints.value) {
          machineCalcPoints.value = countPoint(countGpu_num.value, countMem_num.value, countCuda_core.value, countVideo_num.value, countLocal_num.value)
          
          price1.value = Math.round((machineCalcPoints.value/totalCalcPoints.value*1095890*dbcPrice.value*1.3*30)*countLarge_num.value*100) / 100
          price2.value = Math.round((machineCalcPoints.value*0.508)*countLarge_num.value*100*0.7) / 100
          price3.value = Math.round((machineCalcPoints.value/totalCalcPoints.value*1095890*1.3*30)*countLarge_num.value*100) / 100
          Income.value = Math.round((machineCalcPoints.value/totalCalcPoints.value*1095890*dbcPrice.value*1.3*30+machineCalcPoints.value*0.508*0.7)*countLarge_num.value*100) / 100
        }
      }
      const getPrice = async () => {
        const priceData = await dbcPriceOcw()
        if (priceData.status == 1) {
          dbcPrice.value = priceData.content.dbc_price
        }
        const rewardInfo = await getRewardInfo();
        totalCalcPoints.value = rewardInfo.totalCalcPoints/100
      }

      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
        options3.value = [
          { name: t('gpuMiner.country.country1'), key: 1, value: 1 },
          { name: t('gpuMiner.country.country2'), key: 2, value: 1.2 },
          { name: t('gpuMiner.country.country3'), key: 3, value: 1.3 },
          { name: t('gpuMiner.country.country4'), key: 4, value: 1.5 },
          { name: t('gpuMiner.country.country5'), key: 5, value: 1.6 },
          { name: t('gpuMiner.country.country6'), key: 6, value: 1.6 },
          { name: t('gpuMiner.country.country7'), key: 7, value: 1.7 },
          { name: t('gpuMiner.country.country8'), key: 8, value: 1.3 },
          { name: t('gpuMiner.country.country9'), key: 9, value: 1.3 },
          { name: t('gpuMiner.country.country10'), key: 10, value: 1.5 },
          { name: t('gpuMiner.country.country11'), key: 11, value: 1.5 },
          { name: t('gpuMiner.country.country12'), key: 12, value: 1.5 },
          { name: t('gpuMiner.country.country13'), key: 13, value: 1.3 }
        ]
      })
      onMounted(() => {
        for (let i = 1; i < 33; i ++) {
          numList.value.push(16*i)
        }
        getPrice()
      });
      return {
        lan,
        gpu_type,
        gpu_num,
        mem_num,
        local,
        Income,
        price1,
        price2,
        price3,
        dbcPrice,
        machinePoints,
        options,
        options1,
        options2,
        options2_1,
        options3,
        SelectGPU,
        SelectGpuNum,
        SelectMem,
        SelectLocal,
      };
    }
  })
</script>

<style lang="scss" scoped>
.gpuMiner {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .cont_btns {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
      padding: 10px 20px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      width: 100%;
      margin: auto;
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 40px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
    .cont2_text3 {
      width: 100%;
      margin: auto;
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 40px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
    .select {
      margin-top: 40px;
      .topcon{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .topitem{
          width: 100%;
          color: #FFF;
          font-size: 16px;
          margin-right: 15px;
          margin-bottom: 10px;
          &.bold{
            font-weight: bold;
          }
          .select_width210{
            width: 210px;
          }
          .select_width200{
            width: 200px;
          }
          .select_width60 {
            width: 60px;
          }
          .select_width100 {
            width: 100px;
          }
        }
      }
    }
  }
}

</style>

<style>
.el-input__wrapper {
  background: transparent !important;
}
.el-input__inner {
  color: #38EED6 !important;
}
</style>