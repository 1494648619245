<template>
  <div class="home" :class="{lan_En : lan != 'zh', lan_Ru: (lan == 'ru' || lan == 'vn')}">
    <div class="content1">
      <div class="introduce">
        <p class="text1 delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">{{ $t('home.cont1.text1') }}</p>
        <p class="text2 gradient_text delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('home.cont1.text2') }}</p>
        <p class="text2 gradient_text delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('home.cont1.text2_1') }}</p>
      </div>
      <div class="line_ball">
        <div class="line delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}"></div>
        <div class="content1_bg1">
          <img src="../assets/ball.gif" class="delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}" alt="">
        </div>
      </div>
      
      <div class="detailed delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">{{ $t('home.cont1.text3') }}</div>
      <div class="detailed delay800 animateHide" v-animate="{delay: 800, class:'fadeInUp'}">{{ $t('home.cont1.text4') }}</div>
      <div class="detailed delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">{{ $t('home.cont1.text5') }}</div>
    </div>
    
    <div class="content2">
      <swiper
        :space-between="50"
        :modules="modules"
        navigation
      >
        <!-- <swiper-slide>
          <div class="sliper">
            <div class="sliper_img"><img src="../assets/home/comp4.png" alt=""></div>
            <div class="sliper_text">{{ $t('home.cont2.title4') }}{{ $t('home.cont2.title4_1') }}</div>
            <div class="sliper_btn" @click="linkHerf('https://www.deeplink.cloud/public-sale/')">{{ $t('home.cont2.text4') }}</div>
          </div>
        </swiper-slide> -->
        <swiper-slide>
          <div class="sliper">
            <div class="sliper_img"><img src="../assets/home/comp3.jpg" alt=""></div>
            <div class="sliper_text">{{ $t('home.cont2.title1') }}</div>
            <div class="sliper_btn" @click="seeVideo(1)">{{ $t('home.cont2.text1') }}</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="sliper">
            <div class="sliper_img" style="margin-left: 30px;"><img src="../assets/home/comp1.png" alt=""></div>
            <div class="sliper_text">{{ $t('home.cont2.title2') }}</div>
            <div class="sliper_btn" @click="linkHerf('https://galaxyrace.deepbrainchain.org/')">{{ $t('home.cont2.text2') }}</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="sliper">
            <div class="sliper_img"><img src="../assets/home/comp2.png" alt=""></div>
            <div class="sliper_text">{{ $t('home.cont2.title3') }}</div>
            <div class="sliper_btn" @click="seeVideo(2)">{{ $t('home.cont2.text3') }}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="content3">
      <div class="cont_title">{{ $t('home.cont3.title') }}</div>
      <div class="cont_desc">{{ $t('home.cont3.text') }}</div>
      <swiper
        :space-between="50"
        :modules="modules"
        navigation
      >
        <swiper-slide>
          <div class="ecologyLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/cont3_img1.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title1') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text1') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.deeplink.cloud/"><span><img src="../assets/home/deeplink.png" alt=""></span> {{ $t('home.cont3.btn1_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/decentral_gpt.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title2') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text2') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.decentralgpt.org/"><span><img src="../assets/home/decentral_gpt.png" alt=""></span> {{ $t('home.cont3.btn2_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/superimage.webp" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title3') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text3') }}</div>
            <div class="ecology_btns">
              <!-- <div class="btn1">{{ $t('home.cont3.btn3_1') }}</div> -->
              <a class="btn1" target="_blank" href="https://www.superimage.ai/"><span><img src="../assets/home/superimage.webp" alt=""></span> {{ $t('home.cont3.btn3_1') }}</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="ecologyLi delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/drcpad.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title4') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text4') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.drcpad.io/"><span><img src="../assets/home/drcpad.png" alt=""></span> {{ $t('home.cont3.btn4_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/cont3_img5.jpg" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title5') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text5') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.hycons.cloud"><span><img src="../assets/home/hycons.png" alt=""></span> {{ $t('home.cont3.btn5_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/subscan.webp" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title6') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text6') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://dbc.subscan.io/"><span><img src="../assets/home/subscan.webp" alt=""></span> {{ $t('home.cont3.btn6_1') }}</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/dbcwallet.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title7') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text7') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://test.dbcscan.io/"><span><img src="../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn7_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/cont3_img9.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title9') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text9') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://xaiagent.io/"><span><img src="../assets/home/cont3_img9.png" alt=""></span> {{ $t('home.cont3.btn9_1') }}</a>
            </div>
          </div>
          <div class="ecologyLi delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="ecologyIcon"><img src="../assets/home/dbcwallet.png" alt=""></div>
            <div class="ecology_title">{{ $t('home.cont3.title8') }}</div>
            <div class="ecology_text">{{ $t('home.cont3.text8') }}</div>
            <div class="ecology_btns">
              <a class="btn1" target="_blank" href="https://www.dbcwallet.io/"><span><img src="../assets/home/dbcwallet.png" alt=""></span> {{ $t('home.cont3.btn8_1') }}</a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>  
    <div class="unifycontent">
      <div class="content4">
        <div class="cont_title">{{ $t('home.cont4.title') }}</div>
        <div class="cont_desc">{{ $t('home.cont4.text') }}</div>
        <swiper
          :space-between="50"
          :modules="modules"
          navigation
        >
          <swiper-slide>
            <div class="number_block w270">
              <div class="number num1"> {{ Number_inc.count1 }}</div>
              <div class="num_desc">{{ $t('home.cont4.text1') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num2">{{ Number_inc.count2 }}%</div>
              <div class="num_desc">{{ $t('home.cont4.text2') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num3">{{ Number_inc.count3.toLocaleString() }}</div>
              <div class="num_desc">{{ $t('home.cont4.text3') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num4">{{ Number_inc.count4.toLocaleString() }}</div>
              <div class="num_desc">{{ $t('home.cont4.text4') }}</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="number_block w270">
              <div class="number num1">{{ Number_inc.count5 }}</div>
              <div class="num_desc">{{ $t('home.cont4.text5') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num2">{{ Number_inc.count6 }}</div>
              <div class="num_desc">{{ $t('home.cont4.text6') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num3">{{ Number_inc.count7.toLocaleString() }}</div>
              <div class="num_desc">{{ $t('home.cont4.text7') }}</div>
            </div>
            <div class="number_block w270">
              <div class="number num4">{{ Number_inc.count8 }}</div>
              <div class="num_desc">{{ $t('home.cont4.text8') }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="content5">
        <div class="cont_title">{{ $t('home.cont5.title') }}</div>
        <!-- <div class="cont_desc">{{ $t('home.cont5.text') }}</div> -->
        <div class="right">
          <div class="list">
            <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <div class="text1"><span class="line color3"></span>{{ $t('home.cont5.block1.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block1.text') }}</div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1"><span class="line color2"></span>{{ $t('home.cont5.block2.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block2.text') }}</div>
            </div>
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="text1"><span class="line color1"></span>{{ $t('home.cont5.block3.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block3.text') }}</div>
            </div>
          </div>
          <div class="list">
            <div class="listInfo delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
              <div class="text1"><span class="line color6"></span>{{ $t('home.cont5.block4.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block4.text') }}</div>
            </div>
            <div class="listInfo delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
              <div class="text1"><span class="line color5"></span>{{ $t('home.cont5.block5.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block5.text') }}</div>
              <div class="text2">{{ $t('home.cont5.block5.text1') }}</div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1"><span class="line color4"></span>{{ $t('home.cont5.block6.title') }}</div>
              <div class="text2">{{ $t('home.cont5.block6.text') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content6">
        <div class="cont_title">{{ $t('home.cont6.title') }}</div>
        <div class="token_list">
          <div class="token_li">
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block1.text') }}</p></div>
            </div>
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block2.text') }}</p></div>
              <div class="token_text">
                <span class="icon"></span>
                <p>{{ $t('home.cont6.block2.text1') }}</p>
              </div>
            </div>
          </div>
          <div class="token_li">
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block3.text') }}</p>
              </div>
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block3.text1') }}</p>
              </div>
            </div>
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block4.text', {stakeNum: totalNum.Stake.toLocaleString(), ratio: totalNum.Stake_ratio}) }}</p>
              </div>
            </div>
          </div>
          <div class="token_li">
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span>
                <p>{{ $t('home.cont6.block5.text', {stakeNum: Number_inc.count3.toLocaleString(), ratio: totalNum.Issuance_ratio}) }}</p>
              </div>
            </div>
            <div class="token_block ">
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text1') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text2') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text3') }}</p></div>
              <div class="token_text"><span class="icon"></span><p>{{ $t('home.cont6.block6.text4') }}</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content7">
        <div class="cont_title">{{ $t('home.cont7.title') }}</div>
        <div class="exchange">
          <a target="_blank" href="https://www.gate.tv/trade/DBC_USDT"><img src="../assets/home/gate.png" alt=""></a>
          <!-- <a target="_blank" href="https://www.htx.com/en-us/trade/dbc_usdt?type=spot"><img src="../assets/home/huobi.png" alt=""></a> -->
          <a target="_blank" href="https://swap.swft.pro/#/"><img src="../assets/home/swft.png" alt=""></a>
          <a target="_blank" href="https://app.uniswap.org/#/swap"><img src="../assets/home/uniswap.png" alt=""></a>
          <a target="_blank" href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=DBC_USDT"><img src="../assets/home/bitmart.png" alt=""></a>
          <a target="_blank" href="https://www.mexc.com/exchange/DBC_USDT?_from=search_spot_trade"><img class="mexc" src="../assets/home/mexc.svg" alt=""></a>
        </div>
        <div class="contract_add">{{ $t('home.cont7.text1') }}</div>
        <div class="contract_address">{{ $t('home.cont7.text2') }}</div>
      </div>
      <div class="content8">
        <div class="cont_title">{{ $t('home.cont8.title') }}</div>
        <div class="cont_desc">{{ $t('home.cont8.text1') }}</div>
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th class="" colspan="2">{{ $t('home.cont8.table.tr1') }}</th>
              <th class="color1">{{ $t('home.cont8.table.tr2') }}</th>
              <th class="color1">{{ $t('home.cont8.table.tr3') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr4') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr5') }}</th>
              <th class="color2">{{ $t('home.cont8.table.tr6') }}</th>
              <th class="" style="width: 200px;">{{ $t('home.cont8.table.tr7') }}</th>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody1.text1') }}</td>
              <td class="color1">15%</td>
              <td class="color1">15%</td>
              <td v-show="lan == 'zh'" class="color2">15</td>
              <td v-show="lan != 'zh'" class="color2">1.5</td>
              <td v-show="lan == 'zh'" class="color2">15</td>
              <td v-show="lan != 'zh'" class="color2">1.5</td>
              <td class="color2">-</td>
              <td class="font16">{{ $t('home.cont8.table.tbody1.text2') }}</td>
            </tr>
            <tr>
              <td class="width60" rowspan="2" style="width: 20px;">{{ $t('home.cont8.table.tbody2.text') }}</td>
              <td>{{ $t('home.cont8.table.tbody2.text1') }}</td>
              <td class="color1">17.35%</td>
              <td class="color1" rowspan="2">25%</td>
              <td v-show="lan == 'zh'" class="color2">17.35</td>
              <td v-show="lan == 'zh'" class="color2">11.47</td>
              <td v-show="lan == 'zh'" class="color2">5.88</td>
              
              <td v-show="lan != 'zh'" class="color2">1.735</td>
              <td v-show="lan != 'zh'" class="color2">1.147</td>
              <td v-show="lan != 'zh'" class="color2">0.588</td>
              <td class="font16">{{ $t('home.cont8.table.tbody2.text2') }}</td>
            </tr>
            <tr>
              <td>{{ $t('home.cont8.table.tbody3.text1') }}</td>
              <td class="color1">7.65%</td>
              <!-- <td class="color1">25%</td> -->
              <td v-show="lan == 'zh'" class="color2">7.65</td>
              <td v-show="lan == 'zh'" class="color2">1.02</td>
              <td v-show="lan == 'zh'" class="color2">6.63</td>

              <td v-show="lan != 'zh'" class="color2">0.765</td>
              <td v-show="lan != 'zh'" class="color2">0.102</td>
              <td v-show="lan != 'zh'" class="color2">0.663</td>
              <td class="font16">{{ $t('home.cont8.table.tbody3.text2') }}</td>
            </tr>

            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody4.text1') }}</td>
              <td class="color1">10%</td>
              <td class="color1">10%</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan == 'zh'" class="color2">7</td>
              <td v-show="lan == 'zh'" class="color2">3</td>

              <td v-show="lan != 'zh'" class="color2">1</td>
              <td v-show="lan != 'zh'" class="color2">0.7</td>
              <td v-show="lan != 'zh'" class="color2">0.3</td>
              <td class="font16">{{ $t('home.cont8.table.tbody4.text2') }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody5.text1') }}</td>
              <td class="color1">10%</td>
              <td class="color1">10%</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan == 'zh'" class="color2">10</td>
              <td v-show="lan != 'zh'" class="color2">1</td>
              <td v-show="lan != 'zh'" class="color2">1</td>
              <td class="color2">-</td>
              <td class="font16">{{ $t('home.cont8.table.tbody5.text2') }}</td>
            </tr>
            <tr>
              <td class="width60" rowspan="2" style="width: 20px;">{{ $t('home.cont8.table.tbody6.text') }}</td>
              <td>{{ $t('home.cont8.table.tbody6.text1') }}</td>
              <td class="color1">8%</td>
              <td class="color1" rowspan="2">40%</td>
              <td v-show="lan == 'zh'" class="color2">8</td>
              <td v-show="lan == 'zh'" class="color2">2</td>
              <td v-show="lan == 'zh'" class="color2">6</td>
              <td v-show="lan != 'zh'" class="color2">0.8</td>
              <td v-show="lan != 'zh'" class="color2">0.2</td>
              <td v-show="lan != 'zh'" class="color2">0.6</td>
              <td class="font16">{{ $t('home.cont8.table.tbody6.text2') }}</td>
            </tr>
            <tr>
              <td>{{ $t('home.cont8.table.tbody7.text1') }}</td>
              <td class="color1">32%</td>
              <!-- <td class="color1">40%</td> -->
              <td v-show="lan == 'zh'" class="color2">32</td>
              <td v-show="lan == 'zh'" class="color2">8</td>
              <td v-show="lan == 'zh'" class="color2">24</td>
              <td v-show="lan != 'zh'" class="color2">3.2</td>
              <td v-show="lan != 'zh'" class="color2">0.8</td>
              <td v-show="lan != 'zh'" class="color2">2.4</td>
              <td class="font16">{{ $t('home.cont8.table.tbody7.text2') }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('home.cont8.table.tbody8.text1') }}</td>
              <td class="color1" colspan="2" style="text-align: center;">100%</td>
              <!-- <td class="color1">15%</td> -->
              <td v-show="lan == 'zh'" class="color2">100</td>
              <td v-show="lan == 'zh'" class="color2">54.48</td>
              <td v-show="lan == 'zh'" class="color2">45.52</td>
              <td v-show="lan != 'zh'" class="color2">10</td>
              <td v-show="lan != 'zh'" class="color2">5.448</td>
              <td v-show="lan != 'zh'" class="color2">4.552</td>
              <td class="font16"></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="content9">
        <div class="text1">{{ $t('home.cont9.title') }}</div>
        <div class="partner">
          <img src="../assets/investor4.png" alt="">
          <img src="../assets/investor2.png" alt="">
          <img src="../assets/investor1.png" alt="">
          <img src="../assets/investor3.png" alt="">
          <!-- <img src="../assets/investor5.png" alt=""> -->
          <img src="../assets/investor6.png" alt="">
          <img src="../assets/investor.png" alt="">
          <img src="../assets/investor8.png" alt="">
          <img src="../assets/investor7.png" alt="">
          <img src="../assets/investor9.png" alt="">
        </div>
      </div>
      <div class="content10">
        <div class="cont_title">{{ $t('home.cont10.title') }}</div>
        <div class="timeLine">
          <div class="time_cont delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="time_num">
              <p>01</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text1_1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text1_2') }}</div>
            </div>
          </div>
          <div class="time_cont  delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="time_num">
              <p>02</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title2') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text2_1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text2_2') }}</div>
              <div class="time_cont_text">(<a href="https://github.com/DeepBrainChain">https://github.com/DeepBrainChain</a>)</div>
            </div>
          </div>
          <div class="time_cont delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="time_blank"></div>
            <div class="time_num">
              <p>03</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title3') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text3_1') }}</div>
            </div>
          </div>
          <div class="time_cont  delay400 animateHide" v-animate="{delay: 400, class:'fadeInUp'}">
            <div class="time_num">
              <p>04</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title4') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_1') }}</div>
              <div class="time_cont_text">(<a href="https://www.dbcwallet.io">https://www.dbcwallet.io</a>)</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_2') }}</div>
              <div class="time_cont_text">(<a href="https://dbc.subscan.io">https://dbc.subscan.io</a>)</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_3') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_4') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_5') }}</div>
              <div class="time_cont_text">(<a href="https://galaxyrace.deepbrainchain.org">https://galaxyrace.deepbrainchain.org</a>)</div>
              <div class="time_cont_text">{{ $t('historyDBC.text4_6') }}</div>
            </div>
          </div>
          <div class="time_cont delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="time_blank"></div>
            <div class="time_num">
              <p>05</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title5') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text5_1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text5_2') }}</div>
              <div class="time_cont_text">(<a href="https://hibaocloud.com" target="_blank">https://hibaocloud.com</a>)</div>
              <div class="time_cont_text">{{ $t('historyDBC.text5_3') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text5_4') }}</div>
              <div class="time_cont_text">(<a href="https://www.hycons.cloud" target="_blank">https://www.hycons.cloud</a>)</div>
              <div class="time_cont_text">{{ $t('historyDBC.text5_5') }}</div>
              <div class="time_cont_text">(<a href="https://www.deeplink.cloud" target="_blank">https://www.deeplink.cloud</a>)</div>
            </div>
          </div>
          <div class="time_cont  delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
            <div class="time_num">
              <p>06</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title6') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text6_1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text6_2') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text6_3') }}</div>
            </div>
          </div>
          <div class="time_cont  delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
            <div class="time_num">
              <p>07</p>
              <div class="time_line"></div>
            </div>
            <div class="time_content">
              <div class="time_cont_title">{{ $t('historyDBC.title7') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_1') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_2') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_3') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_4') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_5') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_6') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_7') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_8') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_9') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_10') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_11') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_12') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_13') }}</div>
              <div class="time_cont_text">{{ $t('historyDBC.text7_14') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content12">
        <div class="cont_title">{{ $t('home.cont12.title') }}</div>
        <div class="cont12_list">
          <swiper
            :space-between="50"
            :modules="modules"
            navigation
          >
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new10.webp" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">BlockBeats</p>
                    <a target="_blank" href="https://www.theblockbeats.info/news/56089" class="new_text new_font24">ChatGPT两周年，去中心化AI的机遇何在？深脑链DBC给出了答案</a>
                    <div class="new_date">
                      <p>24-12-08</p>
                    </div>
                  </div>
                </div>
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new9.avif" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">HackerNoon</p>
                    <a target="_blank" href="https://hackernoon.com/the-synergy-of-ai-and-blockchain-how-deepbrain-chain-globally-democratizes-gpu-ai-infrastructure" class="new_text new_font24">The Synergy of AI and Blockchain: How DeepBrain Chain Globally Democratizes GPU AI Infrastructure</a>
                    <div class="new_date">
                      <p>August 13th, 2024</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img1.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Yahoo</p>
                    <a target="_blank" href="https://finance.yahoo.com/news/deepbrain-chain-leads-charge-embracing-163500805.html" class="new_text new_font24">DeepBrain Chain Leads the Charge in Embracing the DePIN Revolution with Rising AI and Web3 Integration Demand</a>
                    <div class="new_date">
                      <p>Sat, Mar 30, 2024</p>
                    </div>
                  </div>
                </div>
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new8.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Chain Catcher</p>
                    <a target="_blank" href="https://www.chaincatcher.com/article/2117966" class="new_text new_font20">AI 与 Web3 在元宇宙风云际会，DePIN 准备好了吗？</a>
                    <div class="new_date">
                      <p>2024年03月21日</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new5.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">InvestorsObserver</p>
                    <a target="_blank" href="https://www.investorsobserver.com/news/crypto-update/should-you-buy-deepbrain-chain-dbc-saturday" class="new_text new_font24">Should You Buy DeepBrain Chain (DBC) Saturday?</a>
                    <div class="new_date">
                      <p>January 27, 2024</p>
                    </div>
                  </div>
                </div>
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new7.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">OvenAdd</p>
                    <a target="_blank" href="https://ovenadd.com/deepbrain-chain-dbc-price-prediction/" class="new_text new_font20">DeepBrain Chain (DBC) Price Prediction 2024, 2025, 2030, 2035 | Will DBC Rise?</a>
                    <div class="new_date">
                      <p>December 2, 2023</p>
                    </div>
                  </div>
                </div>
                
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img1.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Brave NewCoin</p>
                    <a target="_blank" href="https://bravenewcoin.com/insights/the-top-10-ai-cryptocurrencies" class="new_text new_font20">The Top 10 AI Cryptocurrencies: Up 157% in 2023</a>
                    <div class="new_date">
                      <p>21 Sep 2023</p>
                    </div>
                  </div>
                </div>
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new4.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">BlockChainToday</p>
                    <a target="_blank" href="https://blog.naver.com/blockchaintoday/223153153609" class="new_text new_font20">DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현 [출처] DeepBrain Chain, GPU 클라우드 컴퓨팅으로 '클라우드 컴퓨팅 혁신' 실현|작성자 블록체인투데이</a>
                    <div class="new_date">
                      <p>2023. 7. 11</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img1.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Altcoin Buzz</p>
                    <a target="_blank" href="https://www.altcoinbuzz.io/reviews/top-3-most-profitable-ai-coins-according-to-chatgpt/" class="new_text new_font24">Top 3 Most Profitable AI Coins According to ChatGPT</a>
                    <div class="new_date">
                      <p>June 13, 2023</p>
                    </div>
                  </div>
                </div>
                
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new6.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">청년투자자 정현</p>
                    <a target="_blank" href="https://blog.naver.com/zigyt/223059848282" class="new_text new_font16">인공지능 AI 관련코인 AGIX, FET 가격, 전망 확인해봐요</a>
                    <div class="new_date">
                      <p>2023.3.30</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img4.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Binance Square</p>
                    <a target="_blank" href="https://www.binance.com/en/feed/post/255516" class="new_text new_font16">Huawei Cloud Announces Launch of Metaverse and Web3 Alliance</a>
                    <div class="new_date">
                      <p>Feb 27, 2023</p>
                    </div>
                  </div>
                </div>
                
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img5.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Coin Edition</p>
                    <a target="_blank" href="https://coinedition.com/huawei-cloud-and-tencent-cloud-announce-web3-alliances/" class="new_text new_font16">Huawei Cloud and Tencent Cloud Announce Web3 Alliances</a>
                    <div class="new_date">
                      <p>Feb 27, 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img4.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">CoinSpeaker</p>
                    <a target="_blank" href="https://www.coinspeaker.com/huawei-cloud-metaverse-web3-blockchain/" class="new_text new_font16">Huawei Cloud Launches Metaverse and Web3 Initiative to Foster Blockchain Adoption in Asian Region</a>
                    <div class="new_date">
                      <p>Feb 27, 2023</p>
                    </div>
                  </div>
                </div>
                
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/comp3.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">WeiXin</p>
                    <a target="_blank" href="https://mp.weixin.qq.com/s/0qcXdqJGzMMLHgdzYz5z-w" class="new_text new_font16">华为云发起元宇宙 & Web3.0联盟，风口上的 AI 计算平台Deepbrain Chain再次登台亮相</a>
                    <div class="new_date">
                      <p>2023年02月25日</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new2.jpg" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Cryptotvplus</p>
                    <a target="_blank" href="https://cryptotvplus.com/2023/02/all-you-need-to-know-about-deepbrain-chain/" class="new_text new_font16">All you need to know about DeepBrain chain?</a>
                    <div class="new_date">
                      <p>February 6, 2023</p>
                    </div>
                  </div>
                </div>
                
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/new5.webp" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">Yahoo</p>
                    <a target="_blank" href="https://finance.yahoo.com/news/deepbrain-chain-computing-power-mainnet-115100106.html?guccounter=1" class="new_text new_font16">DeepBrain Chain Computing Power Mainnet Launches Online, Meaning All GPU Servers Can Now Freely Connect to the DBC Network, All Information Available On-Chain</a>
                    <div class="new_date">
                      <p>December 17, 2021</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img7.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">AP News</p>
                    <a target="_blank" href="https://apnews.com/press-release/marketersmedia/technology-blockchain-artificial-intelligence-5baa7937549a0f02b1cb0612f9afefa5" class="new_text new_font16">DeepBrain Chain computing power mainnet launches online, meaning all GPU servers can now freely connect to the DBC network</a>
                    <div class="new_date">
                      <p>December 17, 2021</p>
                    </div>
                  </div>
                </div>
                
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img3.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">36KR</p>
                    <a target="_blank" href="https://36kr.com/p/1253772390861320" class="new_text new_font16">一文读懂深脑链DBC：无限扩容的分布式高性能算力网络</a>
                    <div class="new_date">
                      <p>2021-06-04</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="cont12_li">
                <div class="cont12_li_cont">
                  <div class="cont12_li1_block3"><img src="../assets/home/cont12_img6.png" alt=""></div>
                  <div class="cont12_li1_block4">
                    <p class="news_title">BlockBeats</p>
                    <a target="_blank" href="https://www.theblockbeats.info/news/24626?search=1" class="new_text new_font16">高性能计算赛道的深脑链，是Web3.0时代的刚需吗？</a>
                    <div class="new_date">
                      <p>2021-06-01</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="content13">
        <div class="cont_title">{{ $t('home.cont13.title') }}</div>
        <div class="event_list">
          <swiper
            :space-between="50"
            :modules="modules"
            navigation
          >

            <swiper-slide v-for="(el, index) in newEvevtData" :key="index">
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg"><img :src="require(`@/assets/home/${el[0].img_url}`)" alt=""></div>
                  <div class="img_cont" @click="linkHerf(el[0].link)">
                    <div class="img_title">{{ el[0].title }}</div>
                    <div class="img_date">{{ el[0].time }}</div>
                  </div>
                </div>
                <div class="event_li_cont" v-if="el[1]">  
                  <div class="imgbg"><img :src="require(`@/assets/home/${el[1].img_url}`)" alt=""></div>
                  <div class="img_cont" @click="linkHerf(el[1].link)">
                    <div class="img_title">{{ el[1].title }}</div>
                    <div class="img_date">{{ el[1].time }}</div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <!-- <swiper-slide>
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg imgbg1"></div>
                  <div class="img_cont" @click="linkHerf(9)">
                    <div class="img_title">DBC Blockchain Conference Video Review in Phuket, Thailand</div>
                    <div class="img_date">05/02 2024</div>
                  </div>
                </div>
                <div class="event_li_cont">
                  <div class="imgbg imgbg2"></div>
                  <div class="img_cont" @click="linkHerf(8)">
                    <div class="img_title">DeepBrainChain& #DeepLink&TIKEREN cloud gaming all-in-one strategic cooperation conference to jointly enter Japan</div>
                    <div class="img_date">20/12 2023</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg imgbg3"></div>
                  <div class="img_cont" @click="linkHerf(7)">
                    <div class="img_title">Vietnam ESPORTS3.0 EVENT</div>
                    <div class="img_date">07/10 2023</div>
                  </div>
                </div>
                <div class="event_li_cont">
                  <div class="imgbg imgbg4"></div>
                  <div class="img_cont" @click="linkHerf(6)">
                    <div class="img_title">Vietnam MARVELS HCMC DeepBrain Chain</div>
                    <div class="img_date">08/06 2023</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg imgbg5"></div>
                  <div class="img_cont" @click="linkHerf(5)">
                    <div class="img_title">DeepBrain Chain Hong Kong Events</div>
                    <div class="img_date">16/04 2023</div>
                  </div>
                </div>
                <div class="event_li_cont">
                  <div class="imgbg imgbg6"></div>
                  <div class="img_cont" @click="linkHerf(4)">
                    <div class="img_title">DeepBrain Chain Singapore Events</div>
                    <div class="img_date">17/01 2023</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg imgbg7"></div>
                  <div class="img_cont" @click="linkHerf(3)">
                    <div class="img_title">DeepBrain Chain AI meets Blockchain in Stuttgart, Germany</div>
                    <div class="img_date">13/07 2018</div>
                  </div>
                </div>
                <div class="event_li_cont">
                  <div class="imgbg imgbg8"></div>
                  <div class="img_cont" @click="linkHerf(2)">
                    <div class="img_title">DeepBrain Chain participates CCF-GAIR in Shenzhen</div>
                    <div class="img_date">01/07 2018</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="event_li">
                <div class="event_li_cont">
                  <div class="imgbg imgbg9"></div>
                  <div class="img_cont" @click="linkHerf(1)">
                    <div class="img_title">DeepBrain Chain Blockchain Connect Conference in San Francisco</div>
                    <div class="img_date">13/01 2018</div>
                  </div>
                </div>
              </div>
            </swiper-slide> -->
          </swiper>
          <!-- <div class="event_list_count">
            <div class="event_li">
              <div class="event_li_cont">
                <div class="imgbg imgbg1">
                  <div class="img_cont" @click="linkHerf(9)">
                    <div class="img_title">DBC Blockchain Conference Video Review in Phuket, Thailand</div>
                    <div class="img_date">05/02 2024</div>
                  </div>
                </div>
              </div>
              <div class="event_li_cont">
                <div class="imgbg imgbg2">
                  <div class="img_cont" @click="linkHerf(8)">
                    <div class="img_title">DeepBrainChain& #DeepLink&TIKEREN cloud gaming all-in-one strategic cooperation conference to jointly enter Japan</div>
                    <div class="img_date">20/12 2023</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event_li">
              <div class="event_li_cont">
                <div class="imgbg imgbg3">
                  <div class="img_cont" @click="linkHerf(7)">
                    <div class="img_title">Vietnam ESPORTS3.0 EVENT</div>
                    <div class="img_date">07/10 2023</div>
                  </div>
                </div>
              </div>
              <div class="event_li_cont">
                <div class="imgbg imgbg4">
                  <div class="img_cont" @click="linkHerf(6)">
                    <div class="img_title">Vietnam MARVELS HCMC DeepBrain Chain</div>
                    <div class="img_date">08/06 2023</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event_li">
              <div class="event_li_cont">
                <div class="imgbg imgbg5">
                  <div class="img_cont" @click="linkHerf(5)">
                    <div class="img_title">DeepBrain Chain Hong Kong Events</div>
                    <div class="img_date">16/04 2023</div>
                  </div>
                </div>
              </div>
              <div class="event_li_cont">
                <div class="imgbg imgbg6">
                  <div class="img_cont" @click="linkHerf(4)">
                    <div class="img_title">DeepBrain Chain Singapore Events</div>
                    <div class="img_date">17/01 2023</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event_li">
              <div class="event_li_cont">
                <div class="imgbg imgbg7">
                  <div class="img_cont" @click="linkHerf(3)">
                    <div class="img_title">DeepBrain Chain AI meets Blockchain in Stuttgart, Germany</div>
                    <div class="img_date">13/07 2018</div>
                  </div>
                </div>
              </div>
              <div class="event_li_cont">
                <div class="imgbg imgbg8">
                  <div class="img_cont" @click="linkHerf(2)">
                    <div class="img_title">DeepBrain Chain participates CCF-GAIR in Shenzhen</div>
                    <div class="img_date">01/07 2018</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event_li">
              <div class="event_li_cont">
                <div class="imgbg imgbg9">
                  <div class="img_cont" @click="linkHerf(1)">
                    <div class="img_title">DeepBrain Chain Blockchain Connect Conference in San Francisco</div>
                    <div class="img_date">13/01 2018</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount, onBeforeMount } from "vue";
  import { CountTo } from 'vue3-count-to';
  import { getRewardInfo } from '../api/wss'
  import { getHodoler } from '../api/http'
  import { getTotalIssuance, getTotalstak, getTreasury, getTotalNumber } from '../api/query'
  import { useStore } from "vuex"
  // import Swiper, { Navigation, Pagination } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation } from 'swiper/modules';
  import IMG10 from '@/assets/home/cont13_bg10.jpg'
  import 'swiper/css';
  import 'swiper/css/navigation';
  export default defineComponent({
    name: 'Home',
    components: {
      CountTo,
      Swiper,
      SwiperSlide,
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      var Number_inc = ref({
        count1: 0,
        count2: 0,
        count3: 0,
        count4: 0,
        count5: 0,
        count6: 0,
        count7: 0,
        count8: 0
      })
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      const doHandleMonth = (month) => {
        var m = month;
        if (month.toString().length == 1) {
          m = "0" + month;
        }
        return m;
      }
      const getDay = (day) => {
        var today = new Date();
        var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
        today.setTime(targetday_milliseconds); //注意，这行是关键代码
        var tYear = today.getFullYear();
        var tMonth = today.getMonth();
        var tDate = today.getDate();
        tMonth = doHandleMonth(tMonth + 1);
        tDate = doHandleMonth(tDate);
        return tYear + "-" + tMonth + "-" + tDate;
      }
      const totalNum = ref({
        Stake: 0,
        Issuance: 0,
        Stake_ratio: 0,
        Issuance_ratio: 0,
      })
      const rewardInfo = ref(null)
      const Total = ref(null)
      const stake_num = ref(null)
      const account = ref(null)
      const holder = ref(null)
      const getNumber = async () => {
        rewardInfo.value = await getRewardInfo();
        Total.value = await getTotalIssuance()
        stake_num.value = await getTotalstak()
        account.value = await getTreasury()
        const total_Number = await getTotalNumber()
        holder.value = await getHodoler({
          "category": "AccountHolderTotal",
          "end": getDay(0),
          "format": "day",
          "start": getDay(-1)
        })
        
        totalNum.value.Stake = Math.floor( total_Number.Stake_data / Math.pow(10, 15))
        totalNum.value.Issuance = Math.floor( total_Number.Issuance_data / Math.pow(10, 15))
        totalNum.value.Stake_ratio = Number(((totalNum.value.Stake / totalNum.value.Issuance )*100).toFixed(2))
        totalNum.value.Issuance_ratio = Number(((Math.floor(rewardInfo.value.totalStake / Math.pow(10, 15)) / totalNum.value.Issuance )*100).toFixed(2))
        
        Number_inc.value.count1 = rewardInfo.value.totalGpuNum
        Number_inc.value.count2 = Number(((rewardInfo.value.totalRentedGpu / rewardInfo.value.totalGpuNum )*100).toFixed(2))
        Number_inc.value.count3 = Math.floor(rewardInfo.value.totalStake / Math.pow(10, 15))
        Number_inc.value.count4 = Math.floor(rewardInfo.value.totalBurnFee / Math.pow(10, 15))
        Number_inc.value.count5 = (Total.value&&Total.value.replace(/DBC/, "").replace(' ', ""))
        Number_inc.value.count6 = Number(holder.value.data.list ? holder.value.data.list[holder.value.data.list.length-1].total : 0)
        Number_inc.value.count7 = (stake_num.value ? stake_num.value.replace(/DBC/, "").replace(' ', "") : 0)
        Number_inc.value.count8 = (account.value ? account.value.replace(/DBC/, "").replace(' ', "") : 0)
      }
      const seeVideo = (type) => {
        if (type == 1) {
          window.open('/web3.mp4')
        } else {
          window.open('/DBC_Pitch_Deck.pdf')
        }
      }
      const linkHerf = (idx) => {
        let str = ''
        if (idx == 100) {
          str = 'https://galaxyrace.deepbrainchain.org/'
        } else if (idx == 9) {
          str = 'https://twitter.com/DeepBrainChain/status/1754500650648715521?t=-pneye4mhfHfdZGjoZ5zXw&s=19'
        } else if (idx == 8) {
          str = 'https://twitter.com/deepbrainfeng/status/1737496574278725733?t=3HftJUj9ee-3cEkjYnDr1w&s=19'
        } else if (idx == 7) {
          str = 'https://twitter.com/DeepLinkVietNam/status/1710551891061756038'
        } else if (idx == 6) {
          str = 'https://twitter.com/DeepBrainChain/status/1666651735266562052'
        } else if (idx == 5) {
          str = 'https://twitter.com/deepbrainfeng/status/1647529443945226241?s=20'
        } else if (idx == 4) {
          str = 'https://twitter.com/deepbrainfeng/status/1615370872252137473?s=20'
        } else if (idx == 3) {
          str = 'https://twitter.com/onegamerocks/status/1017718325969899522'
        } else if (idx == 2) {
          str = 'https://twitter.com/DeepBrainChain/status/1013332400208670720'
        } else if (idx == 1) {
          str = 'https://twitter.com/coindar_/status/951993629576454144'
        } else {
          str = idx
        }
        window.open(str)
      }


      const ball_show = ref(true)
      const CurrentHeight = ref(0)
      const handleScroll = () => {
        CurrentHeight.value = document.body.scrollTop || document.documentElement.scrollTop
        if (CurrentHeight.value < 700) {
          ball_show.value = true
        } else {
          ball_show.value = false
        }
      }

      const visibilityChange = () => {
        if (document.hidden) {
          ball_show.value = false
        } else {
          if (CurrentHeight.value < 700) {
            ball_show.value = true
          } else {
            ball_show.value = false
          }
        }
      }
      const eventInfo = ref([
        {
          title: "DBC participated in the 1012EXPO Big Navigation Blockchain Conference, a standout event focused on future tech trends and innovative collaboration models.",
          time: '17/10 2024',
          link: 'https://x.com/deepbrainfeng/status/1846490905043521916',
          delay: 200,
          img_url: 'cont13_bg30.jpg'
        },
        {
          title: "DeepBrain Chain at the Singapore Token2049. ",
          time: '18/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1836387111114879067',
          delay: 200,
          img_url: 'cont13_bg29.jpg'
        },
        {
          title: "DeepBrainChain and DeepLinkGlobal Signs Strategic Cooperation Agreement with IPCA to Promote the Application of AI and Blockchain Technology in Korean Internet Cafe Industry",
          time: '10/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1833739141290623405',
          delay: 200,
          img_url: 'cont13_bg28.jpg'
        },
        {
          title: "DeepBrain Chain successfully attended and completed the Web X event in Tokyo, Japan.",
          time: '02/09 2024',
          link: 'https://x.com/DeepBrainChain/status/1830534976254746764',
          delay: 300,
          img_url: 'cont13_bg27.jpg'
        },
        {
          title: "DeepBrain Chain (DBC) successfully hosted the “Future of Decentralized AI” summit at Stanford University.",
          time: '28/07 2024',
          link: 'https://x.com/DeepBrainChain/status/1817793793154011213',
          delay: 300,
          img_url: 'cont13_bg26.jpg'
        },
        // {
        //   title: 'DeepLink’s Cornerstone Investor Mr. Adam Ha, participated in the DEPIN ALLIANCE YACHT PARTY ⛵️panel and introduced DeepLink to the Vietnam market.',
        //   time: '07/06 2024',
        //   link: 'https://x.com/DeepLinkGlobal/status/1799036124150821272',
        //   delay: 100,
        //   img_url: 'cont13_bg25.jpg'
        // },
        {
          title: "We held a meetup in Seoul today with ETNEWS, Korea's top IT news media",
          time: '14/06 2024',
          link: 'https://x.com/DeepBrainChain/status/1801600720757789162',
          delay: 200,
          img_url: 'cont13_bg24.jpg'
        },
        {
          title: 'DeepBrainChain attended the US Consensus Conference. We brought the first AI project',
          time: '01/06 2024',
          link: 'https://x.com/DeepBrainChain/status/1796677581502095490',
          delay: 300,
          img_url: 'cont13_bg23.jpg'
        },
        {
          title: "DBC founders and council members attended the BTC Consensus Conference in Austin",
          time: '30/05 2024',
          link: 'https://x.com/DeepBrainChain/status/1795996265492140187',
          delay: 200,
          img_url: 'cont13_bg22.jpg'
        },
        {
          title: 'DeepBrainChain participated in the 2024 Tokyo Blockchain Expo, one of the largest blockchain events in Japan. This exhibition brought together global elites from the AI and Blockchain industries.',
          time: '24/05 2024',
          link: 'https://x.com/DeepBrainChain/status/1793958508335120434',
          delay: 300,
          img_url: 'cont13_bg21.jpg'
        },
        {
          title: 'Introducing DeepBrainChain to crypto investors from multiple countries around the world at the token2049 conference',
          time: '19/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1781270973737816073',
          delay: 400,
          img_url: 'cont13_bg20.jpg'
        },

        {
          title: 'Arriving at the Dubai venue, the DBC&Deeplink booth is packed with people.',
          time: '18/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1780863389889364358',
          delay: 200,
          img_url: 'cont13_bg19.jpg'
        },
        {
          title: 'TeamZ Web3 Japan Summit ended successfully！',
          time: '14/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1779440009134919731',
          delay: 300,
          img_url: 'cont13_bg18.jpg'
        },
        {
          title: 'The DBC booth was crowded with people and was warmly welcomed by everyone.',
          time: '13/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1779152234875584607',
          delay: 400,
          img_url: 'cont13_bg17.jpg'
        },
        {
          title: 'Felix, a DBC director from Japan, is delivering a speech at the Global DePIN Hardware Conference.',
          time: '08/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1777276310571897256',
          delay: 300,
          img_url: 'cont13_bg16.jpg'
        },
        {
          title: 'DBC Ambassadors attended the DePIN China Tour Shenzhen Meetup',
          time: '05/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1776195577954607110',
          delay: 400,
          img_url: 'cont13_bg15.jpg'
        },
        {
          title: 'DBC Ambassador introduces #DeepBrainChain at Web3 Storage Conference hosted by Swarm #DBC #DeepBrainChain',
          time: '03/04 2024',
          link: 'https://x.com/DeepBrainChain/status/1775470361074868698',
          delay: 500,
          img_url: 'cont13_bg14.jpg'
        },
        {
          title: '🔥DeepBrainChain Meetup event is in progress at Amazon Hangzhou, China, Tongji University, ICP, and Llama China',
          time: '30/03 2024',
          link: 'https://x.com/DeepBrainChain/status/1773992128999473261',
          delay: 300,
          img_url: 'cont13_bg13.jpg'
        },
        {
          title: 'Electronic sports Hotel Group covers 27 cities in China and is a well-known esports hotel brand in China. Starting from this year, we will work together to provide them with DBC’s GPU computing power and DeepLink’s cloud esports ultra-low game rendering latency. solution',
          time: '18/03 2024',
          link: 'https://x.com/deepbrainfeng/status/1769573009768288765',
          delay: 400,
          img_url: 'cont13_bg12.jpg'
        },
        {
          title: 'The AI ​​cloud platform Hycons based on the DBC decentralized GPU network provides AI users with an unparalleled experience, and the GPU price saves more than 90% compared to AWS.',
          time: '07/03 2024',
          link: 'https://x.com/deepbrainfeng/status/1765532462351024216',
          delay: 500,
          img_url: 'cont13_bg11.jpg'
        },

        {
          title: 'Chengdu Internet Cafe Technical Conference in China',
          time: '04/03 2024',
          link: 'https://twitter.com/DeepBrainChain/status/1764676037366231365?t=LUmiOO3PWHX5Hca41pbXRw&s=19',
          delay: 500,
          img_url: 'cont13_bg10.jpg'
        },
        {
          title: 'DBC Blockchain Conference Video Review in Phuket, Thailand',
          time: '05/02 2024',
          link: 'https://twitter.com/DeepBrainChain/status/1754500650648715521?t=-pneye4mhfHfdZGjoZ5zXw&s=19',
          delay: 600,
          img_url: 'cont13_bg9.jpg'
        },
        {
          title: 'DeepBrainChain& #DeepLink&TIKEREN cloud gaming all-in-one strategic cooperation conference to jointly enter Japan',
          time: '20/12 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1737496574278725733?t=3HftJUj9ee-3cEkjYnDr1w&s=19',
          delay: 700,
          img_url: 'cont13_bg8.png'
        },
        {
          title: 'Vietnam ESPORTS3.0 EVENT',
          time: '07/10 2023',
          link: 'https://twitter.com/DeepLinkVietNam/status/1710551891061756038',
          delay: 500,
          img_url: 'cont13_bg7.png'
        },
        {
          title: 'Vietnam MARVELS HCMC DeepBrain Chain',
          time: '08/06 2023',
          link: 'https://twitter.com/DeepBrainChain/status/1666651735266562052',
          delay: 600,
          img_url: 'cont13_bg6.png'
        },
        {
          title: 'DeepBrain Chain Hong Kong Events',
          time: '16/04 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1647529443945226241?s=20',
          delay: 700,
          img_url: 'cont13_bg5.png'
        },
        {
          title: 'DeepBrain Chain Singapore Events',
          time: '17/01 2023',
          link: 'https://twitter.com/deepbrainfeng/status/1615370872252137473?s=20',
          delay: 800,
          img_url: 'cont13_bg4.png'
        },
        {
          title: 'DeepBrain Chain AI meets Blockchain in Stuttgart, Germany',
          time: '13/07 2018',
          link: 'https://twitter.com/onegamerocks/status/1017718325969899522',
          delay: 700,
          img_url: 'cont13_bg3.png'
        },
        {
          title: 'DeepBrain Chain participates CCF-GAIR in Shenzhen',
          time: '01/07 2018',
          link: 'https://twitter.com/DeepBrainChain/status/1013332400208670720',
          delay: 800,
          img_url: 'cont13_bg2.png'
        },
        {
          title: 'DeepBrain Chain Blockchain Connect Conference in San Francisco',
          time: '13/01 2018',
          link: 'https://twitter.com/coindar_/status/951993629576454144',
          delay: 900,
          img_url: 'cont13_bg1.jpg'
        }
      ])
      const processData = (arr, data) => {
        for(var i=0;i<data.length;i+=2){
          arr.push(data.slice(i,i+2));
        }
      }
      const getImg = (url) => {
        return require(`../assets/home/${url}`)
      }
      const newEvevtData = ref([])
      onBeforeMount(() => {
        processData(newEvevtData.value, eventInfo.value)
      })
      onMounted(async () => {
        await getNumber()
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('visibilitychange', visibilityChange)
      });
      onBeforeUnmount(() => {
        ball_show.value = false
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('visibilitychange', visibilityChange)
      });
      return {
        lan,
        seeVideo,
        linkHerf,
        totalNum,
        Number_inc,
        ball_show,
        handleScroll,
        newEvevtData,
        getImg,
        modules: [Navigation],
      };
    }
  })
</script>

<style lang="scss" scoped>
.home {
  width: 390px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  .cont_title {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: 58px;
    text-align: center;
    margin-bottom: 10px;
  }
  .cont_desc {
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .line_ball {
      width: 100%;
      display: flex;
      align-items: center;
      .content1_bg1 {
        // position: absolute;
        // left: 60px;
        // top: 180px;
        width: 280px;
        height: 280px;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
        }
      }
      
      .line {
        width: 12px;
        height: 306px;
        margin: 24px 24px 24px 0;
        background: url(../assets/home/line.png) no-repeat;
        background-size: 12px 306px;
      }
    }
    

    .introduce {
      .text1 {
        color: #FFF;
        font-size: 36px;
        font-weight: 500;
        line-height: normal;
      }
      .text2 {
        font-size: 30px;
      }
    }
    .detailed {
      width: 100%;
      font-size: 14px;
      color: #899AA9;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
  }
  .content2 {
    width: 100%;
    padding: 0 24px;
    background: #000;
    box-sizing: border-box;
    .sliper {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .sliper_img {
        height: 200px;
        margin-bottom: 20px;
        img {
          height: 200px;
        }
      }
      .sliper_text {
        width: 100%;
        color: #FFF;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 10px;
      }
      .sliper_btn {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 20px;
        line-height: normal;
        margin-bottom: 80px;
        background: #41A4FF;
      }
    }
  }

  .content3 {
    width: 100%;
    padding: 48px 24px 0;
    box-sizing: border-box;
    .ecologyList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 48px auto 0;
      justify-content: space-between;
      .ecologyLi {
        width: 342px;
        // height: 270px;
        position: relative;
        margin-bottom: 40px;
        padding: 32px 16px 16px;
        box-sizing: border-box;
        border: 1px solid #FFF;
        transition: all 1s;
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        // &:hover {
        //   border: 1px solid;
        //   border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
        // }
        .ecologyIcon {
          position: absolute;
          width: 48px;
          height: 48px;
          top: -25px;
          left: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ecology_title {
          color: #FFF;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 12px;
          line-height: normal;
        }
        .ecology_text {
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 12px;
          word-wrap: break-word;
        }
        .ecology_btns {
          // position: absolute;
          // bottom: 30px;
          width: 100%;
          height: 43px;
          display: flex;
          color: #FFF;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          align-items: center;
          justify-content: space-between;
          .btn {
            width: 145px;
            height: 43px;
            color: #FFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .btn1 {
            width: 100%;
            height: 43px;
            display: flex;
            color: #FFF;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border: 1px solid #FFF;
            span {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
  }
  .swiper-slide {
    width: 342px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 48px;
    margin: 0 auto;
    overflow: hidden;
    justify-content: space-between;
    .number_block {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 100px;
      }
      &.w390 {
        width: 390px;
      }
      &.w270 {
        // width: 270px;
        width: 100%;
      }
      &.w155 {
        width: 155px;
      }
      &.w320 {
        width: 320px;
      }
      .number {
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &.num1 {
          background-image: linear-gradient(80deg, #9814FF 13.88%, #215DFF 93.31%);
        }
        &.num2 {
          background-image: linear-gradient(77deg, #2053FF 12.51%, #2DC9F8 84.27%);
        }
        &.num3 {
          background-image: linear-gradient(90deg, #30C7FD 0.03%, #00FFA3 108.27%);
        }
        &.num4 {
          background-image: linear-gradient(132deg, #04F9AB -6.93%, #C6FF7D 103.68%);
        }
      }
      .num_desc {
        font-size: 14px;
        color: #C4C4C4;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .ecologyLi {
      width: 340px;
      // height: 270px;
      position: relative;
      margin-bottom: 40px;
      padding: 32px 16px 16px;
      box-sizing: border-box;
      border: 1px solid #FFF;
      transition: all 1s;
      box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
      background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
      // &:hover {
      //   border: 1px solid;
      //   border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      // }
      &:last-child {
        margin-bottom: 80px;
      }
      .ecologyIcon {
        position: absolute;
        width: 48px;
        height: 48px;
        top: -25px;
        left: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ecology_title {
        color: #FFF;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 12px;
        line-height: normal;
      }
      .ecology_text {
        font-size: 14px;
        color: #899AA9;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 12px;
        word-wrap: break-word;
      }
      .ecology_btns {
        // position: absolute;
        // bottom: 30px;
        width: 100%;
        height: 43px;
        display: flex;
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        align-items: center;
        justify-content: space-between;
        .btn {
          width: 145px;
          height: 43px;
          color: #FFF;
          display: flex;
          cursor: pointer;
          align-items: center;
          text-decoration: none;
          justify-content: center;
          border: 1px solid #FFF;
          span {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .btn1 {
          width: 100%;
          height: 43px;
          display: flex;
          color: #FFF;
          cursor: pointer;
          align-items: center;
          text-decoration: none;
          justify-content: center;
          border: 1px solid #FFF;
          span {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
    .cont12_li {
      width: 340px;
      .cont12_li_cont {
        width: 100%;
        padding: 16px;
        margin-bottom: 16px;
        box-sizing: border-box;
        background: rgba(65, 164, 255, 0.16);
        &:last-child {
          margin-bottom: 80px;
        }
        
        .cont12_li1_block3 {
          width: 100%;
          height: 140px;
          display: flex; /* 设置为flex布局 */
          justify-content: center; /* 水平居中对齐 */
          align-items: center; /* 垂直居中对齐 */
          overflow: hidden;
          img {
            width: 100%;
            height: 140px;
            object-fit: cover;
          }
        }
        .cont12_li1_block4 {
          margin-top: 8px;
          width: 100%;
          height: 110px;
          .new_text {
            overflow: hidden; /* 超出部分将被隐藏 */
            display: -webkit-box; /* 必需的WebKit前缀 */
            -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
            -webkit-box-orient: vertical; /* 垂直布局 */
          }
          a {
            height: 60px;
          }
        }
        .news_title {
          font-size: 14px;
          font-weight: 500;
          color: #41A4FF;
          line-height: normal;
          letter-spacing: 0.28px;
        }
        .new_text {
          color: #fff;
          margin: 8px 0;
          display: block;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          transition: all 2s;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
        .new_color1 {
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
        }
        .new_date {
          display: flex;
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          align-items: center;
          line-height: normal;
          letter-spacing: 0.32px;
          justify-content: space-between;
          p {
            display: flex;
            align-items: center;
            .new_icon {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url(../assets/home/good.png) no-repeat 100%/100%;
            }
          }
        }
      }
    }
    .event_li {
      width: 340px;
      // display: flex;
      // flex-wrap: wrap;
      // margin-right: 24px;
      .event_li_cont {
        width: 100%;
        // height: 240px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 100px;
        }
        .imgbg {
          position: relative;
          width: 100%;
          height: 240px;
          padding: 8px;
          display: flex;
          overflow: hidden;
          border: 1px solid;
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          img {
            width: 100%;
            height: 224px;
            object-fit: cover;
          }
        }
        
        .img_cont {
          width: 100%;
          display: flex;
          padding: 16px;
          color: #fff;
          cursor: pointer;
          box-sizing: border-box;
          flex-direction: column;
          .img_title {
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
            letter-spacing: 0.4px;
            overflow: hidden; /* 超出部分将被隐藏 */
            display: -webkit-box; /* 必需的WebKit前缀 */
            -webkit-line-clamp: 2; /* 限制显示的行数为2行 */
            -webkit-box-orient: vertical; 
          }
          .img_date {
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.32px;
          }
        }
      }
    }
  }
  .unifycontent {
    width: 100%;
    overflow: hidden;
    position: relative;
    .bg1 {
      position: absolute;
      top: 100px;
      margin: auto;
      width: 1920px;
      height: 1690px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1920px;
        height: 1690px;
      }
    }
    .bg2 {
      position: absolute;
      top: 2000px;
      margin: auto;
      width: 1200px;
      height: 1200px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1200px;
        height: 1200px;
      }
    }
    .bg3 {
      position: absolute;
      top: 3450px;
      margin: auto;
      width: 1603px;
      height: 1348px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1603px;
        height: 1348px;
      }
    }
    .bg4 {
      position: absolute;
      top: 5900px;
      z-index: 1;
      margin: auto;
      width: 1603px;
      height: 1348px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1603px;
        height: 1348px;
      }
    }
    .bg5 {
      position: absolute;
      top: 6560px;
      margin: auto;
      width: 1920px;
      height: 1690px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1920px;
        height: 1690px;
      }
    }
    .content4 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: auto;
      padding: 48px 24px 0;
      box-sizing: border-box;
      .numberList {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .number_block {
          margin-bottom: 60px;
          &.w390 {
            width: 390px;
          }
          &.w270 {
            // width: 270px;
            width: 100%;
          }
          &.w155 {
            width: 155px;
          }
          &.w320 {
            width: 320px;
          }
          .number {
            font-size: 40px;
            font-weight: 500;
            line-height: 50px;
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &.num1 {
              background-image: linear-gradient(80deg, #9814FF 13.88%, #215DFF 93.31%);
            }
            &.num2 {
              background-image: linear-gradient(77deg, #2053FF 12.51%, #2DC9F8 84.27%);
            }
            &.num3 {
              background-image: linear-gradient(90deg, #30C7FD 0.03%, #00FFA3 108.27%);
            }
            &.num4 {
              background-image: linear-gradient(132deg, #04F9AB -6.93%, #C6FF7D 103.68%);
            }
          }
          .num_desc {
            font-size: 14px;
            color: #C4C4C4;
            font-weight: 400;
            line-height: 20px;
          }
        }
        
      }
    }
    .content5 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .list {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          .listInfo {
            width: 100%;
            padding: 16px;
            margin-top: 16px;
            box-sizing: border-box;
            backdrop-filter: blur(2px);
            background: rgba(0, 0, 0, 0.24);
            .text1 {
              display: flex;
              color: #fff;
              font-size: 18px;
              font-weight: 500;
              line-height: 32px;
              align-items: center;
              margin-bottom: 10px;
              .line {
                width: 2px;
                height: 28px;
                margin-right: 15px;
                &.color3 {
                  background: #9945FF;
                }
                &.color2 {
                  background: #2059FE;
                }
                &.color1 {
                  background: #16C8FF;
                }
                &.color6 {
                  background: #FF5D39;
                }
                &.color5 {
                  background: #FFD512;
                }
                &.color4 {
                  background: #19FB9B;
                }
              }
            }
            .text2 {
              font-size: 14px;
              color: #899AA9;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
      }
    }
    .content6 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .token_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        justify-content: space-between;
        .token_li {
          width: 100%;
          .token_block {
            padding: 16px;
            display: flex;
            margin-bottom: 16px;
            flex-direction: column;
            box-sizing: border-box;
            background: #10132F;
            position: relative;
            border-style: solid;
            border-bottom-width: 1px;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
            .token_text {
              display: flex;
              font-size: 14px;
              color: #899AA9;
              font-weight: 400;
              line-height: 20px;
              margin-bottom: 8px;
              justify-content: space-between;
              &:last-child {
                margin-bottom: 0;
              }
              .icon {
                width: 8px;
                height: 8px;
                margin-top: 5px;
                margin-right: 8px;
                background: url(../assets/home/cont5_icon.png) 100%/100% no-repeat;
              }
              p {
                flex: 1;
              }
            }
            .token_icon1 {
              width: 200px;
              height: 200px;
              background: url(../assets/home/cont5_img1.png) 100%/100% no-repeat;
            }
            .token_icon2 {
              width: 141px;
              height: 140px;
              background: url(../assets/home/cont5_img2.png) 100%/100% no-repeat;
            }
          }
        }
      }
    }
    .content7 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .exchange {
        width: 100%;
        display: flex;
        margin: 32px 0 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        img {
          height: 45px;
          margin: 0 24px 24px;
          cursor: pointer;
        }
        img.mexc {
          height: 30px;
        }
      }
      .contract_add {
        font-size: 14px;
        font-weight: 400;
        color: #899AA9;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 8px;
      }
      .contract_address {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        color: transparent;
        text-align: center;
        letter-spacing: 1px;
        background-clip: text;
        word-wrap: break-word;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
      }
    }
    .content8 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .cont_table {
        width: 100%;
        padding: 8px;
        margin-top: 40px;
        overflow: auto;
        box-sizing: border-box;
        background: rgba(31, 77, 241, 0.04);
        border: 1px solid rgba(255, 255, 255, 0.08);
        .table_cont {
          width: 700px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          line-height: 18px;
          border-collapse: collapse;
          tr.theader {
            background: rgba(0, 194, 255, 0.16);
            th {
              border: none;
              padding: 8px;
            }
          }
          th {
            padding: 0 8px;
          }
          td {
            padding: 17px 8px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
          .font16 {
            font-size: 12px;
            font-weight: 400;
            color: #899AA9;
            line-height: 150%;
          }
          .color1 {
            color: #38EED6;
          }
          .color2 {
            color: #41A4FF;
          }
          .width60 {
            width: 60px;
            box-sizing: border-box;
          }
          .width210 {
            width: 210px;
          }
          .width240 {
            width: 240px;
          }
        }
      }
    }
    .content9 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .text1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        letter-spacing: 1px;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
      }
      .partner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 14px;
        align-items: center;
        justify-content: space-around;
        img {
          height: 50px;
          cursor: pointer;
          margin: 0 10px 24px;
        }
      }
    }
    .content10 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .timeLine {
        width: 100%;
        // height: 456px;
        margin: 14px 0 0;
        overflow: hidden;
        .time_cont {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .time_content {
            flex: 1;
            display: flex;
            flex-direction: column;
            .time_cont_title {
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              margin-top: 12px;
            }
            .time_cont_text {
              font-size: 14px;
              margin-top: 8px;
              font-weight: 500;
              color: rgba(255, 255, 255, .7);
              a {
                color: #fff;
              }
            }
          }
          .time_num {
            width: 40px;
            display: flex;
            margin-right: 12px;
            align-items: center;
            flex-direction: column;
            p {
              color: rgba(255, 255, 255, 0.24);
              font-size: 32px;
              font-weight: 900;
              line-height: 48px; 
            }
            .time_line {
              flex: 1;
              width: 2px;
              margin-top: 5px;
              // height: -webkit-fill-available;
              background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
            }
          }
        }
      }
      
    }
    .content11 {
      position: relative;
      width: 100%;
      height: 430px;
      .contbg {
        position: absolute;
        margin: auto;
        width: 1900px;
        height: 430px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 1900px;
          height: 430px;
        }
      }
    }
    .content12 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .cont12_list {
        width: 100%;
        overflow: auto;
        margin-top: 14px;
        .cont12_list_cont {
          width: 850%;
          height: 610px;
          display: flex;
          overflow: auto;
          margin-top: 14px;
          justify-content: space-between;
          .cont12_li {
            width: 342px;
            .cont12_li_cont {
              width: 100%;
              padding: 16px;
              margin-bottom: 16px;
              box-sizing: border-box;
              background: rgba(65, 164, 255, 0.16);
              &:last-child {
                margin-bottom: 0;
              }
              
              .cont12_li1_block3 {
                width: 100%;
                height: 140px;
                display: flex; /* 设置为flex布局 */
                justify-content: center; /* 水平居中对齐 */
                align-items: center; /* 垂直居中对齐 */
                overflow: hidden;
                img {
                  width: 100%;
                  height: 140px;
                  object-fit: cover;
                }
              }
              .cont12_li1_block4 {
                margin-top: 8px;
                width: 100%;
                height: 110px;
                .new_text {
                  overflow: hidden; /* 超出部分将被隐藏 */
                  display: -webkit-box; /* 必需的WebKit前缀 */
                  -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
                  -webkit-box-orient: vertical; /* 垂直布局 */
                }
                a {
                  height: 60px;
                }
              }
            }
          }
          .news_title {
            font-size: 14px;
            font-weight: 500;
            color: #41A4FF;
            line-height: normal;
            letter-spacing: 0.28px;
          }
          .new_text {
            color: #fff;
            margin: 8px 0;
            display: block;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            transition: all 2s;
            text-decoration: none;
            cursor: pointer;
            &:hover {
              color: transparent;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
            }
          }
          .new_color1 {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
          }
          .new_date {
            display: flex;
            font-size: 14px;
            color: #899AA9;
            font-weight: 400;
            align-items: center;
            line-height: normal;
            letter-spacing: 0.32px;
            justify-content: space-between;
            p {
              display: flex;
              align-items: center;
              .new_icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url(../assets/home/good.png) no-repeat 100%/100%;
              }
            }
          }
        }
      }
    }
    .content13 {
      position: relative;
      z-index: 1;
      width: 390px;
      margin: 0 auto;
      padding: 48px 24px;
      box-sizing: border-box;
      .event_list {
        width: 100%;
        // display: flex;
        overflow: auto;
        // margin-top: 60px;
        justify-content: space-between;
        .event_list_count {
          width: 1900px;
          display: flex;

        }
      }
    }
  }
}
.lan_En.home {
  .content2 {
    .sliper {
      .sliper_text {
        font-size: 16px;
      }
      .sliper_btn {
        font-size: 14px;
      }
    }
  }
}

.lan_Ru.home {
  .cont_title {
    font-size: 30px;
    line-height: 1;
  }
  .cont_desc {
    line-height: 18px; 
  }
  .content2 {
    .sliper {
      .sliper_text {
        font-size: 14px;
      }
      .sliper_btn {
        font-size: 10px;
      }
    }
  }
  .content1 {
    .introduce {
      .text1 {
        font-size: 30px;
      }
      .text2 {
        font-size: 30px;
      }
    }
  }
}
</style>

<style>
.swiper-button-prev, .swiper-button-next {
  top: unset;
  bottom: 20px;
}
.swiper-button-prev {
  width: 32px;
  height: 32px;
  left: 110px;
  border-radius: 100%;
  border: 1px solid #fff;
}
.swiper-button-prev:after {
  color: #fff;
  font-size: 20px;
}

.swiper-button-next {
  width: 32px;
  height: 32px;
  right: 110px;
  border-radius: 100%;
  border: 1px solid #fff;
}
.swiper-button-next:after {
  color: #fff;
  font-size: 20px;
}
</style>
