<template>
  <div class="getReward" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('getReward.title') }}</div>
      <div class="cont_desc">{{ $t('getReward.text') }}</div>
      <a target="_blank" href="https://www.dbcwallet.io/#/staking" class="cont_btn">{{ $t('getReward.seedetails') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('getReward.cont.title') }}</p>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure1.title1') }}</p>
        <div class="procedure_list1">
          <div class="procedure_li"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure1.text1') }}</p></div>
          <div class="procedure_li"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure1.text2') }}</p></div>
          <div class="procedure_li"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure1.text3') }}</p></div>
        </div>
      </div>
      <div class="procedure_wiki"> 
        <p class="procedure_text1">{{ $t('getReward.procedure2.title1') }}</p>
        <p class="procedure_text2">{{ $t('getReward.procedure2.text1') }} <a class="procedure_text3" target="_blank" href="https://deepbrainchain.github.io/DBC-Wiki/staking-model/generate-new-account.html">{{ $t('getReward.procedure2.text2') }}</a></p>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure3.title1') }}</p>
        <p class="procedure_text4">{{ $t('getReward.procedure3.text1') }}</p>
        <p class="procedure_text2">{{ $t('getReward.procedure3.text2') }}</p>
        <div class="procedure_code">
          <p>mkdir dbc-chain-mainnet && cd dbc-chain-mainnet</p>
          <p>wget https://github.com/DeepBrainChain/DeepBrainChain-MainChain/releases/download/v2.3/dbc_chain_linux_x64.tar.gz -O dbc_chain_linux_x64.tar.gz</p>
          <p>tar xf dbc_chain_linux_x64.tar.gz</p>
        </div>
        <p class="procedure_text4">{{ $t('getReward.procedure3.text3') }}</p>
        <div class="procedure_code">
          <p>{{ $t('getReward.procedure3.text4') }}</p>
          <p>curl https://getsubstrate.io -sSf | bash -s -- --fast</p>
          <p>source ~/.cargo/env</p>
          <p>{{ $t('getReward.procedure3.text5') }}</p>
          <p>git clone https://github.com/DeepBrainChain/DeepBrainChain-MainChain.git</p>
          <p>cd DeepBrainChain-MainChain && git checkout v2.3</p>
          <p>cargo build --release</p>
        </div>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure4.title1') }}</p>
        <div class="procedure_code">
          <p>./dbc-chain --base-path ./db_data --pruning archive</p>
        </div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure4.text1') }} ./target/release/dbc-chain</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure4.text2') }}</p></div>
        <img class="procedure_img" src="../../assets/wiki/wiki_img1.png" alt="">
        <p class="procedure_text4">{{ $t('getReward.procedure4.text3') }}</p>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure4.text4') }}</p>
        </div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure4.text5') }}</p>
        </div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure4.text6') }}</p>
        </div>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure5.title1') }}</p>
        <p class="procedure_text2">{{ $t('getReward.procedure5.text1') }}</p>
        <div class="procedure_code">
          <p>nohup ./dbc-chain --base-path ./db_data --validator --name YourNodeName 1>dbc_node.log 2>&1 &</p>
        </div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure5.text2') }}./target/release/dbc-chain</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure5.text3') }}</p></div>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure5.text4') }}</p>
        <p class="procedure_text2">{{ $t('getReward.procedure5.text5') }}</p>
        <div class="procedure_code">
          <p>curl -H "Content-Type: application/json" -d '{"id":1, "jsonrpc":"2.0", "method": "author_rotateKeys", "params":[]}' http://localhost:9933</p>
        </div>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('getReward.procedure6.title1') }}</p>
        <p class="procedure_text2">{{ $t('getReward.procedure6.text1') }} <a class="procedure_text3" target="_blank" href="https://www.dbcwallet.io/?rpc=wss://info.dbcwallet.io#/accounts">https://www.dbcwallet.io/?rpc=wss://info.dbcwallet.io#/accounts</a> {{ $t('getReward.procedure6.text2') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'" src="../../assets/wiki/POS-1.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/POS-1-en.png" alt="">
        <p class="procedure_text4">{{ $t('getReward.procedure6.text3') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'" src="../../assets/wiki/POS-2.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/POS-2-en.png" alt="">
        <p class="procedure_text4">{{ $t('getReward.procedure6.text4') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'" src="../../assets/wiki/POS-3.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/wiki/POS-3-en.png" alt="">
        <p class="procedure_text4">{{ $t('getReward.procedure6.text5') }}</p>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text6') }}</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text7') }}</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text8') }}</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text9') }}</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text10') }}</p></div>
        <div class="procedure_list2"><span class="procedure_li_icon"></span><p>{{ $t('getReward.procedure6.text11') }}</p></div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'getReward',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.getReward {
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px;
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 20px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
    .cont2_text2 {
      font-size: 14px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
  }
}

</style>
