<template>
  <div class="applyForFunding" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('applyForFunding.title') }}</div>
      <div class="cont_desc">{{ $t('applyForFunding.text1') }}</div>
      <!-- <div class="cont_btn">{{ $t('applyForFunding.text2') }}</div> -->
      <a target="_blank" class="cont_btn" href="https://t.me/dbccouncil">{{ $t('applyForFunding.text2') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('applyForFunding.cont.title') }}</p>
      <p class="cont2_text2">{{ $t('applyForFunding.cont.text') }}</p>
      <div class="procedure_wiki">
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text') }} <a class="procedure_text3" target="_blank" href="https://bit.ly/3FJ4gwo">https://bit.ly/3FJ4gwo</a></p>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text2') }} <a class="procedure_text3" target="_blank" href="https://shimo.im/docs/R6P99TpPWW8wC3yPopen">https://shimo.im/docs/R6P99TpPWW8wC3yPopen</a></p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3') }} <a class="procedure_text3" target="_blank" href="">https://shimo.im/docs/lfwA369loYQhDprN/open</a></p>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4') }}</p>
        <img class="procedure_img" src="../../assets/help/apply_img1.png" alt="">
      </div>

      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title3') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_3') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'" src="../../assets/help/apply_img2.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/help/apply_img2_en.png" alt="">
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_4') }}</p>
        <img class="procedure_img" src="../../assets/help/apply_img3.png" alt="">
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title4') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_3') }}</p>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title5') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text5_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text5_2') }}</p>
      </div>
      <div class="procedure_wiki">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title6') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_3') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'applyForFunding',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.applyForFunding {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 20px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      background: #41A4FF;
      &:hover {
        background: #0989FF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 24px;
      text-align: left;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

</style>
