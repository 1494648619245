<template>
  <div class="gpuMining" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="title">{{ $t('gpuMining.title') }}</div>
      <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div>
      <div class="pagecont">
        <div class="cont_left">
          <div class="list">
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="list_num">1</div>
              <div class="list_cont">
                <div class="cont_desc">{{ $t('gpuMining.text1') }} <a class="nav1" href="https://www.decentralgpt.org/" target="_blank">www.decentralgpt.org</a></div>
              </div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="list_num">2</div>
              <div class="list_cont">
                <div class="cont_desc">{{ $t('gpuMining.text2') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_right delay100 animateHide" v-animate="{delay: 100, class:'amplify'}">
          <img class="contimg" src="../../assets/aipro/ai_img6.png" alt="">
          <!-- <div class="text text1">{{ $t('gpuMining.img_text1') }}</div>
          <div class="text text2">{{ $t('gpuMining.img_text2') }}</div>
          <div class="text text3">{{ $t('gpuMining.img_text3') }}</div>
          <div class="text text4">{{ $t('gpuMining.img_text4') }}</div>
          <div class="text text5">{{ $t('gpuMining.img_text5') }}</div>
          <div class="text text6">{{ $t('gpuMining.img_text6') }}</div>
          <div class="text text7">{{ $t('gpuMining.img_text7') }}</div>
          <div class="text text8">{{ $t('gpuMining.img_text8') }}</div> -->
        </div>
      </div>
    </div>
    <div class="pagecont2">
      <div class="pagecont">
        <div class="cont_left delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="list">
            <div class="listInfo">
              <div class="list_num">3</div>
              <div class="list_cont">
                <div class="cont_desc mb20">{{ $t('gpuMining.text3_1') }} <span class="text1">{{ $t('gpuMining.text3_2') }}</span> </div>
                <div class="cont_desc">{{ $t('gpuMining.text3_3') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_right">
          <div class="list">
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('gpuMining.text4') }}</div>
              <div class="text2 cont_desc">{{ $t('gpuMining.text4_1') }}</div>
              <div class="text2 cont_desc">{{ $t('gpuMining.text4_2') }}</div>
              <div class="text2 cont_desc">{{ $t('gpuMining.text4_3') }}</div>
            </div>
            <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <div class="text1 cont_title">{{ $t('gpuMining.text5') }}</div>
              <div class="text2 cont_desc">{{ $t('gpuMining.text5_1') }}</div>
              <div class="text2 cont_desc">{{ $t('gpuMining.text5_2') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'gpuMining',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.gpuMining {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .6);
  }
  .pagecont1 {
    position: relative;
    width: 90%;
    margin: auto;
    padding: 80px 0 0;
    box-sizing: border-box;    
    .bg1 {
      position: absolute;
      top: -200px;
      left: -100px;
      margin: auto;
      width: 700px;
      height: 700px;
      img {
        width: 700px;
        height: 700px;
      }
    }
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: auto;
      color: transparent;
      margin: 0 auto 40px;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
    }
    .pagecont {
      text-align: left;
      .cont_left {
        width: 100%;
        .list {
          width: 100%;
          .listInfo {
            position: relative;
            width: 100%;
            z-index: 2;
            padding: 14px 0 18px 18px;
            margin-bottom: 40px;
            box-sizing: border-box;
            .list_num {
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              width: 52px;
              height: 44px;
              font-size: 24px;
              font-weight: 600;
              line-height: 44px;
              text-align: center;
              border-radius: 10px;
              background: linear-gradient(315deg, #03FFF0 0%, #6409F7 100%);
            }
            .list_cont {
              padding: 30px;
              height: 100%;
              border-radius: 20px;
              box-sizing: border-box;
              background: rgba(255, 255, 255, .05);
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .mb20 {
            margin-bottom: 20px;
          }
          .ml20 {
            margin-left: 20px;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
        }
      }
      .cont_right {
        position: relative;
        width: 100%;
        display: flex;
        margin: 0 auto 40px;
        flex-direction: column;
        .contimg {
          position: relative;
          z-index: 2;
          width: 100%;
          img {
            width: 100%;
          }
        }
        .text {
          color: #fff;
          display: flex;
          padding: 5px;
          font-size: 16px;
          line-height: 24px;
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
        }
        .text1{ 
          position: absolute;
          top: 92px;
          left: 280px;
        }
        .text2 {
          position: absolute;
          width: 110px; 
          height: 52px;
          top: 160px;
          left: 48px;
        }
        .text3 {
          position: absolute;
          top: 160px;
          left: 180px;
          width: 240px;
          height: 52px;
        }
        .text4 {
          position: absolute;
          left: 28px;
          top: 220px;
          width: 400px;
        }
        .text5 {
          position: absolute;
          left: 405px;
          top: 16px;
          width: 210px;
        }
        .text6 {
          position: absolute;
          left: 435px;
          top: 260px;
          width: 210px;
        }
        .text7 {
          position: absolute;
          right: 0;
          top: 120px;
          width: 210px;
        }
        .text8 {
          position: absolute;
          right: 100px;
          top: 233px;
          width: 188px;
          height: 76px;
        }
      }
    }
    
  }
  .pagecont2 {
    position: relative;
    width: 90%;
    margin: auto;
    padding: 0 0 100px;
    box-sizing: border-box;    
    .pagecont {
      text-align: left;
      .cont_left {
        width: 100%;
        display: flex;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            position: relative;
            width: 100%;
            margin-bottom: 40px;
            box-sizing: border-box;
            padding: 14px 0 18px 18px;
            .list_num {
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              width: 52px;
              height: 44px;
              font-size: 24px;
              font-weight: 600;
              line-height: 44px;
              text-align: center;
              border-radius: 10px;
              background: linear-gradient(315deg, #03FFF0 0%, #6409F7 100%);
            }
            .list_cont {
              padding: 30px;
              border-radius: 20px;
              box-sizing: border-box;
              background: rgba(255, 255, 255, .05);
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .mb20 {
            margin-bottom: 20px;
          }
          .ml20 {
            margin-left: 20px;
          }
          .text1 {
            color: #9DFFD4;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
        }
      }
      .cont_right {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          margin-top: 15px;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            width: 100%;
            padding-left: 16px;
            margin-bottom: 40px;
            box-sizing: border-box;
            backdrop-filter: blur(2px);
            border-left: 1px solid #0EF0FF;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    
  }
}
</style>
