
<template>
  <div class="introDBC" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('introDBC.title') }}</div>
      <div class="cont_desc">{{ $t('introDBC.text1') }}</div>
      <div class="cont_desc">{{ $t('introDBC.text2') }}</div>
    </div>
    <div class="content2">
      <div class="council delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="council_title">{{ $t('introDBC.cont2.block1.title') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text1') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text2') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text3') }}</div>
      </div>
      <div class="council delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
        <div class="council_title">{{ $t('introDBC.cont2.block2.title') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text1') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text2') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text3') }}</div>
        <!-- <div class="council_btn">{{ $t('introDBC.cont2.block2.btn') }}</div> -->
        <a class="council_btn" target="_blank" href="https://www.dbcwallet.io/#/council">{{ $t('introDBC.cont2.block2.btn') }}</a>
      </div>
    </div>
    <div class="content3">
      <div class="cont3_text1">{{ $t('introDBC.cont3.title') }}</div>
      <div class="useList">
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img1.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text1') }}<br/>{{ $t('introDBC.cont3.text1_2') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img2.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text2') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text2_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img3.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text3') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text3_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img4.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text4') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text4_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img5.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text5') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text5_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img6.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text6') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text6_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img7.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text7') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img8.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text8') }}</p>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="cont4_text1">{{ $t('introDBC.cont4.title') }}</div>
      <div class="memberList">
        <div class="member" v-for="(el, index) in top3Array">
          <div class="member_img"><img :src="el.img" alt=""></div>
          <div class="member_name gradient_text">{{ el.member_name }}</div>
          <div class="member_job">{{ $t('introDBC.cont4.job') }}</div>
          <div class="member_num">{{ $t('introDBC.cont4.reward') }} <br/> {{ el.stake_amount }}</div>
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="cont5_text1">{{ $t('introDBC.cont5.title') }} {{ countDown.days }} {{ $t('introDBC.cont5.text11') }} {{ countDown.hours }} {{ $t('introDBC.cont5.text7') }}</div>
      <div class="electList">
        <div class="electLi" v-for="(el, index) in proposalData" :key="index" @click="linkHerf(el.link)">
          <div class="vote_num">
            <p class="vote">{{ $t('introDBC.cont5.text1') }}{{el.id}}</p>
            <div class="time" :class="{active: el.status == 'success', active2: el.status == 'fail'}">{{ el.status == 'success' ? $t('introDBC.cont5.text3') : (el.status == 'fail' ? $t('introDBC.cont5.text3_1') : $t('introDBC.cont5.text2')) }}</div>
          </div>
          <p class="elect_title gradient_text">{{ $t('introDBC.cont5.text4') }} <br> {{el.price.toLocaleString()}} DBC</p>
          <p class="elect_text1">{{ $t('introDBC.cont5.text5') }}<span>{{el.name}}</span></p>
          <p class="elect_text2">{{el.content}}</p>
          <!-- <p class="elect_text3 gradient_text">{{ $t('introDBC.cont5.text6') }}xxx{{ $t('introDBC.cont5.text7') }}</p> -->
          <div class="praise">
            <div class="favour"><div class="praise_icon"><img src="../../assets/learn/good.png" alt=""></div>{{ $t('introDBC.cont5.text9') }}{{el.agree}}</div>
            <div class="against"><div class="praise_icon"><img src="../../assets/learn/bad.png" alt=""></div>{{ $t('introDBC.cont5.text10') }}{{el.oppose}}</div>
          </div>
        </div>
      </div>
      <a target="_blank" class="cont_btn" href="https://www.reddit.com/r/DBC_Council/">{{ $t('introDBC.seemore') }}</a>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  import { getMembers, getMemberName, getBlockNumber } from '../../api/query'
  import Member1 from '../../assets/learn/member_img1.svg'
  import Member2 from '../../assets/learn/member_img2.svg'
  import Member3 from '../../assets/learn/member_img3.svg'
  export default defineComponent({
    name: 'introDBC',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      // watch(lan, (newvlaue, oldvalue) => {
        
      // })
      const proposalData = ref([
        {
          id: 133,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 1500000,
          content: 'TG海外群管理第四季度奖励',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1gkrk88/tg%E6%B5%B7%E5%A4%96%E7%BE%A4%E7%AE%A1%E7%90%86%E7%AC%AC%E5%9B%9B%E5%AD%A3%E5%BA%A6%E5%A5%96%E5%8A%B1motion215/'
        },
        {
          id: 132,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 0.1,
          content: '为推动DeepBrainChain (DBC) 在全球范围内的发展和应用，设立DBC各大区执行委员会，包含亚洲执行委员会、美洲执行委员会和欧洲执行委员会。执行委员会成员为全职工作非兼职。这些执行委员会将独立运行，互不隶属，并独立于DBC基金会和理事会。实际执行过程中，考虑到成本，采用逐步执行的方式，先选举产生其中一个得票最高的执行委员会主席，然后逐步优化方案。',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1get2cr/deepbrainchain_dbc_%E6%89%A7%E8%A1%8C%E5%A7%94%E5%91%98%E4%BC%9A%E6%8F%90%E6%A1%88motion212/'
        },
        {
          id: 131,
          name: 'DBC-KOREA',
          wallet: "5FHJYkMKxr87G3r914ME1DEE1mxpHeVzQ3j8pd1sy4zSFJSP",
          price: 16750000,
          content: '深脑链交易所维护与流动性管理经费申请（2个月） Funding Request for DeepBrain Chain Exchange Maintenance and Liquidity Management (2-Month Period)',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1ge3kd4/proposal_id_131_motions_211_%E6%B7%B1%E8%84%91%E9%93%BE%E4%BA%A4%E6%98%93%E6%89%80%E7%BB%B4%E6%8A%A4%E4%B8%8E%E6%B5%81%E5%8A%A8%E6%80%A7%E7%AE%A1%E7%90%86%E7%BB%8F%E8%B4%B9%E7%94%B3%E8%AF%B72%E4%B8%AA%E6%9C%88/'
        },
        {
          id: 130,
          name: 'xingxing',
          wallet: "5H1XTgaGkf6CtG2P9eqFSErDD2rMWksicrMQYEf6g7VHunGh",
          price: 1000000,
          content: '金色财经一年的宣发经费',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1gh3giq/motion210%E9%87%91%E8%89%B2%E8%B4%A2%E7%BB%8F%E4%B8%80%E5%B9%B4%E7%9A%84%E5%AE%A3%E5%8F%91%E7%BB%8F%E8%B4%B9/'
        },
        {
          id: 129,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 3450000,
          content: 'DBC升级AI公链安全性测试经费',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1gdwcwa/dbc%E5%8D%87%E7%BA%A7ai%E5%85%AC%E9%93%BE%E5%AE%89%E5%85%A8%E6%80%A7%E6%B5%8B%E8%AF%95%E7%BB%8F%E8%B4%B9motion209/'
        },
        {
          id: 128,
          name: 'Louis',
          wallet: "5G7MMgnbocnKPhoqx1PioCWN16gpSUHxF6CuGqYW6dpFacZh",
          price: 6500000,
          content: 'Proposal on Adjusting the Council Voting Rules',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 10,
          oppose: 1,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1g3iid2/proposal_on_adjusting_the_council_voting_rules/'
        },
        {
          id: 127,
          name: 'Louis',
          wallet: "5G7MMgnbocnKPhoqx1PioCWN16gpSUHxF6CuGqYW6dpFacZh",
          price: 1,
          content: 'Proposal on Adjusting the Council Voting Rules',
          endTime: '',
          status: 'fail', //success fail afoot
          agree: 1,
          oppose: 1,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1g3irnf/proposal_127_motion_207_proposal_on_adjusting_the/'
        },
        {
          id: 126,
          name: 'DBCKAYSONT',
          wallet: "5E27t35pq8CvJcNAjMZ2VTaNHZS8v36voSUcLaxAGKxf7KgL",
          price: 8480000,
          content: 'Web3 Innovation Festival',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 12,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1f6w4qq/1617092024web3_innovation_festival/'
        },
        {
          id: 125,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 1300000,
          content: '海外TG群管理激励金',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1eny3kc/proposal125motion199%E6%B5%B7%E5%A4%96tg%E7%BE%A4%E7%AE%A1%E7%90%86%E6%BF%80%E5%8A%B1%E9%87%91/'
        },
        {
          id: 124,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 7800000,
          content: '配合DBC作为AI公链宣发的快讯服务经费申请',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1e9fey1/motions198_proposal124%E9%85%8D%E5%90%88dbc%E4%BD%9C%E4%B8%BAai%E5%85%AC%E9%93%BE%E5%AE%A3%E5%8F%91%E7%9A%84%E5%BF%AB%E8%AE%AF%E6%9C%8D%E5%8A%A1%E7%BB%8F%E8%B4%B9%E7%94%B3%E8%AF%B7/'
        },
        {
          id: 123,
          name: '5GeGk7MV3LMZVCLRQYYpcLjwtNKNU6X1T9TNKNPQMJcLXCxY',
          wallet: "5GeGk7MV3LMZVCLRQYYpcLjwtNKNU6X1T9TNKNPQMJcLXCxY",
          price: 3190000,
          content: '申请退还被惩罚扣除的质押DBC及建议',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 10,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1e8g5i9/proposal123motion197%E7%94%B3%E8%AF%B7%E9%80%80%E8%BF%98%E8%A2%AB%E6%83%A9%E7%BD%9A%E6%89%A3%E9%99%A4%E7%9A%84%E8%B4%A8%E6%8A%BCdbc%E5%8F%8A%E5%BB%BA%E8%AE%AE/'
        },
        {
          id: 122,
          name: 'Louis',
          wallet: "5G7MMgnbocnKPhoqx1PioCWN16gpSUHxF6CuGqYW6dpFacZh",
          price: 130000000,
          content: 'Requesting DeepBrain Chain Treasury Tokens for Market Making Funds',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1e64udg/proposal_122_motion_195_requesting_deepbrain/'
        },
      ])
      const linkHerf = (str) => {
        if (str) {
          window.open(str)
        }
      }
      const top3Array = ref([])
      const getMembwe = async () => {
        const memberData = await getMembers()
        memberData.sort((a, b) => {
          return Number(b.stake.replace('MDBC', '')) - Number(a.stake.replace('MDBC', ''))
        })
        const topimg = {
          mem0: Member1,
          mem1: Member2,
          mem2: Member3
        }
        memberData.slice(0,3).map(async (el, index) => {
          el.member_name = await getMemberName(el.who)
          el.img = topimg[`mem${index}`]
          top3Array.value.push(el)
        })
        console.log(top3Array.value, 'newarr');
      }
      const countDown = ref({
        days: 0,
        hours: 0
      })
      const getTime = async () => {
        const block_num = await getBlockNumber()
        const cycle = 120 * 2880;
        let cycle_now = 1
        for (let i = 1; i < 100; i ++ ) {
          if (cycle * i - block_num > 0) {
            cycle_now = cycle * (i-1)
            break;
          }
        }
        const use_time = 120 - ((block_num - cycle_now) / 2880)
        countDown.value.days = parseInt(use_time)
        countDown.value.hours = (parseInt((parseFloat(use_time) % 1) * 24)).toString().padStart(2, '0');
      }
      onMounted(() => {
        getMembwe()
        getTime()
      });
      return {
        lan,
        linkHerf,
        countDown,
        top3Array,
        proposalData
      };
    }
  })
</script>

<style lang="scss" scoped>
.introDBC {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
  }
  .content2 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 48px 24px;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    .council {
      width: 100%;
      padding: 16px;
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      border-style: solid;
      border-bottom-width: 1px;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      background: rgba(16, 19, 47, 1);
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      &:last-child {
        margin-bottom: 0;
      }
      .council_title {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .council_text {
        font-size: 14px;
        font-weight: 400;
        color: #899AA9;
        margin-top: 16px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .council_btn {
        display: inline-flex;
        width: 100%;
        height: 48px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin-top: 24px;
        line-height: normal;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        border: 1px solid #FFF;
      }
    }
  }
  .content3 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont3_text1 {
      width: 100%;
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .useList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .useli {
        width: 163px;
        padding: 16px;
        display: flex;
        margin-top: 24px;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
        border: 2px solid;
        border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
        backdrop-filter: blur(12.5px);
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        .use_iocn {
          width: 48px;
          height: 48px;
          margin-bottom: 14px;
        }
        .use_title {
          color: #fff;
          // color: transparent;
          // background-clip: text;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%); 
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-bottom: 8px;
        }
        .use_text {
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .content4 {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 24px;
    box-sizing: border-box;
    .cont4_text1 {
      width: 100%;
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .memberList {
      width: 100%;
      display: flex;
      padding: 24px 0;
      margin-top: 24px;
      justify-content: space-between;
      .member {
        width: 110px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .member_img {
          width: 80px;
          height: 80px;
          display: flex;
          overflow: hidden;
          border-radius: 80px;
          margin-bottom: 16px;
          align-items: center;
          justify-content: center;
          background: url(../../assets/learn/use_bg.png) 100%/100% no-repeat;
          img {
            width: 74px;
            height: 74px;
            border-radius: 80px;
          }
        }
        .member_name {
          font-size: 18px;
          font-weight: 400;
          // line-height: 24px;
        }
        .member_job {
          margin: 8px 0;
          font-size: 14px;
          color: #899AA9;
          font-weight: 400;
          line-height: 20px;
        }
        .member_num {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
  .content5 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 48px 24px;
    box-sizing: border-box;
    flex-direction: column;
    .cont5_text1 {
      width: 100%;
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }
    .electList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // margin-top: 40px;
      justify-content: space-between;
      .electLi {
        width: 100%;
        padding: 16px;
        cursor: pointer;
        margin-top: 16px;
        box-sizing: border-box;
        backdrop-filter: blur(8px);
        background: rgba(65, 164, 255, 0.16);
        &:hover {
          background: rgba(65, 164, 255, 0.32);
        }
        .vote_num {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .vote {
            font-size: 14px;
            color: #899AA9;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.28px;
          }
          .time {
            color: #fff;
            font-size: 14px;
            padding: 4px 8px;
            font-weight: 500;
            line-height: normal;
            background: #38EED6;
            letter-spacing: 0.28px;
            &.active {
              background: #41A4FF;
            }
            &.active2 {
              background: #6c6c6c;
            }
          }
        }
        .elect_title {
          font-size: 24px;
          font-weight: 500;
          margin-top: 24px;
          line-height: normal;
          letter-spacing: 0.48px;
        }
        .elect_text1 {
          display: flex;
          color: #fff;
          font-size: 16px;
          margin-top: 8px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
          span {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .elect_text2 {
          height: 72px;
          font-size: 14px;
          margin-top: 8px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.28px;
          overflow: hidden; /* 超出部分将被隐藏 */
          display: -webkit-box; /* 必需的WebKit前缀 */
          -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
          -webkit-box-orient: vertical; /* 垂直布局 */
        }
        .elect_text3 {
          font-size: 16px;
          font-weight: 400;
          margin-top: 24px;
          line-height: normal;
          letter-spacing: 0.32px;
        }
        .praise {
          width: 100%;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          margin-top: 24px;
          line-height: normal;
          align-items: center;
          letter-spacing: 0.32px;
          justify-content: space-between;
          .favour {
            display: flex;
            color: #EDA439;
            align-items: center;
          }
          .against {
            display: flex;
            color: #899AA9;
            align-items: center;
          }
          .praise_icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
      }
    }
  }
}

</style>
