<template>
  <div class="header" :class="{lan_En : lan != 'zh'}">
    <div class="meau">
      <div class="content">
        <img class="logo" src="../assets/logo.png" alt="deeplink logo" @click="linkHref2('/')">
        <div class="right">
          <div class="country" @click="changeLanList">
            <div class="lan_list" v-show="lan_show">
              <p @click.stop="handleCommand('en')">ENGLISH</p>
              <p @click.stop="handleCommand('zh')">简体中文</p>
              <p @click.stop="handleCommand('ko')">한국어</p>
              <p @click.stop="handleCommand('ja')">日本語</p>
              <p @click.stop="handleCommand('ru')">Русский</p>
              <p @click.stop="handleCommand('vn')">Việt nam</p>
            </div>
          </div>
          <div class="nav" @click="showMeau">
            <img v-show="!meau_show" src="../assets/meau.png" alt="deeplink logo">
            <img v-show="meau_show" src="../assets/close.png" alt="deeplink logo">
          </div>
        </div>
        
      </div>
    </div>
    <div class="container" v-show="meau_cont_show">
      <div class="meau_cont">
        <div class="meau_top" @click="linkHref2('/')">
          <div class="meau_name">{{$t('meau.nav')}}</div>
        </div>
        <div class="meau_list" v-for="(el, index) in Meau_data" :key="index">
          <div class="meau_top" @click="List_expand(el)">
            <div class="meau_name">{{ el.title }}</div>
            <div class="meau_icon" :class="{ up: !el.show, down: el.show }"></div>
          </div>
          <div class="meau_center" v-show="!el.show">
            <div class="meau_li" v-for="(el1, index1) in el.data" :key="index1" @click="linkHref2(el1.router)">{{ el1.name }}</div>
          </div>
        </div>
        <div class="meau_top">
          <a target="_blank" href="https://deepbrainchain.github.io/DBC-Wiki/">{{$t('meau.nav5')}}</a>
        </div>
        <div class="meau_top">
          <a target="_blank" href="https://dbc.subscan.io/">{{$t('meau.nav8')}}</a>
        </div>
        <div class="meau_top" @click="linkHref2('/buyDBC')">
          <div class="meau_name">{{$t('meau.nav6')}}</div>
        </div>
        <!-- <div class="meau_bottom">
          <div class="lan_list" v-show="lan_show">
            <p @click="handleCommand('en')">ENGLISH</p>
            <p @click="handleCommand('zh')">简体中文</p>
            <p @click="handleCommand('ko')">한국어</p>
            <p @click="handleCommand('ja')">日本語</p>
            <p @click="handleCommand('ru')">Русский</p>
            <p @click="handleCommand('vn')">Việt nam</p>
          </div>
          <div class="show_text" @click="lan_show = !lan_show"><div class="icon"></div><div class="text">{{ text }}</div></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted ,computed, watch} from "vue";
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'ComponentHeader',
    setup() {
      const { t, locale } = useI18n()
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const text = ref('ENGLISH')
      let lan = computed(() => store.state.lan)
      const lanObj = {
        'en': 'ENGLISH',
        'ko': '한국어',
        'zh': '简体中文',
        'ja': '日本語',
        'ru': 'Русский',
        'vn': 'Việt nam'
      }
      const linkHref2 = (command) => {
        meau_cont_show.value = false
        meau_show.value = false
        scrollmove()
        if (command == '/Whitepaper') {
          if (lan.value == 'zh') {
            window.open('/DeepBrainChainWhitepaper_zh.pdf');
          } else {
            window.open('/DeepBrainChainWhitepaper_en.pdf');
          }
          return
        }
        router.push({path: command})
        localStorage.setItem('path', command)
        store.commit('changePath', command)
      }
      const lan_show = ref(false)
      const changeLanList = () => {
        lan_show.value = !lan_show.value 
      }
      const handleCommand = (command) => {
        text.value = lanObj[command]
        locale.value = command
        lan_show.value = false
        localStorage.setItem('lan', command)
        store.commit('changeLan', command)
      }

      const Meau_data = ref([
        {
          title: t('meau.nav1'),
          show: true,
          data: [
            { name: t('meau.nav1_1'), router: '/buyGpuServer' },
            { name: t('meau.nav1_2'), router: '/getReward' },
            { name: t('meau.nav1_3'), router: '/voteReward' },
            { name: t('meau.nav1_4'), router: '/gpuMiner' },
            { name: t('meau.nav1_5'), router: '/applyCouncil' },
            { name: t('meau.nav1_6'), router: '/councilVoting' },
            { name: t('meau.nav1_7'), router: '/referenda' }
          ]
        },
        {
          title: t('meau.nav2'),
          show: true,
          data: [
            { name: t('meau.nav2_1'), router: '/learnDBC' },
            { name: t('meau.nav2_2'), router: '/whatisGPU' },
            { name: t('meau.nav2_3'), router: '/historyDBC' },
            { name: t('meau.nav2_4'), router: '/Whitepaper' },
            { name: t('meau.nav2_5'), router: '/introDBC' },
            { name: t('meau.nav2_6'), router: '/aboutTeam' }
          ]
        },
        {
          title: t('meau.nav9'),
          show: true,
          data: [
            { name: t('meau.nav9_1'), router: '/whatisAi' },
            { name: t('meau.nav9_2'), router: '/developAi' },
            { name: t('meau.nav9_3'), router: '/deployToken' },
            { name: t('meau.nav9_4'), router: '/paymentRelated' },
            { name: t('meau.nav9_5'), router: '/gpuMining' },
            { name: t('meau.nav9_6'), router: '/developAIModel' },
            { name: t('meau.nav9_7'), router: '/fullProcessTesting' },
            { name: t('meau.nav9_8'), router: '/primaryProcessTesting' }
          ]
        },
        {
          title: t('meau.nav10'),
          show: true,
          data: [
            { name: t('meau.nav10_1'), router: '/participateDBCMining' },
            { name: t('meau.nav10_2'), router: '/participateDBCDLCMining' },
            { name: t('meau.nav10_3'), router: '/participateDBCAiMining' },
            { name: t('meau.nav10_4'), router: '/participateAiMining' },
            { name: t('meau.nav10_5'), router: '/participateGPUMining' }
          ]
        },
        // {
        //   title: t('meau.nav3'),
        //   show: true,
        //   data: [
        //     { name: t('meau.nav3_1'), router: '/deployCloud' },
        //     { name: t('meau.nav3_2'), router: '/cloudInternet' },
        //     { name: t('meau.nav3_3'), router: '/gameAgents' },
        //     { name: t('meau.nav3_4'), router: '/convertGame' },
        //     { name: t('meau.nav3_5'), router: '/applyForFunding' }
        //   ]
        // },
        {
          title: t('meau.nav4'),
          show: true,
          data: [
            { name: t('meau.nav4_1'), router: '/createwallet' },
            { name: t('meau.nav4_2'), router: '/polkaWallet' },
            // { name: t('meau.nav4_3'), router: '/ERC20Wallet' },
            { name: t('meau.nav4_4'), router: '/mathWallet' },
            { name: t('meau.nav4_5'), router: '/ImToken' },
            { name: t('meau.nav4_6'), router: '/TrustWallet' },
            { name: t('meau.nav4_7'), router: '/metaMask' }
          ]
        },
      ])
      const List_expand = (el) => {
        if (el.show) {
          Meau_data.value.map((e) => {
            e.show = true
          })
          el.show = false
        } else {
          Meau_data.value.map((e) => {
            e.show = true
          })
        }
      }
      const meau_show = ref(false)
      const meau_cont_show = ref(false)
      const showMeau = () => {
        meau_show.value = !meau_show.value
        meau_cont_show.value = !meau_cont_show.value
        if (meau_cont_show.value) {
          scrollStop()
        } else {
          scrollmove()
        }
      }
      const pageLocation = ref('')

      const scrollStop = () => {
        let scrollTop = window.scrollY;
        pageLocation.value = scrollTop
        document.body.style.position = 'fixed';
        document.body.style.top = '-' + scrollTop + 'px';
      }
      
      const scrollmove = () => {
        document.body.style.position = 'static';
        window.scrollTo(0, pageLocation.value)
      }

      onMounted(() => {
        text.value = lanObj[lan.value]
      });
      watch(lan, (newValue) => {
        Meau_data.value = [
          {
            title: t('meau.nav1'),
            show: true,
            data: [
              { name: t('meau.nav1_1'), router: '/buyGpuServer' },
              { name: t('meau.nav1_2'), router: '/getReward' },
              { name: t('meau.nav1_3'), router: '/voteReward' },
              { name: t('meau.nav1_4'), router: '/gpuMiner' },
              { name: t('meau.nav1_5'), router: '/applyCouncil' },
              { name: t('meau.nav1_6'), router: '/councilVoting' },
              { name: t('meau.nav1_7'), router: '/referenda' }
            ]
          },
          {
            title: t('meau.nav2'),
            show: true,
            data: [
              { name: t('meau.nav2_1'), router: '/learnDBC' },
              { name: t('meau.nav2_2'), router: '/whatisGPU' },
              { name: t('meau.nav2_3'), router: '/historyDBC' },
              { name: t('meau.nav2_4'), router: '/Whitepaper' },
              { name: t('meau.nav2_5'), router: '/introDBC' },
              { name: t('meau.nav2_6'), router: '/aboutTeam' }
            ]
          },
          {
            title: t('meau.nav9'),
            show: true,
            data: [
              { name: t('meau.nav9_1'), router: '/whatisAi' },
              { name: t('meau.nav9_2'), router: '/developAi' },
              { name: t('meau.nav9_3'), router: '/deployToken' },
              { name: t('meau.nav9_4'), router: '/paymentRelated' },
              { name: t('meau.nav9_5'), router: '/gpuMining' },
              { name: t('meau.nav9_6'), router: '/developAIModel' },
              { name: t('meau.nav9_7'), router: '/fullProcessTesting' },
              { name: t('meau.nav9_8'), router: '/primaryProcessTesting' }
            ]
          },
          {
            title: t('meau.nav10'),
            show: true,
            data: [
              { name: t('meau.nav10_1'), router: '/participateDBCMining' },
              { name: t('meau.nav10_2'), router: '/participateDBCDLCMining' },
              { name: t('meau.nav10_3'), router: '/participateDBCAiMining' },
              { name: t('meau.nav10_4'), router: '/participateAiMining' },
              { name: t('meau.nav10_5'), router: '/participateGPUMining' }
            ]
          },
          // {
          //   title: t('meau.nav3'),
          //   show: true,
          //   data: [
          //     { name: t('meau.nav3_1'), router: '/deployCloud' },
          //     { name: t('meau.nav3_2'), router: '/cloudInternet' },
          //     { name: t('meau.nav3_3'), router: '/gameAgents' },
          //     { name: t('meau.nav3_4'), router: '/convertGame' },
          //     { name: t('meau.nav3_5'), router: '/applyForFunding' }
          //   ]
          // },
          {
            title: t('meau.nav4'),
            show: true,
            data: [
              { name: t('meau.nav4_1'), router: '/createwallet' },
              { name: t('meau.nav4_2'), router: '/polkaWallet' },
              // { name: t('meau.nav4_3'), router: '/ERC20Wallet' },
              { name: t('meau.nav4_4'), router: '/mathWallet' },
              { name: t('meau.nav4_5'), router: '/ImToken' },
              { name: t('meau.nav4_6'), router: '/TrustWallet' },
              { name: t('meau.nav4_7'), router: '/metaMask' }
            ]
          },
        ]
      })
      return {
        lan,
        linkHref2,
        Meau_data,
        meau_show,
        meau_cont_show,
        showMeau,
        List_expand,
        lan_show,
        text,
        handleCommand,
        changeLanList
      };
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  width: 100%;
  font-style: normal;
  // font-family: "Noto Sans SC";
  .meau {
    width: 390px;
    margin: auto;
    height: 60px;
    padding: 0 16px;
    box-sizing: border-box;
    .content {
      width: 100%;
      height: 60px;
      margin: auto;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      .logo {
        width: 217px;
        height: 28px;
      }
      .right {
        display: flex;
        align-items: center;
        .country {
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background: url(../assets/country.png) no-repeat 100%/100%;
          .lan_list {
            position: absolute;
            top: 30px;
            z-index: 10;
            left: -45px;
            width: 110px;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            background: rgba(0, 0 , 0, .8);
            p {
              width: 100%;
              font-size: 14px;
              color: #899AA9;
              line-height: 30px;
            }
          }
        }
        .nav {
          width: 32px;
          height: 32px;
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      
    }
  }
  .container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 60px - env(SAFE-AREA-INSET-TOP, 50px));
    .meau_cont {
      position: relative;
      top: 0;
      display: flex;
      padding: 24px;
      width: 100%;
      overflow: auto;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      background: #03061D;
      .meau_top {
        width: 100%;
        height: 48px;
        display: flex;
        color: #fff;
        font-size: 16px;
        align-items: center;
        justify-content: space-between;
        .meau_icon {
          width: 20px;
          height: 20px;
          transition: all 0.3s;
          background: url(../assets/arrow1.png) no-repeat 100%/100%;
          &.up {
            transform: rotate(0deg);
          }
          &.down {
            transform: rotate(180deg);
          }
          
        }
        
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      .meau_list {
        width: 100%;
        font-weight: 400;
        .meau_top {
          width: 100%;
          height: 48px;
          display: flex;
          color: #fff;
          font-size: 16px;
          align-items: center;
          justify-content: space-between;
          .meau_icon {
            width: 20px;
            height: 20px;
            transition: all 0.3s;
            background: url(../assets/arrow1.png) no-repeat 100%/100%;
            &.up {
              transform: rotate(0deg);
            }
            &.down {
              transform: rotate(180deg);
            }
            
          }
        }
        .meau_center {
          display: flex;
          align-items: center;
          flex-direction: column;
          .meau_li {
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 40px;
            font-size: 14px;
            color: #899AA9;
            line-height: 1;
          }
        }
      }
      .meau_bottom {
        // position: absolute;
        width: 342px;
        // bottom: 24px;
        // left: 24px;
        margin-top: 300px;
        .lan_list {
          position: absolute;
          top: 24px;
          left: 0;
          width: 110px;
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          background: rgba(0, 0 , 0, .8);
          p {
            width: 100%;
            font-size: 14px;
            color: #899AA9;
            line-height: 30px;
          }
        }
        .show_text {
          width: 100%;
          display: flex;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background: url(../assets/country.png) no-repeat 100%/100%;
          }
          .text {
            font-size: 14px;
            color: #899AA9;
            line-height: 40px;
          }
        }
        
      }
    }
  }
  
}

</style>

<style lang="scss">
.drawerInfo {
  height: initial !important;
  border-radius: 20px 20px 0 0;
  .el-drawer__body {
    padding: 0;
  }
  .meauInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .meaulist {
      width: 100%;
      cursor: pointer;
      padding: 20px 0;
      font-size: 34px;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      color: rgba(54, 63, 94, 0.6);
      &.active {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
      &:hover {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
    }
  }
  .iconInfo {
    width: 100%;
    padding: 50px 20px;
    background: #eee;
    .title {
      font-size: 34px;
      color: #363F5E;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      margin-bottom: 40px;
    }
    .community {
      width: 100%;
      .follow {
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .list {
          margin: 0 20px 10px;
          cursor: pointer;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: rgba(54, 63, 94, 0.6);
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            img {
              width: 20px;
              margin: 0 5px;
            }
          }
          span {
            width: 80px;
            height: 80px;
            margin-bottom: 5px;
            display: inline-block;
            
          }
          &:hover {
            color: #363F5E;
            span {
              
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .el-dialog {
    width: 45% !important;
  }
}
</style>
