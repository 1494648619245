<template>
  <div class="learnDBC" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/cont_bg1.png" alt=""></div>
    <div class="content1">
      <div class="cont1_left">
        <p class="left_text">{{ $t('learnDBC.title1') }}</p>
        <p class="left_text gradient_text">{{ $t('learnDBC.title2') }}</p>
      </div>
      <div class="cont1_right">
        <div class="right_list">
          <div class="right_li delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text1') }}</p></div>
          </div>
          <div class="right_li delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text4') }}</p></div>
          </div>
        </div>
        <div class="right_list">
          <div class="right_li delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text3') }}</p></div>
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text2') }}</p></div>
          </div>
          <div class="right_li delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text5') }}</p></div>
            <div class="token_text"><span class="icon"></span><p>{{ $t('learnDBC.text6') }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'learnDBC',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.learnDBC {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  // font-family: "Noto Sans SC";
  .bg1 {
    position: absolute;
    top: -60px;
    margin: auto;
    width: 390px;
    height: 343px;
    img {
      width: 390px;
      height: 343px;
    }
  }
  .cont_title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    width: 100%;
    font-size: 14px;
    color: #899AA9;
    font-weight: 400;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 1px; 
    margin-bottom: 16px;
    &.margin0 {
      margin-bottom: 0;
    }
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    .cont1_left {
      width: 100%;
      margin-bottom: 40px;
      .left_text {
        color: #fff;
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 10px;
        letter-spacing: 0.96px;
      }
    }
    .cont1_right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      justify-content: space-between;
      .right_list {
        width: 100%;
        .right_li {
          width: 348px;
          padding: 16px;
          margin-bottom: 16px;
          box-sizing: border-box;
          border-style: solid;
          border-bottom-width: 1px;
          border-left: 0;
          border-right: 0;
          border-top: 0;
          background: rgba(16, 19, 47, .6);
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          .token_text {
            display: flex;
            font-size: 14px;
            color: #899AA9;
            font-weight: 400;
            line-height: 20px;
            margin-top: 16px;
            justify-content: space-between;
            &:first-child {
              margin-top: 0;
            }
            .icon {
              width: 12px;
              height: 12px;
              margin-top: 4px;
              margin-right: 8px;
              background: url(../../assets/home/cont5_icon.png) 100%/100% no-repeat;
            }
            p {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>
